import React from 'react';
import moment from 'moment';
import { Popconfirm, Popover } from 'antd';
import {
  PlusSquareTwoTone,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  MoreOutlined,
  FolderOpenOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '_fsd/shared';
// import {DATE_TIME_FORMAT} from 'common/service/rule-generator/constants';
// import {getPetOwnersQuery, updateNextAppointmentPetEstimationDateMutation} from 'gql';
import { petImages } from 'constants/pet';
import './styles.css';
import css from './EditClientModal.module.css';

import cls from 'classnames';
import { IntegrationErrorPopover, isEntityIntegrationError } from '_fsd/entities/integration';
import { isAnyEntityIntegrationError } from '_fsd/entities/integration';

const useEditClientModalColumns = (handleRemovePet, setEditPet, setArchivePetInfo) => {
  const { t } = useTranslation();

  const content = (
    <div className="delete-popover">
      <ExclamationCircleFilled className="warning-icon" />
      {t('cancel.appointments.before.removing')}
    </div>
  );

  const errorContent = (
    <div className="delete-popover">
      <ExclamationCircleFilled className="warning-icon" />
      {t('this.pet.has.appointments')}
    </div>
  );

  const ActionColumn = ({ record }) => {
    const petCanBeDeleted = record?.nextAppointmentDate < moment().format('YYYY-MM-DD');

    const items = [
      {
        key: 1,
        label: (
          <div
            className="logo-wrapper"
            onClick={() => {
              setEditPet && setEditPet(record);
            }}>
            <EditOutlined className="edit-icon" />
            <span className="action-title">{t('client-modal.pet-actions.edit')}</span>
          </div>
        )
      },
      {
        key: 2,
        label: (
          <div
            className="logo-wrapper"
            onClick={() => {
              setArchivePetInfo && setArchivePetInfo(record);
            }}>
            <FolderOpenOutlined className="edit-icon" />
            <span className="action-title">{t('client-modal.pet-actions.archive')}</span>
          </div>
        )
      },
      {
        key: 3,
        label: (
          <div className="logo-wrapper">
            {petCanBeDeleted ? (
              <Popconfirm
                placement="top"
                title={t('are.you.sure.remove.pet')}
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleRemovePet(record.uid)}
                id="delete-popconfirm">
                <Popover content={content} trigger="hover">
                  <DeleteOutlined className="pet-table-delete-icon" />
                  <span className="action-title">{t('client-modal.pet-actions.delete')}</span>
                </Popover>
              </Popconfirm>
            ) : (
              <Popover content={errorContent} trigger="hover">
                <DeleteOutlined className="pet-table-delete-icon" />
                <span className="action-title">{t('client-modal.pet-actions.delete')}</span>
              </Popover>
            )}
          </div>
        )
      }
    ];

    return (
      <Dropdown
        menu={{ items }}
        trigger={['click']}
        getPopupContainer={() => {
          return document.getElementById('ID_LAYOUT_SCROLLABLE');
        }}>
        <MoreOutlined />
      </Dropdown>
    );
  };

  const petColumns = [
    {
      title: 'Pet Name',
      dataIndex: 'petName',
      key: 'petName',
      align: 'center',
      className: 'pet-name-column',
      render: (text, record) => {
        const isPetIntegrationError = isAnyEntityIntegrationError(
          record.vetAdmin,
          record?.petIntegrations
        );

        const integrationErrors = record.petIntegrations
          ?.filter(isEntityIntegrationError)
          .map(
            (integration) =>
              integration?.errorDetails?.message && (
                <span key={integration.uid}>{integration?.errorDetails?.message}</span>
              )
          );

        return (
          <div className="column-style">
            <IntegrationErrorPopover
              content={
                integrationErrors?.length > 0
                  ? integrationErrors
                  : t('integration.error_popover.pet_error_message')
              }
              entity={record}
              fixItHandler={() => {
                setEditPet(record);
              }}>
              <ExclamationCircleFilled
                className={cls(css.integrationWarningIcon, {
                  [css.hiddenIntegrationIcon]: !isPetIntegrationError
                })}
              />
            </IntegrationErrorPopover>
            <img
              className="pet-images"
              src={record?.avatarUrl ? record?.avatarUrl : petImages?.[record?.type?.name]}
              alt="petImage"
            />
            <span className="pet-name">{record?.name}</span>
          </div>
        );
      }
    },
    {
      title: 'Pet Type',
      dataIndex: 'petType',
      key: 'petType',
      align: 'center',
      render: (text, record) => <div>{record?.type?.name}</div>
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      align: 'center',
      render: (text, record) => (
        <div>{record?.birthDate ? moment(record?.birthDate).from(new Date(), true) : ''}</div>
      )
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      align: 'center'
    },
    {
      title: t('client-modal.next-appointment-column.title'),
      dataIndex: 'nextAppointment',
      key: 'nextAppointment',
      align: 'center',
      render: (text, pet) => {
        let dateString;

        if (pet.nextAppointmentDate) {
          dateString = moment(pet.nextAppointmentDate).format(t('date-time-format.full-date'));
        } else if (pet.nextAppointmentEstimation?.date) {
          dateString =
            t('client-modal.next-appointment-column.estimated-appt-short') +
            ' ' +
            moment(pet.nextAppointmentEstimation?.date).format(t('date-time-format.full-date'));
        } else {
          dateString = t('client-modal.next-appointment-column.no-appointments');
        }

        return <div>{dateString}</div>;
      }
    },
    {
      title: (
        <PlusSquareTwoTone
          className="plus-icon"
          onClick={() => {
            setEditPet && setEditPet({});
          }}
        />
      ),
      dataIndex: 'status',
      key: 'status',
      align: 'center',

      render: (text, record) => <ActionColumn record={record} />
    }
  ];

  return {
    petColumns
  };
};

export default useEditClientModalColumns;
