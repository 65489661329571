import React from 'react';
import { ClientCardSearchable } from '_fsd/features/client-card-searchable';
import ClientsEmptyState from './ClientsEmptyState';
import InfiniteScroll from 'react-infinite-scroll-component';
import { List, Skeleton } from 'antd';

const ModalContent = ({
  total,
  clients,
  onClientSelect,
  selectedClient,
  loading,
  onClientEdit,
  fetchPetOwners
}) => {
  if (!clients.length && !loading) return <ClientsEmptyState />;

  const loadMoreData = () => {
    if (loading) {
      return;
    }
    fetchPetOwners();
  };

  return (
    <div className="appointments-content-wrapper">
      <div
        id="scrollableDiv"
        style={{
          height: 400,
          overflow: 'auto'
        }}>
        <InfiniteScroll
          dataLength={clients.length}
          next={loadMoreData}
          loading={loading}
          hasMore={clients.length < total}
          loader={
            <Skeleton
              avatar
              paragraph={{
                rows: 1
              }}
              active
            />
          }
          scrollableTarget="scrollableDiv">
          <List
            dataSource={clients}
            renderItem={(item) => (
              <ClientCardSearchable
                key={item?.uid}
                item={item}
                onClientSelect={onClientSelect}
                selectedClient={selectedClient}
                onClientEdit={onClientEdit}
              />
            )}
          />
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default ModalContent;
