import React, { useEffect, useState, useMemo } from 'react';
import { PlusOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { cssText, ButtonColors, Button, Calendar } from '_fsd/shared';
import css from './VetApptManagerPage.module.css';
import { useTimeslotsByVets } from '_fsd/entities/timeslot';
import { getTimeslotsInDate, Modal } from '_fsd/shared';
import moment from 'moment/moment';
import { DayTimeslots, DAY_TIMESLOTS_VIEWS } from '_fsd/widgets/day-timeslots';
import { ClinicVetsMap } from '../../../widgets/clinic-vets-map';
import { useAppointmentsByVetIds } from '_fsd/entities/appointment';
import { SelectClinicMultipleVet } from '_fsd/features/admin-select-multiple-vet';
import { useClinicVets } from '_fsd/entities/vet-admin';
import { AppWrapper } from './AppWrapper';
import { SmartScheduleWrapper } from './SmartScheduleWrapper';
import { Skeleton } from './VetApptManagerPage.skeleton';
import { useDatesWithIntegrationErrors } from '../lib';
import { LoaderOnContent } from '_fsd/shared';

export const VetApptManagerPage = () => {
  const { loading: isVetsLoading, data: vets } = useClinicVets();

  const page = useMemo(() => <Page vets={vets} />, [vets]);
  return (
    <Skeleton loading={isVetsLoading} active>
      {page}
    </Skeleton>
  );
};

const Page = ({ vets }) => {
  const [view, setView] = useState(DAY_TIMESLOTS_VIEWS.TIMESLOTS);
  const [vetsMap, setVetsMap] = useState();
  const [selectedVets, setSelectedVets] = useState(vets);
  const [monthDate, setMonthDate] = useState(new Date());

  const [isSmartScheduleOpen, setIsSmartScheduleOpen] = useState(false);

  const dateStart = moment(monthDate)
    .startOf('month')
    .subtract(1, 'week')
    .format('YYYY-MM-DD 00:00');
  const dateEnd = moment(monthDate).endOf('month').add(1, 'week').format('YYYY-MM-DD 23:59');
  const {
    data: timeslots,
    refetch: refetchTimeslots,
    loading: isTimeslotsLoading
  } = useTimeslotsByVets({
    vetIds: selectedVets.map((v) => v.uid),
    dateStart,
    dateEnd
  });

  const [activeTimeslot, setActiveTimeslot] = useState();
  useEffect(() => {
    if (activeTimeslot) {
      if (!view) {
        setView(DAY_TIMESLOTS_VIEWS.TIMESLOTS);
      }
    }
    if (!activeTimeslot) {
      setView(DAY_TIMESLOTS_VIEWS.TIMESLOTS);
    }
  }, [activeTimeslot]);
  const vet = activeTimeslot?.vet;
  const [dates, setDates] = useState({});
  const [calendarDate, _setCalendarDate] = useState(moment());
  const timeslotActions = {
    calendarDate,
    setCalendarDate: _setCalendarDate
  };
  const timeslotsInDate = getTimeslotsInDate({
    timeslots,
    timeslotActions,
    setDates,
    filters: { displayFullTS: true },
    startDate: moment('2000-01-01'),
    isForAdminCalendar: true
  });
  const appRoute = activeTimeslot?.routes[0]?.appointmentRoutes[0];
  const route = activeTimeslot?.routes[0];

  const calendarDateStr = calendarDate.format('YYYY-MM-DD');
  const appsFilters = useMemo(
    () => ({
      date: calendarDateStr,
      skip: !vet?.uid || !activeTimeslot?.uid
    }),
    [calendarDateStr, vet?.uid, activeTimeslot?.uid]
  );

  const args = useMemo(
    () => ({
      vets: [vet?.uid],
      ...appsFilters,
      timeSlotId: view === DAY_TIMESLOTS_VIEWS.TIMESLOTS ? activeTimeslot?.uid : undefined,
      fetchPolicy: 'network-only'
    }),
    [vet?.uid, view, appsFilters]
  );
  const {
    data: apps,
    refetch: refetchApps,
    loading: isAppsLoading
  } = useAppointmentsByVetIds(args);

  const [vetAdmin] = vets?.[0]?.vetAdmins ?? [];
  const datesWithIntegrationErrors = useDatesWithIntegrationErrors({ dates, vetAdmin });

  return (
    <div className={css.container}>
      <div className={css.header}>
        <div className={css.headerCaregivers}>
          <span className={cssText.s16h24w5}>Caregivers</span>
          <SelectClinicMultipleVet
            setSelectedVets={setSelectedVets}
            selectedVets={selectedVets}
            vets={vets}
          />
        </div>
        <Button
          size="medium"
          disabled={!selectedVets.length}
          type="primary"
          colorScheme={ButtonColors.ORANGE}
          onClick={() => setIsSmartScheduleOpen(true)}
          icon={<PlusOutlined />}>
          Smart Scheduling
        </Button>
      </div>

      <div className={css.timeslotsWrapper}>
        <div className={css.calendar}>
          <Calendar
            loading={isTimeslotsLoading}
            setMonthDate={(date) => {
              timeslotActions.setCalendarDate(moment(date));
              setMonthDate(date);
            }}
            className={css.calendarWidth}
            theme="light"
            open
            hideHeader
            holidays={Object.keys(dates).map((date) => ({ date: `${date} 12:00:00` }))}
            endYear={2}
            startYear={new Date().getFullYear()}
            selected={timeslotActions.calendarDate.toDate()}
            onChange={(value) => {
              timeslotActions.setCalendarDate(moment(value));
              setActiveTimeslot();
            }}
            renderDayContents={(day, date) => {
              const formattedDate = moment(date).format('YYYY-MM-DD');
              const dayWithTimeslots = datesWithIntegrationErrors[formattedDate];

              return dayWithTimeslots?.hasAnyIntegrationError ? (
                <div className={css.dateContainer}>
                  <span>{day}</span>
                  <ExclamationCircleFilled className={css.warningIcon} />
                </div>
              ) : (
                day
              );
            }}
          />
        </div>
        <div className={css.timeSlots}>
          <DayTimeslots
            loading={isTimeslotsLoading}
            refetchTimeslots={refetchTimeslots}
            view={view}
            setView={setView}
            route={appRoute}
            vet={vet}
            calendarDate={calendarDate}
            setCalendarDate={timeslotActions.setCalendarDate}
            selectedVets={selectedVets}
            setVetsMap={setVetsMap}
            timeslots={timeslotsInDate}
            setActiveTimeslot={setActiveTimeslot}
            activeTimeslot={activeTimeslot}
            vetAdmin={vetAdmin}
          />
        </div>
      </div>
      <div className={css.apps}>
        {!isAppsLoading && !isTimeslotsLoading ? (
          <AppWrapper
            isAppsLoading={isAppsLoading}
            isTimeslotsLoading={isTimeslotsLoading}
            refetchApps={refetchApps}
            setIsSmartScheduleOpen={setIsSmartScheduleOpen}
            view={view}
            apps={apps}
            activeTimeslot={activeTimeslot}
            vet={activeTimeslot?.vet}
            route={route}
            appRoute={appRoute}
            calendarDate={calendarDate}
            vetAdmin={vetAdmin}
          />
        ) : (
          <LoaderOnContent md />
        )}
      </div>
      {vetsMap && (
        <Modal
          title="Day preview"
          isOpen={vetsMap}
          onClose={() => setVetsMap()}
          customFooter={() => null}>
          <ClinicVetsMap appsFilters={appsFilters} vetsMap={vetsMap} onClose={() => setVetsMap()} />
        </Modal>
      )}
      <SmartScheduleWrapper
        vets={vets}
        setCalendarDate={timeslotActions.setCalendarDate}
        isSmartScheduleOpen={isSmartScheduleOpen}
        setIsSmartScheduleOpen={setIsSmartScheduleOpen}
        refetchApps={() => {}}
      />
    </div>
  );
};
