import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from './EditOriginallyScheduledTime.module.css';
import { Label, Checkbox, Modal, cssSpace, cssText } from '_fsd/shared';
import LoaderWrapper from '../../../../components/loaderWrapper/LoaderWrapper';
import { getTimeSlotTime } from 'utils/helpers';
import cls from 'classnames';
import { ReactComponent as ClockCircleIcon } from 'assets/icons/clock-circle.svg';
import { useUpdateAppointmentOriginallyScheduledTime } from '_fsd/entities/appointment/api/useUpdateAppointmentOriginallyScheduledTime';

const AppointmentOriginallyScheduledTimeModal = ({
  onclose,
  selectedAppointment,
  onSuccess,
  originallyScheduledTimes
}) => {
  const [isRecalculateRoute, setIsRecalculateRoute] = useState(true);
  const {
    originallyScheduledTime: { appointmentRoute: { uid, originalStartTime, originalEndTime } } = {}
  } = selectedAppointment;
  const { t } = useTranslation();

  const currentlySelectedOriginallyScheduledTime =
    originalStartTime && originalEndTime ? `${originalStartTime}-${originalEndTime}` : null;

  const [selectedOriginallyScheduledTime, setSelectedOriginallyScheduledTime] = useState(
    currentlySelectedOriginallyScheduledTime
  );

  const [loading, setLoading] = useState(false);

  const { handleUpdateAppointmentOriginallyScheduledTime } =
    useUpdateAppointmentOriginallyScheduledTime();

  const onSubmit = () => {
    const [start, end] = selectedOriginallyScheduledTime
      ? selectedOriginallyScheduledTime.split('-')
      : [null, null];
    setLoading(true);
    handleUpdateAppointmentOriginallyScheduledTime(uid, start, end, isRecalculateRoute)
      .then(() => onSuccess())
      .catch(() => setLoading(false));
  };

  const formatTime = (time) => {
    const [start, end] = time.split('-');
    return `${getTimeSlotTime(start)} - ${getTimeSlotTime(end)}`;
  };

  const timesToDisplay = [null];

  if (originallyScheduledTimes) {
    timesToDisplay.push(...originallyScheduledTimes);
  }

  return (
    <Modal
      isOpen={true}
      onClose={onclose}
      title={t('new_appointment.set_originally_scheduled_time_title')}
      footerProps={{
        buttonConfirmProps: {
          disabled:
            selectedOriginallyScheduledTime === currentlySelectedOriginallyScheduledTime || loading,
          onClick: onSubmit,
          children: 'Apply',
          className: css.buttonConfirm
        },
        displayCancel: false
      }}>
      <LoaderWrapper isLoading={loading} style={{ height: '100%' }}>
        {timesToDisplay.map((time, index) => {
          return (
            <div
              key={time}
              className={cls(
                selectedOriginallyScheduledTime === time ? css.selectedTime : css.time,
                cssText.s14h22w4,
                index === timesToDisplay.length - 1 ? cssSpace.mb24 : cssSpace.mb12
              )}
              onClick={() => setSelectedOriginallyScheduledTime(time)}>
              <ClockCircleIcon className={cssSpace.mr12} />
              {time ? formatTime(time) : 'None'}
            </div>
          );
        })}

        <div>
          <Checkbox
            checked={isRecalculateRoute}
            name="firstTime"
            onChange={() => setIsRecalculateRoute((prev) => !prev)}>
            <Label>{t('new_appointment.recalculate_route')}</Label>
          </Checkbox>
        </div>
      </LoaderWrapper>
    </Modal>
  );
};

export default AppointmentOriginallyScheduledTimeModal;
