import React from 'react';
import { ReactComponent as Loader } from '../../../../assets/icons/loader.svg';
import css from './LoaderOnContext.module.css';
import cls from 'classnames';

export const LoaderOnContent = ({ md = false, noShadow = false, small = false }) => (
  <div
    className={cls(css.container, {
      [css.noShadow]: noShadow,
      [css.small]: small,
      [css.md]: md
    })}>
    <div className={css.image}>
      <Loader />
    </div>
  </div>
);
