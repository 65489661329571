import React, { useContext } from 'react';
import css from './Shop.module.css';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../contexts/AuthContext';
import { useMutation } from '@apollo/react-hooks';
import { createUserEvent } from '../../gql';
import { Button, message } from 'antd';

const Shop = (props) => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [mutate] = useMutation(createUserEvent);

  const onBack = () => {
    mutate({
      variables: {
        record: { action: 'leave_shop_unregistered', context: { target: 'shop', type: 'click' } }
      }
    });
    if (typeof window?.gtag === 'function') {
      window?.gtag('event', 'leave_shop_unregistered', {
        event_label: `user:${user.uid}`,
        event_category: `user:${user.uid}`,
        user_id: user.uid
      });
    }
    props.history.push('/');
  };
  const onNotify = () => {
    mutate({
      variables: {
        record: {
          action: 'notify_shop_service_enabled',
          context: { target: 'shop', type: 'click' }
        }
      }
    });
    if (typeof window?.gtag === 'function') {
      window?.gtag('event', 'notify_shop_service_enabled', {
        event_label: `user:${user.uid}`,
        event_category: `user:${user.uid}`,
        user_id: user.uid
      });
    }
    props.history.push('/');
    message.success(t('shop.success'));
  };
  return (
    <div className={css.container}>
      <div className={css.modal}>
        <h3 className={css.title}>{t('shop.title')}</h3>
        <span className={css.desc1}>{t('shop.desc1')}</span>
        <span className={css.desc2}>{t('shop.desc2')}</span>
        <div className={css.section}>
          <span className={css.desc3}>{t('shop.desc3')}</span>
          <span className={css.desc4}>{t('shop.desc4')}</span>
        </div>
        <div className={css.buttons}>
          <Button type="primary" onClick={onNotify} className={css.btn}>
            {t('shop.btnNotify')}
          </Button>
          <Button onClick={onBack} className={css.btn}>
            {t('shop.btnBack')}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Shop;
