import React from 'react';
import { EyeTwoTone, MoreOutlined } from '@ant-design/icons';
import './styles.css';

import useActAsUser from './useActAsUser';
import { useTranslation } from 'react-i18next';
import css from '../../vet-clients/ui/outdate/Customers.module.css';
import { Dropdown, UserDynamicAvatar } from '_fsd/shared';
import { useRemoveVet } from '_fsd/entities/vet-admin';

const ActionColumn = ({ record, actAsHandler, handleEdit, handleDelete }) => {
  const items = [
    {
      key: 1,
      label: (
        <div className={css.actionItem} onClick={() => actAsHandler(record.vetUserId)}>
          <EyeTwoTone twoToneColor={'#112950'} />
          <span>View as caregiver</span>
        </div>
      )
    }
    // {
    //   key: 3,
    //   label:
    //     <Popconfirm
    //       title={t('admin.general.confirm_delete')}
    //       onConfirm={() => {
    //         handleDelete(record.userID);
    //       }}
    //       getPopupContainer={() => document.getElementById('ID_LAYOUT_SCROLLABLE')}
    //       okText={t('admin.general.yes')}
    //       cancelText={t('admin.general.no')}
    //       placement="topRight"
    //     >
    //       <div className={css.actionItem}>
    //         <DeleteTwoTone twoToneColor={'#112950'} />
    //         <span>Remove vet</span>
    //       </div>
    //     </Popconfirm>,
    // },
  ];
  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      getPopupContainer={() => document.getElementById('ID_LAYOUT_SCROLLABLE')}>
      <MoreOutlined />
    </Dropdown>
  );
};

const useColumns = (refetchVets) => {
  const { t } = useTranslation();
  const { handleDeleteVet } = useRemoveVet(refetchVets);

  const { handleActAsUser } = useActAsUser();

  return [
    {
      showSorterTooltip: false,
      sorter: true,
      title: t('admin.users.registration_date'),
      dataIndex: 'registrationDate',
      key: 'registrationDate'
    },
    {
      showSorterTooltip: false,
      sorter: true,
      title: t('admin.users.name'),
      dataIndex: 'fullName',
      key: 'fullName',
      render: (t, record) => (
        <div className={css.user}>
          <UserDynamicAvatar user={record.user} />
          <span>{record.name}</span>
        </div>
      )
    },
    {
      showSorterTooltip: false,
      sorter: true,
      title: t('admin.forms.email'),
      dataIndex: 'email',
      key: 'email'
    },
    {
      showSorterTooltip: false,
      sorter: true,
      title: t('admin.users.phone'),
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: t('admin.general.actions'),
      key: 'action',
      align: 'right',
      dataIndex: 'action',
      render: (text, record) => (
        <ActionColumn
          record={record}
          actAsHandler={handleActAsUser}
          handleDelete={handleDeleteVet}
        />
      )
    }
  ].filter((item) => item?.visible !== false);
};
export default useColumns;
