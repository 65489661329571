import React from 'react';

import { EditOutlined } from '@ant-design/icons';
import AvatarGroup from 'components/avatar-group/AvatarGroup';
import { useTranslation } from 'react-i18next';
import './style.css';
import { useFragment } from '@apollo/client';
import { ADDRESS_FRAGMENT } from '_fsd/entities/address';

const getFullName = (user) => `${user?.firstName}` + (user?.lastName ? ` ${user?.lastName}` : '');

export const ClientCardSearchable = ({ item, onClientSelect, selectedClient, onClientEdit }) => {
  const { t } = useTranslation();

  const { data: address } = useFragment({
    fragment: ADDRESS_FRAGMENT,
    fragmentName: 'FAddress',
    from: {
      __typename: 'Address',
      uid: item?.user?.address?.uid
    }
  });

  const user = item.user
    ? {
        ...item.user,
        address: address || item.user.address
      }
    : null;

  return (
    <div
      className={`client-card-wrapper ${selectedClient?.user?.uid === user?.uid ? 'active' : ''}`}
      onClick={() => onClientSelect({ ...item, user })}>
      {item.pets?.length > 0 ? (
        <div style={{ marginRight: '2.4rem' }}>
          <AvatarGroup pets={item.pets} />
        </div>
      ) : (
        <div />
      )}
      <div className="client-details-wrapper address-details">
        <h3>{getFullName(user)}</h3>
        <div className="address-wrapper">
          <h4 onClick={(e) => onClientEdit(e, user)}>{t('new_appointment.address')}</h4>
          {user?.address ? (
            <h5 onClick={(e) => onClientEdit(e, user)}>{user?.address?.description}</h5>
          ) : (
            <h5>
              <div onClick={(e) => onClientEdit(e, user)} className="add-address-button">
                {t('new_appointment.add_address')}
              </div>
            </h5>
          )}
        </div>
        {user?.address ? (
          <h5>
            {user?.address?.city}, {user?.address?.country?.name}{' '}
            <EditOutlined onClick={(e) => onClientEdit(e, user)} />
          </h5>
        ) : null}
      </div>
    </div>
  );
};
