import React from 'react';
import cls from 'classnames';
import moment from 'moment-timezone';
import css from './Timeslot.module.css';
import { cssText as text, cssColor as colors, cssSpace as space } from '_fsd/shared';
import { User } from '_fsd/entities/user';
import { formatAppointmentDuration } from 'common_repo/utils/helpers';
import { getRouteSummaryData } from '_fsd/entities/route/lib/getRouteSummaryData';
import { Label } from '_fsd/shared';

import { useTranslation } from 'react-i18next';

import { TimeslotInfoTooltip as InfoTooltip } from './Timeslot.info-tooltip';
import { routeStatus } from '../../../../../../components/timeline/appointments/useTimelineAppointments';

export const TimeslotRecomender = ({ timeslot, active, onClick, disabled }) => {
  const {
    additionalDistanceInMi,
    additionalTime,
    routes,
    timeSlot: { date, name, startTime, endTime, vet }
  } = timeslot;
  const { t } = useTranslation();
  const route = routes?.[0] || { route: {} };
  const { isFull, isLocked } = route?.route;

  const [_, totalTime, routingTime, appointmentsTime, freeTime, percentage] = getRouteSummaryData(
    route?.route?.appointmentRoutes,
    timeslot.timeSlot,
    route?.route
  );

  const percentageStyle =
    freeTime < 0 || percentage <= 5
      ? colors.cRed
      : percentage > 5 && percentage <= 20
        ? colors.cYellow
        : colors.cBlue;

  return (
    <div
      className={cls(css.timeslotItem, {
        [css.activeTimeslotItem]: active,
        [css.disabledTimeslotItem]: disabled
      })}
      onClick={() => !disabled && onClick()}>
      {(isFull || isLocked) && (
        <div className={css.marks}>
          {isFull && <div className={cls(css.markFull, text.s12w7l20)}>Full</div>}
          {isLocked && <div className={cls(css.markLocked, text.s12w7l20)}>Locked</div>}
        </div>
      )}
      <div className={css.distanceContainer}>
        <span className={text.s18w7l24}>{`+${
          Math.round(additionalDistanceInMi * 10) / 10
        } mi`}</span>
        <span className={cls(colors.cGray8c, text.s12w5l14)}>{`(~${formatAppointmentDuration(
          Math.round(additionalTime)
        ).toLocaleLowerCase()})`}</span>
      </div>
      <div className={css.dateContainer}>
        <span className={text.s12w4l20}>{`${moment(startTime, 'h:mm A').format(
          'h:mm A'
        )} - ${moment(endTime, 'h:mm A').format('h:mm A')}`}</span>
        <span className={text.s14w5l16}>{moment(date).format('ddd, MMMM Do YYYY')}</span>
        <span className={cls(css.tsName, colors.cBlue, text.s12w5l18)}>{name}</span>
      </div>
      <div className={css.vetContainer}>
        <span className={cls(css.vetLabel, colors.cGray8c, text.s12w4l20)}>Caregiver</span>
        <User user={vet?.user} />
      </div>
      <div className={css.footer}>
        <Label className={cls(css.percentage, percentageStyle, text.s12w6l20, space.mr4)}>
          {percentage.toFixed()}%
        </Label>
        <Label className={cls(css.time, text.s12w5l20, colors.cBlackO45, space.mr2)}>
          {freeTime < 0 ? t('route-summary.overTime') : t('route-summary.free')}
        </Label>
        <InfoTooltip
          routeSummaryData={{
            routingTime,
            appointmentsTime,
            freeTime,
            routeStatus: route?.route?.status || routeStatus.REGISTER,
            housecallsCnt: route?.route?.appointmentRoutes?.length || 0
          }}
        />
      </div>
    </div>
  );
};
