import React, { useContext, useState } from 'react';
import css from './TimeSlotForm.module.css';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UserIcon } from 'assets/icons/user-blue.svg';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Input, InputNumber, message, Row, Select, TimePicker, Typography } from 'antd';
import cls from 'classnames';
import AddressAutocomplete from 'components/vet-components/address-autocomplete/AddressAutocomplete';

import {
  Button as ButtonFSD,
  Checkbox,
  cssColor as colors,
  cssText as text,
  getVetName,
  HorizontalLine,
  Label,
  Modal,
  useGetVetByUid
} from '_fsd/shared';
import LoaderWrapper from 'components/loaderWrapper/LoaderWrapper';

import * as moment from 'moment';
import { useCreateTimeSlots } from '_fsd/entities/timeslot/api/useCreateTimeSlots';

import { WorkingAreasMapView, WorkingAreasSelectMultiple } from '_fsd/features';
import { CreateWorkingArea } from '_fsd/widgets';
import { addressSerializer, useCreateForm } from '_fsd/features/timeslot-editor';
import {
  serializeTimeslotUpdateInput,
  useCreateTimeslotFromRecurring
} from '_fsd/entities/timeslot';
import { processDate } from '../../../../pages/vet/new-appointment-modal/serializers';
import DatePicker from '../../../../components/vet-components/vet-web-inputs/date-picker/DatePicker';
import { RRule } from 'rrule';
import { getLatLng } from '_fsd/entities/user';
import { parseUserSettingStartPoint } from '_fsd/entities/vet';
import AuthContext from '../../../../contexts/AuthContext';
import { Roles } from '../../../../constants/enums';
import { useVets } from '../../../entities/vet';

const { Title } = Typography;

const getOnChangeAddressHandler = (name, formik) => (address) =>
  formik.setFieldValue(name, addressSerializer({ address }));

const TimeSlotForm = ({
  selectedTimeSlot,
  refetchTimeSlots,
  isCopying,
  date,
  onSave,
  vetId,
  vet
}) => {
  const { user } = useContext(AuthContext);
  const settings = parseUserSettingStartPoint(vet?.user?.userSettings || user?.userSettings);
  const defaultCenter = getLatLng(settings);

  const [errorModal, setErrorModal] = useState([]);

  const { t } = useTranslation();
  const { t: tm } = useTranslation('timeslot_modal');

  const { createTimeSlots } = useCreateTimeSlots();
  const [editTimeslotFromRecurring] = useCreateTimeslotFromRecurring({
    refetchQueries: ['getAuthVet', 'getFilteredTimeSlotsByVetUid']
  });

  const isEditByAdmin = Roles.VET_ADMIN === user.role;
  const { data: vets } = useVets({
    variables: { search: '' },
    skip: !isEditByAdmin
  });

  const formik = useCreateForm(
    selectedTimeSlot,
    {
      onSubmit: (values, helpers) => {
        helpers.setSubmitting(true);
        const variables = serializeTimeslotUpdateInput(values);
        const excludeFromRecurring = selectedTimeSlot?.isRecurring && !isCopying;
        const handler = excludeFromRecurring ? editTimeslotFromRecurring : createTimeSlots;
        // new vetId or existed one
        const newVetId = (isEditByAdmin && variables.vetId) || vetId;
        const newTimeslot = {
          ...variables,
          vetId: undefined,
          rule: excludeFromRecurring
            ? variables.rule
            : new RRule({
                freq: RRule.DAILY,
                dtstart: moment(moment(variables.date).format('YYYYMMDD') + 'T120000Z').toDate(),
                count: 1,
                interval: 1
              }).toString(),
          workingAreas: variables.workingAreas.map((wa) => wa.uid),
          type: 'work_availability',
          oldDate: processDate(selectedTimeSlot?.date)
        };
        const record = excludeFromRecurring
          ? {
              timeSlot: newTimeslot,
              vetId: newVetId
            }
          : {
              timeSlots: [newTimeslot],
              vetId: newVetId
            };

        handler({ variables: { record } })
          .then(() => {
            refetchTimeSlots();
            message.success(t('updated.successfully'));
            onSave();
          })
          .catch((err) => {
            message.error(err.message);
            console.log('error', err);
          })
          .finally(() => {
            helpers.setSubmitting(false);
          });
      }
    },
    true,
    date,
    vet
  );

  const vetOptions = (vets || []).map((v) => ({
    value: v.uid,
    vet: v,
    label: getVetName(v)
  }));
  const valueVet = {
    value: formik.values.vet?.uid,
    label: getVetName(formik.values.vet || {})
  };

  const [createAreaModal, setCreateAreaModal] = useState(false);

  return (
    <>
      <div className={css.container}>
        {isEditByAdmin && (
          <div className={css.formItem}>
            <span className={text.label}>{tm('assigned_vets_label')}</span>
            <Select
              style={{ width: '100%' }}
              options={vetOptions}
              value={valueVet}
              onSelect={(v, vetOption) => {
                formik.setFieldValue('vet', vetOption.vet);
              }}
              required
              showSearch
              optionFilterProp={'label'}
              name="vet"
              placeholder={tm('assigned_vets_placeholder')}
            />
          </div>
        )}

        <div className={css.formItem}>
          <span className={text.label}>{t('recurring_time_slot.name_ts')}</span>
          <Input
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            status={formik.errors.name && 'error'}
            placeholder={t('recurring_time_slot.name_the_time_slot')}
          />
          {formik.errors.name && <Label type="error">{t(`recurring_time_slot.errors.name`)}</Label>}
        </div>
        <div>
          <span className={text.label}>{t('Select date')}</span>
          <DatePicker
            allowClear={false}
            className={css.inputFullWidth}
            name="date"
            date={moment(formik.values.date)}
            onChange={(date) => {
              formik.setFieldValue('date', date?.format('YYYY-MM-DD'));
            }}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className={css.formRow}>
          <div className={css.formItem}>
            <span className={text.label}>{t('start.time')}</span>
            <TimePicker
              getPopupContainer={() => document.getElementById('ID_LAYOUT_SCROLLABLE')}
              className={css.inputFullWidth}
              inputReadOnly
              format="h:mm a"
              use12Hours
              minuteStep={15}
              name="startTime"
              placeholder={'00:00'}
              value={
                formik.values.startTime ? moment(formik.values.startTime, 'hh:mm a') : undefined
              }
              onBlur={formik.handleBlur}
              onSelect={(v) => {
                formik.setFieldValue('startTime', moment(v).format('hh:mm a'));
              }}
            />
          </div>
          <div className={css.formItem}>
            <span className={text.label}>{t('end.time')}</span>
            <TimePicker
              getPopupContainer={() => document.getElementById('ID_LAYOUT_SCROLLABLE')}
              className={css.inputFullWidth}
              inputReadOnly
              format="h:mm a"
              use12Hours
              minuteStep={15}
              name="endTime"
              placeholder={'00:00'}
              value={formik.values.endTime ? moment(formik.values.endTime, 'hh:mm a') : undefined}
              onBlur={formik.handleBlur}
              onSelect={(v) => {
                formik.setFieldValue('endTime', moment(v).format('hh:mm a'));
              }}
            />
          </div>
        </div>
        <Title level={5} className="working-area-title">
          {t('timeslot_modal.working_area_lbl')}
        </Title>
        <Row>
          <Col span={24}>
            <WorkingAreasSelectMultiple
              placeholder={t('timeslot_modal.select_wa_placeholder')}
              status={formik.errors.workingAreas && 'error'}
              error={formik.errors.workingAreas}
              onSelect={(areas) => formik.setFieldValue('workingAreas', areas)}
              values={formik.values.workingAreas}
            />
            {!createAreaModal && (
              <ButtonFSD
                className={css.buttonCreate}
                onClick={() => setCreateAreaModal(true)}
                icon={<PlusOutlined />}>
                {t('timeslot_modal.add_working_area_btn')}
              </ButtonFSD>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <WorkingAreasMapView
              data={formik.values.workingAreas}
              loading={false}
              handleClickArea={() => {}}
              disableControls
              mapClass={css.mapClass}
              containerClass={css.containerClass}
            />
          </Col>
        </Row>
        <HorizontalLine className={cls(css.hr, colors.cC9d4E3)} />
        <>
          <Col style={{ padding: 0 }}>
            <Row style={{ alignItems: 'center', marginBottom: 8, padding: 0 }}>
              <Checkbox
                name="isDefaultLimit"
                checked={formik.values.isDefaultLimit}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}>
                <span className="checkbox-text">{t('timeslot_modal.clients_limit')}</span>
              </Checkbox>
              {!formik.values.isDefaultLimit && (
                <InputNumber
                  name="maxAppointments"
                  controls={false}
                  type="number"
                  addonAfter={<UserIcon />}
                  value={formik.values.maxAppointments}
                  onChange={(number) => formik.setFieldValue('maxAppointments', number)}
                  onBlur={formik.handleBlur}
                  status={formik.errors.maxAppointments && 'error'}
                  className="client-limit-input"
                />
              )}
            </Row>
            {formik.errors.maxAppointments && (
              <Label type="error">{t(`recurring_time_slot.errors.maxAppointments`)}</Label>
            )}
          </Col>
          <>
            <Row className={css.defaultPointsCheckbox}>
              <Checkbox
                name="useDefaultPoints"
                checked={formik.values.useDefaultPoints}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}>
                <span className="checkbox-text">{t('timeslot_modal.default_points')}</span>
              </Checkbox>
            </Row>
            <div className={css.pointsInput}>
              <div className={css.inputFullWidth}>
                <span className="checkbox-text">{t('start.point')}</span>
                <AddressAutocomplete
                  disabled={formik.values.useDefaultPoints}
                  className="address-autocomplete-input"
                  address={formik.values.startPoint}
                  onSelect={getOnChangeAddressHandler('startPoint', formik)}
                  onChange={getOnChangeAddressHandler('startPoint', formik)}
                  customErrorMessage={
                    formik.errors.startPoint && t('recurring_time_slot.errors.addressPoint')
                  }
                />
              </div>
              <div className={css.inputFullWidth}>
                <span className="checkbox-text">{t('end.point')}</span>
                <AddressAutocomplete
                  disabled={formik.values.useDefaultPoints}
                  className="address-autocomplete-input"
                  address={formik.values.endPoint}
                  onSelect={getOnChangeAddressHandler('endPoint', formik)}
                  onChange={getOnChangeAddressHandler('endPoint', formik)}
                  customErrorMessage={
                    formik.errors.endPoint && t('recurring_time_slot.errors.addressPoint')
                  }
                />
              </div>
            </div>
          </>
        </>
        <Row className={css.includeCheckbox}>
          <Checkbox
            name="isSingle"
            onBlur={formik.handleBlur}
            onClick={() => formik.setFieldValue('isSingle', !formik.values.isSingle)}
            checked={!formik.values.isSingle}
            className="checkbox-text">
            {t('timeslot_modal.is_single_timeslot')}
          </Checkbox>
        </Row>

        <ButtonFSD
          disabled={formik.isSubmitting}
          type="primary"
          colorScheme="blue"
          className={cls(css.inputFullWidth)}
          onClick={formik.submitForm}>
          {selectedTimeSlot && !isCopying
            ? t('recurring_time_slot.update')
            : t('recurring_time_slot.save')}
        </ButtonFSD>
      </div>
      {createAreaModal && (
        <CreateWorkingArea
          defaultCenter={defaultCenter}
          pushIsSavedSelectModal
          createAreaModal={createAreaModal}
          onClose={(area) => {
            setCreateAreaModal(false);
            const areas = [...formik.values.workingAreas.filter((a) => a.uid !== area?.uid)];
            if (area?.uid) {
              areas.push(area);
            }
            formik.setFieldValue('workingAreas', areas);
          }}
        />
      )}
      <Modal
        hideClose
        icon={<ExclamationCircleOutlined />}
        title={t('create_timeslot.validations.title')}
        isOpen={errorModal.length}
        footerProps={{
          displayCancel: false
        }}
        onConfirm={() => {
          setErrorModal([]);
        }}
        onClose={() => {
          setErrorModal([]);
        }}>
        <div className={css.validationModal}>
          {errorModal.map((err) => (
            <span key={err}>{err}</span>
          ))}
        </div>
      </Modal>
    </>
  );
};

export const TimeslotForm = (props) => {
  const { vet, loading } = useGetVetByUid(props.vetId);

  return (
    <LoaderWrapper isLoading={loading}>
      <TimeSlotForm vet={vet} {...props} />
    </LoaderWrapper>
  );
};
