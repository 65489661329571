import { gql, useQuery } from '@apollo/client';

const emptySlots = [];
export const useTimeslotsByVets = (filters) => {
  const { data: { getTimeSlots } = {}, ...rest } = useQuery(queryGetTimeSlots, {
    variables: { record: filters },
    fetchPolicy: 'no-cache',
    skip: !filters.vetIds?.length
  });

  return { data: !filters.vetIds?.length ? emptySlots : getTimeSlots, ...rest };
};

// simple structure of timeslot object
export const useTimeSlotsByVetId = ({ vetId, isRecurring, isArchived }) => {
  const { data, ...rest } = useQuery(queryGetTimeSlots, {
    variables: {
      record: { vetIds: [vetId], isRecurring, isArchived }
    },
    skip: !vetId
  });
  return {
    data: data?.getTimeSlots,
    ...rest
  };
};

export const queryGetTimeSlots = gql`
  query getTimeSlots($record: GetTimeSlotsRecord!) {
    getTimeSlots(record: $record) {
      uid
      startTime
      endTime
      date
      weekDay
      rule
      name
      type
      maxAppointments
      isRecurring
      isSingle
      maxAppointments
      mergedTimeSlotsHours
      appointments {
        appointmentRouteId
        appointmentIntegrations {
          uid
          status
          errorDetails
          integration {
            status
          }
        }
      }
      startPoint {
        lat
        lng
        description
        zipCode
        city
        countryCode
        street
        houseNumber
      }
      endPoint {
        lat
        lng
        description
        zipCode
        city
        countryCode
        street
        houseNumber
      }
      vet {
        uid
        timezone {
          uid
          slug
          gmt
        }
        user {
          uid
          firstName
          lastName
          avatarUrl
          userSettingStartPoint
          userSettings {
            uid
            itemValue
            setting {
              description
            }
          }
          address {
            lat
            lng
            placeId
            zipCode
            placeId
            uid
          }
        }
      }
      workingAreas {
        uid
        createdAt
        updatedAt
        name
        type
        thumbnail
        isSaved
        vetAdmin {
          uid
        }
        zipCodes {
          uid
          zip
        }
        polygons {
          name
          uid
          area
        }
        address {
          zipCode
          city
          lat
          lng
          country {
            name
            code
          }
        }
      }
      routes {
        uid
        date
        status
        isLocked
        isFull
        appointmentRoutes {
          uid
          duration
        }
      }
    }
  }
`;
