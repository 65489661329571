import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalizationContext } from 'common_repo';

import { petAvatars } from 'constants/pet';
import { SINGLE_PET } from 'constants/client';
import { getBookingUrl, replaceRouteParams } from 'utils/helpers';

import VetModal from 'pages/dashboard/vet-modal/VetModal.jsx';

import * as S from './components';

const TimelinePetCard = ({
  image,
  name,
  price,
  type,
  vetImage,
  id,
  // hasActiveSubscription,
  vet,
  vetId
}) => {
  const [isExtraVisitOpen] = useState(false);
  const [vetModalOpen, setVetModalOpen] = useState(false);

  const { t } = useTranslation();

  const { rtl: isRtl } = useLocalizationContext();

  return (
    <S.PetCard isExtraVisitOpen={isExtraVisitOpen} hasActiveSubscription>
      <S.CheckoutDetailsPetCard to={replaceRouteParams(SINGLE_PET, 'id', id)}>
        <S.CheckoutDetailsPetCardImage hasImage={!!image} src={image || petAvatars[type]} />
        <S.CheckoutDetailsPetCardName>{name}</S.CheckoutDetailsPetCardName>
      </S.CheckoutDetailsPetCard>
      <S.VetWrapper isRtl={isRtl} isSubscribed>
        <S.ExtraVisitWrapper href={getBookingUrl()}>
          <S.ExtraVisitText>{t('book.a.visit')}</S.ExtraVisitText>
        </S.ExtraVisitWrapper>
      </S.VetWrapper>
      {vetModalOpen && (
        <VetModal
          isOpen={true}
          handleCloseModal={() => setVetModalOpen((prev) => !prev)}
          vetId={vetId}
        />
      )}
    </S.PetCard>
  );
};

export default TimelinePetCard;
