import React, { createContext, useCallback, useState } from 'react';
import storage from 'utils/storage';
import { useApolloClient, useQuery } from '@apollo/client';
import { meQuery } from '../gql';

const initialData = storage.getToken();

const AuthContext = createContext({
  user: null,
  codeSentAt: null,
  token: initialData,
  setCodeSendAt: (time) => {},
  setToken: (token) => {},
  logout: ({ reload }) => {},
  loading: false
});

export const AuthContextProvider = ({ children }) => {
  const client = useApolloClient();

  const [token, setTokenRaw] = useState(initialData);
  const [codeSentAt, setCodeSendAt] = useState(null);

  const { data, loading, refetch } = useQuery(meQuery, {
    fetchPolicy: 'no-cache',
    skip: !token
  });

  const setToken = useCallback((token, toSessionStorage) => {
    setTokenRaw(token);
    storage.setToken(token, toSessionStorage);
    refetch();
  }, []);

  const logout = useCallback((params) => {
    storage.clearToken();
    client.clearStore().then(() => {
      if (params?.reload) {
        window.location.reload();
      } else {
        window.location = '/';
      }
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        token,
        codeSentAt,
        user: data?.me,
        setToken,
        loading,
        logout,
        refetch,
        setCodeSendAt
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
