import React, { useEffect, useMemo, useState } from 'react';
import cls from 'classnames';
import css from './DayTimeslots.module.css';
import { Row, Table } from 'antd';
import { Button, ButtonColors, cssText, LoaderOnContent } from '_fsd/shared';
import { useTimeslots, useVets } from '_fsd/features';
import { sortByStartTime } from '../../../../utils/helpers';
import AddTimeslotModal from '../../../../pages/vet/add-timeslot-modal/AddTimeslotModal';
import { routeStatus as Route } from '../../../../components/timeline/appointments/useTimelineAppointments';
import { useQuery } from '@apollo/client';
import { getVetByUidQuery } from '../../../../gql';
import moment from 'moment';
import { DAY_TIMESLOTS_VIEWS } from '../types';

export const DayTimeslots = ({
  loading,
  view,
  setView,
  timeslots,
  activeTimeslot,
  setActiveTimeslot,
  setCalendarDate,
  refetchTimeslots,
  setVetsMap,
  calendarDate,
  vet,
  route,
  vetAdmin
}) => {
  const [isAddTimeslotModalOpen, setIsAddTimeslotModalOpen] = useState(false);
  const vets = useMemo(() => {
    const vetObj = {};
    timeslots.forEach((t) => {
      if (!vetObj[t.vet.uid]) {
        vetObj[t.vet.uid] = {
          ...t.vet,
          timeslots: []
        };
      }
      vetObj[t.vet.uid].timeslots.push(t);
    });
    return Object.values(vetObj);
  }, [timeslots]);
  const [selectedRowKeysTimeslots, setSelectedRowKeysTimeslots] = useState([]);
  const [selectedRowKeysVets, setSelectedRowKeysVets] = useState([]);
  const onSelectTimeslot = (newSelectedRowKeys) => {
    setSelectedRowKeysTimeslots(newSelectedRowKeys);
    const ts = timeslots.find((t) => t.uid === newSelectedRowKeys[0]);
    if (ts) {
      setActiveTimeslot(ts);
      setSelectedRowKeysVets([ts.vet.uid]);
    }
  };

  const onSelectVet = (newSelectedRowKeys) => {
    setSelectedRowKeysVets(newSelectedRowKeys);
    const vet = vets.find((v) => v.uid === newSelectedRowKeys[0]);
    if (vet) {
      setSelectedRowKeysTimeslots([vet.timeslots[0].uid]);
      setActiveTimeslot(vet.timeslots[0]);
    }
  };

  const rowSelectionTimeslot = {
    type: 'radio',
    selectedRowKeys: selectedRowKeysTimeslots,
    onChange: onSelectTimeslot
  };
  const rowSelectionVet = {
    type: 'radio',
    selectedRowKeys: selectedRowKeysVets,
    onChange: onSelectVet
  };

  useEffect(() => {
    if (timeslots?.length && timeslots) {
      const currentSelectedTimeslot =
        timeslots.find((t) => t.uid === activeTimeslot?.uid) || timeslots[0];

      setSelectedRowKeysTimeslots([currentSelectedTimeslot?.uid]);
      setSelectedRowKeysVets([currentSelectedTimeslot?.vet?.uid]);
      setActiveTimeslot(currentSelectedTimeslot);
    }
  }, [timeslots]);
  const [updateTimeslotData, setUpdateTimeslotData] = useState(null);
  const { timeslotsColumn } = useTimeslots(
    setUpdateTimeslotData,
    calendarDate,
    setActiveTimeslot,
    refetchTimeslots,
    vetAdmin
  );
  const { columns: vetColumns } = useVets();

  const modalIsVisible = isAddTimeslotModalOpen || updateTimeslotData;

  const { data: { getVetByUid: fullVet } = {} } = useQuery(getVetByUidQuery, {
    variables: {
      record: {
        uid: vet?.uid
      }
    },
    skip: !vet
  });

  const clientLimit = useMemo(() => {
    if (vet?.uid) {
      const address = vet?.user?.userSettings?.find(
        (s) => s?.setting?.description === 'CLIENTS_PER_HOUR'
      )?.itemValue;

      if (!address) return {};

      return JSON.parse(address);
    }
  }, [vet]);
  const registerOrAdjustment = useMemo(() => {
    return (
      route?.status?.toLowerCase() === Route.REGISTER ||
      route?.status?.toLowerCase() === Route.ADJUSTMENT
    );
  }, [route?.status]);

  return (
    <div className={css.container}>
      {loading && (
        <div className={css.loaderWrapper}>
          <LoaderOnContent small />
        </div>
      )}
      <Row className={css.header}>
        <Row>
          <span className={cssText.s16h24w5}>View by:</span>
          <Button
            className={css.btnLeft}
            type={view === DAY_TIMESLOTS_VIEWS.TIMESLOTS ? 'secondary' : 'default'}
            colorScheme="blue"
            onClick={() => setView(DAY_TIMESLOTS_VIEWS.TIMESLOTS)}>
            Timeslots
          </Button>
          <Button
            className={css.btnRight}
            type={view === DAY_TIMESLOTS_VIEWS.CAREGIVERS ? 'secondary' : 'default'}
            colorScheme="blue"
            onClick={() => setView(DAY_TIMESLOTS_VIEWS.CAREGIVERS)}>
            Caregiver
          </Button>
        </Row>
      </Row>
      <div className={css.view}>
        {view === DAY_TIMESLOTS_VIEWS.TIMESLOTS && (
          <div>
            <Table
              // scroll={{y: 200}}
              className={css.table}
              pagination={false}
              rowSelection={rowSelectionTimeslot}
              dataSource={sortByStartTime(timeslots)}
              columns={timeslotsColumn}
              rowKey="uid"
              onRow={(record) => ({
                onClick: () => {
                  onSelectTimeslot([record?.uid]);
                }
              })}
            />
          </div>
        )}
        {view === DAY_TIMESLOTS_VIEWS.CAREGIVERS && (
          <div className={css.caregivers}>
            <Table
              pagination={false}
              rowSelection={rowSelectionVet}
              dataSource={vets}
              columns={vetColumns}
              className={cls(css.table, css.tableRadio)}
              rowKey="uid"
              onRow={(record) => ({
                onClick: () => onSelectVet([record?.uid])
              })}
            />
          </div>
        )}
      </div>
      <div className={css.footer}>
        <Button
          disabled={!vets.length}
          type="primary"
          colorScheme={ButtonColors.BLUE}
          onClick={() => setVetsMap(vets)}>
          Day Preview
        </Button>
      </div>
      {modalIsVisible && fullVet && (
        <AddTimeslotModal
          isCopying={!updateTimeslotData?.uid}
          setCalendarDate={setCalendarDate}
          vet={fullVet}
          closeModal={() => {
            setIsAddTimeslotModalOpen(false);
            setUpdateTimeslotData(null);
          }}
          clientLimit={clientLimit}
          calendarDate={calendarDate}
          refetchAuthVet={refetchTimeslots}
          appointmentsData={[]}
          spotCalendarDate={(date) => {
            setCalendarDate(moment(date));
          }}
          updateTimeslotData={updateTimeslotData}
          setSelectedTimeslot={setActiveTimeslot}
          registerOrAdjustment={registerOrAdjustment}
        />
      )}
    </div>
  );
};
