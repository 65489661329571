import * as React from 'react';

export const EmptyVetIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="96" height="58" fill="none" viewBox="0 0 96 58">
    <path
      fill="url(#paint0_linear_4039_38322)"
      fillRule="evenodd"
      d="M19.275 10.866q1.482-4.596 2.755-5.724c2.35-2.082 5.6-.95 6.29-.787 2.428.572 1.639-3.19 3.721-3.955q2.081-.764 3.425 1.869Q36.654-.187 39.09.015c2.436.203 3.289 8.281 6.636 6.513 3.348-1.77 7.452-2.174 9.206.455.379.569.524-.314 3.101-3.326s5.148-4.34 10.42-2.586q3.595 1.195 5.916 6.48 0 7.541 11.3 8.927c11.3 1.385 2.529 13.314-11.3 16.808s-45.668 5.38-62.946-3.447q-17.278-8.826 7.852-18.973"
      clipRule="evenodd"
      opacity="0.8"></path>
    <path
      fill="url(#paint1_linear_4039_38322)"
      fillRule="evenodd"
      d="M82.787 55.326c-15.666 3.858-69.14-.555-74.832-2.57Q3.8 51.283.465 47.218a2.03 2.03 0 0 1-.26-2.185c.172-.352.44-.648.776-.855a2.13 2.13 0 0 1 1.12-.318h93.443q2.91 7.608-12.757 11.466"
      clipRule="evenodd"
      opacity="0.675"></path>
    <path
      fill="#A9DCE8"
      d="M47.31 54.25c10.136 0 18.353-8.11 18.353-18.113s-8.217-18.113-18.352-18.113-18.353 8.11-18.353 18.113S37.175 54.25 47.311 54.25"></path>
    <path
      fill="#22A3C3"
      d="M57.009 36.132V44.7a.85.85 0 0 1-.255.605.88.88 0 0 1-.613.252H38.779a.88.88 0 0 1-.614-.252.85.85 0 0 1-.255-.605v-8.568c0-.186.062-.368.177-.516a.87.87 0 0 1 .457-.306l6.077-1.714a.85.85 0 0 1 1.01.44.86.86 0 0 1 .093.383c0 .454.182.89.508 1.211s.767.502 1.228.502.902-.18 1.227-.502c.326-.32.509-.757.509-1.211a.86.86 0 0 1 .347-.686.85.85 0 0 1 .755-.137l6.077 1.714c.182.05.343.157.458.306.114.148.176.33.176.516"></path>
    <path
      fill="#EEF2FF"
      d="m50.881 34.129-.703-1.928a.86.86 0 0 0-.476-.497.88.88 0 0 0-.694.005c-.215.092-.446.14-.68.14H46.59c-.234 0-.466-.048-.68-.14a.877.877 0 0 0-1.17.492l-.703 1.928a.8.8 0 0 0-.05.29c0 .909.365 1.78 1.016 2.423a3.5 3.5 0 0 0 2.456 1.004c.92 0 1.804-.361 2.455-1.004a3.4 3.4 0 0 0 1.017-2.423.9.9 0 0 0-.05-.29"></path>
    <path
      fill="#000"
      fillOpacity="0.08"
      d="m50.881 34.129-.703-1.928a.86.86 0 0 0-.476-.497.88.88 0 0 0-.694.005c-.215.092-.446.14-.68.14H46.59c-.234 0-.466-.048-.68-.14a.877.877 0 0 0-1.17.492l-.703 1.928a.8.8 0 0 0-.05.29c0 .909.365 1.78 1.016 2.423a3.5 3.5 0 0 0 2.456 1.004c.92 0 1.804-.361 2.455-1.004a3.4 3.4 0 0 0 1.017-2.423.9.9 0 0 0-.05-.29"></path>
    <path
      fill="#EEF2FF"
      d="M48.327 23.28h-1.736c-1.918 0-3.473 1.535-3.473 3.428v3.427c0 1.893 1.555 3.427 3.473 3.427h1.736c1.918 0 3.473-1.534 3.473-3.427v-3.427c0-1.893-1.555-3.427-3.473-3.427"></path>
    <path
      fill="#22A3C3"
      d="M65.664 36.137a17.93 17.93 0 0 1-2.952 9.862 18.27 18.27 0 0 1-7.92 6.683 18.56 18.56 0 0 1-10.33 1.345 18.44 18.44 0 0 1-9.406-4.426c9.919-7.404 17.276-17.652 21.065-29.343a18.27 18.27 0 0 1 6.972 6.651 17.96 17.96 0 0 1 2.57 9.228"
      opacity="0.4"></path>
    <defs>
      <linearGradient
        id="paint0_linear_4039_38322"
        x1="49.643"
        x2="49.643"
        y1="27.011"
        y2="-6.384"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#DEDEDE" stopOpacity="0"></stop>
        <stop offset="1" stopColor="#A9A9A9" stopOpacity="0.3"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear_4039_38322"
        x1="48"
        x2="48"
        y1="56.856"
        y2="41.303"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" stopOpacity="0"></stop>
        <stop offset="1" stopColor="#919191" stopOpacity="0.15"></stop>
      </linearGradient>
    </defs>
  </svg>
);
