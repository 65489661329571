import { useQuery, gql } from '@apollo/client';

const emptyResult = [];
export const useClinicVets = ({ order, search, skip } = { search: '' }) => {
  const { data, ...rest } = useQuery(document, {
    variables: {
      search,
      order
    },
    skip
  });
  return { data: data?.getVets || emptyResult, ...rest };
};

const document = gql`
  query getVetsAdmin($search: String!, $order: OrderVets) {
    getVets(search: $search, order: $order) {
      uid
      status
      user {
        ...USER_FOR_VET_FRAGMENT
      }
      experience
      pets {
        uid
        name
      }
      licenseNumber
      licensedDate
      licenseExpiration
      languages {
        uid
        code
        name
      }
      commuteMethod
      createdAt
      activeCustomersCount
      vetAdmins {
        uid
        integrations {
          uid
          status
          pim {
            uid
            name
          }
        }
      }
    }
  }
`;
