import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { SIGN_IN } from 'constants/client';
import { Roles } from 'constants/enums';

require('components/vet-components/layout/layout.css');

const VetRoute = ({
  component: Component,
  user,
  isHome,
  layout: Layout,
  isCustomers,
  disableMobilePadding,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return user && user.role?.toLowerCase() === Roles?.VET.toLowerCase() ? (
          Layout ? (
            <Layout
              isHome={isHome}
              isCustomers={isCustomers}
              disableMobilePadding={disableMobilePadding}>
              <Component {...props} />
            </Layout>
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to={SIGN_IN} />
        );
      }}
    />
  );
};

export default VetRoute;
