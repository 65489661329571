import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from './EditPetList.module.css';
import PetSection from '../../components/_new/PetSection/PetSection';
import { prepareAppPets } from '../../serializers/signUpFlow.serializer';
import { prepareAppPetForForm } from '../../utils/helpers';
import { Label, Checkbox, Modal } from '_fsd/shared';
import { useMutation } from '@apollo/client';
import { addPetToAppointmentRoute } from '../../gql';
import LoaderWrapper from '../loaderWrapper/LoaderWrapper';

const AppointmentPatientListModal = ({
  onclose,
  selectedAppointment,
  onConfirm,
  vet,
  selectedAppointmentClient
}) => {
  const [isRecalculateDuration, setIsRecalculateDuration] = useState(true);
  const { addPatient: { appointment, user } = {} } = selectedAppointment;
  const { t } = useTranslation();

  const [petList, setPetList] = useState(prepareAppPetForForm(appointment?.pets, true) || []);
  const [loading, setLoading] = useState(false);
  const [updateAppPets] = useMutation(addPetToAppointmentRoute);
  const onSubmit = () => {
    if (petList.length) {
      setLoading(true);
      const pets = prepareAppPets(petList, true);
      updateAppPets({
        variables: {
          data: {
            petIds: [],
            appointmentRouteId: appointment.appointmentRoute.uid,
            appointmentPets: pets,
            userId: user.uid,
            isRecalculateDuration
          }
        },
        refetchQueries: ['getAppointmentsByDate']
      })
        .then(() => onConfirm())
        .catch(() => setLoading(false));
    }
  };

  return (
    <Modal
      isOpen={true}
      onClose={onclose}
      title="Edit Appointment"
      footerProps={{
        buttonConfirmProps: {
          disabled: !petList.length,
          onClick: onSubmit,
          children: 'Update Appointment'
        }
      }}>
      <LoaderWrapper isLoading={loading} style={{ width: 400, height: 400 }}>
        <PetSection
          setPetsToCreate={() => {}}
          petList={petList}
          setPetList={setPetList}
          vet={vet}
          client={user}
          isVet={true}
        />
        <div className={css.checkbox}>
          <Checkbox
            checked={isRecalculateDuration}
            name="firstTime"
            onChange={() => setIsRecalculateDuration((prev) => !prev)}>
            <Label>{t('patient_details.recalculate_duration')}</Label>
          </Checkbox>
        </div>
      </LoaderWrapper>
    </Modal>
  );
};

export default AppointmentPatientListModal;
