import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { timeSlotsToDateObject } from 'common_repo/utils/helpers';

const useVetCard = (
  uid,
  timeSlots,
  selectedAppointment,
  selectedVetUid,
  setSelectedVetUid,
  time,
  selectedTimeSlot,
  vet
) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [workingHours, setWorkingHours] = useState([]);
  const [selectedHour, setSelectedHour] = useState(null);
  const [currentMonthTimeSlots, setCurrentMonthTimeSlots] = useState([]);
  const [calendarTimeSlots, setCalendarTimeSlots] = useState([]);

  useEffect(async () => {
    if (!!timeSlots) {
      const allTimeSlots = timeSlotsToDateObject({
        timeslots: timeSlots,
        currenthMonth: false,
        fullTimeSlots: [],
        isForReschedule: false,
        startDate: new Date(),
        time,
        isForAdminCalendar: false,
        isForVet: false,
        timeZone: false,
        displayLocked: false,
        shouldDeleteFullTimeSlots: true
      });

      setCalendarTimeSlots(allTimeSlots);

      if (selectedAppointment?.uid) {
        // select default date
        setStartDate(new Date(selectedAppointment?.date));

        // get default working hour
        if (allTimeSlots[selectedAppointment?.date]) {
          const hour = allTimeSlots[selectedAppointment?.date].find(
            (day) => day.uid === selectedAppointment?.timeSlot?.uid
          );

          if (hour) {
            await handleActiveSlots(hour);
            setSelectedVetUid(uid);
          }
        }
      }
    }
  }, [selectedAppointment, timeSlots, selectedTimeSlot]);

  const handleChangeDate = (date) => {
    setStartDate(date);
    const dayTimes = calendarTimeSlots[dayjs(date).format('YYYY-MM-DD')];

    setWorkingHours(
      dayTimes?.sort((a, b) => Number(a.startTime.slice(0, 2)) - Number(b.startTime.slice(0, 2)))
    );
    setSelectedHour(null);
    setSelectedVetUid(null);
  };

  const handleOpenCalendar = () => {
    if (selectedVetUid !== uid) {
      setStartDate(new Date());
      setSelectedHour(null);
      setWorkingHours([]);
    }
    setIsCalendarOpen(true);
  };

  const handleActiveSlots = async (slot) => {
    // console.log(slot, currentMonthTimeSlots);

    // if selected date is not from list, push timeslot to list
    const index = currentMonthTimeSlots.findIndex(
      (sl) =>
        sl.uid === slot.uid &&
        dayjs(slot.date).format('YYYYMMDDHHmm') == dayjs(sl.date).format('YYYYMMDDHHmm')
    );

    if (index >= 0) {
      setCurrentMonthTimeSlots((prev) => {
        return prev.map((s) => ({
          ...s,
          isActive: s.uid === slot.uid
        }));
      });
    } else {
      setCurrentMonthTimeSlots((prev) => [
        {
          ...slot,
          isActive: true
        },
        ...prev
          .filter((s) => s.date.toString() !== slot.date.toString())
          .map((s) => ({
            ...s,
            isActive: false
          }))
      ]);
    }
    setSelectedHour({ ...slot, isActive: true });
  };

  const handleSelectHour = (slot) => {
    handleActiveSlots(slot);
    setSelectedVetUid(uid);
  };

  // select appointment without opening calendar
  const setDate = (timeSlot, appointmentDate) => {
    setCurrentMonthTimeSlots((prev) => {
      return prev.map((slot) => ({
        ...slot,
        isActive: slot.uid === timeSlot.uid
      }));
    });

    setSelectedHour({
      ...timeSlot,
      isActive: true
    });
    setSelectedVetUid(uid);
    setStartDate(appointmentDate);
  };

  return {
    isCalendarOpen,
    setIsCalendarOpen,
    startDate,
    workingHours,
    selectedHour,
    currentMonthTimeSlots,
    handleChangeDate,
    handleOpenCalendar,
    handleSelectHour,
    setDate,
    calendarTimeSlots
  };
};

export default useVetCard;
