import React, { useState, useCallback, useEffect, useContext } from 'react';
import { signUpFlowMutation, checkIfUserExistsQuery } from 'gql';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { useHistory, useParams } from 'react-router-dom';
import { Form, message } from 'antd';
import { useLocalizationContext } from 'common_repo';
import options from 'assets/static/country-phones.json';
import { Roles } from 'constants/enums';
import PersonalDetails from './personal-details/PersonalDetails';
import useCalendarSteps from '../../useCalendarSteps';
import { signUpFinalData } from 'serializers/signUpFlow.serializer';
import StepperFooter from './StepperFooter';
import Stepper from './Stepper';

import './style.css';
import Modal from 'components/common/modal/Modal';
import Button from 'components/common/button/Button';
import Warning from 'assets/icons/warning.svg';
import PetSection from 'components/_new/PetSection/PetSection';
import { AddressStepNew } from './address/AddressStepNew';
import { useFilters, useSelectedTimeslot } from '_fsd/widgets';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../../../../../../contexts/AuthContext';

// TODO: remove these consts
const BOOK = 'book';
const CALENDAR_STEPS = 'tmp';

const CalendarSteps = ({
  vet,
  allVets,
  sendRequestCode,
  auth,
  isBooking,
  isForVet,
  onFinishNewClient,
  placeId,
  onRequestClose,
  goBack,
  onChangeView
}) => {
  const { t } = useTranslation();
  const emptyPetObj = {
    type: '',
    name: ''
  };
  const [form] = Form.useForm();
  const { countryCode } = useLocalizationContext();
  const history = useHistory();
  const params = useParams();
  const { user: me } = useContext(AuthContext);

  const [isSubmitting, setSubmitting] = useState(false);
  const [current, setCurrent] = useState(0);
  const [, setIsVerifyCodeModalOpen] = useState(false);
  const [petList, setPetList] = useState([]);
  const [petsToCreate, setPetsToCreate] = useState([]);
  const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [isNoteModalOpen, setIsNoteModalOpen] = useState(true);
  const [isPhoneNumberExists, setIsPhoneNumberExists] = useState(false);

  useEffect(() => {
    form.setFieldsValue({ pets: petList });
  }, [petList]);

  const {
    onSelect,
    onChange,
    addressError,
    address,
    setAddress,
    selectedDate,
    selectedTimeslot,
    setSelectedTimeslot,
    vetIsOccupied,
    propertyType,
    setPropertyType
  } = useCalendarSteps(vet, null, isForVet, placeId, me, form);
  const [checkIfUserExists] = useLazyQuery(checkIfUserExistsQuery);

  const [signUp] = useMutation(signUpFlowMutation);

  useEffect(() => {
    if (me?.address && !isForVet) {
      form.setFieldsValue({
        floor: me?.address?.floor ? String(me?.address?.floor) : '',
        apartment: me?.address?.apartment,
        comment: me?.address?.comment,
        userComment: me?.address?.comment
      });
      setAddress({
        ...me?.address,
        countryCode: me?.address?.country?.code
      });
    }
  }, [me, placeId]);
  // submit data
  useEffect(() => {
    if (isVerificationCodeSent && !isPhoneNumberExists) {
      const signUpObject = signUpFinalData(
        formValues,
        address,
        isForVet ? null : me,
        auth,
        selectedTimeslot,
        petList,
        propertyType,
        isForVet,
        selectedTimeslot,
        selectedTimeslot.vet || vet,
        petsToCreate
      );

      if (signUpObject.user.address?.country) {
        delete signUpObject.user.address?.country;
      }
      if (signUpObject.user.address?.__typename) {
        delete signUpObject.user.address?.__typename;
      }

      handleSubmit(signUpObject);
      setIsVerificationCodeSent(false);
    }
  }, [isVerificationCodeSent]);

  // reset error message
  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 5000); // 5sec
    }
  }, [errorMessage]);

  useEffect(() => {
    // FIXME: remove?
    if (params?.step) {
      history.push(BOOK.replace(':step', ''));
    }
  }, []);

  const changeUrl = useCallback(
    (step) => {
      const stepsUrl = {
        0: CALENDAR_STEPS.BOOKING_PAGE,
        1: CALENDAR_STEPS.PATIENT_DETAILS,
        2: CALENDAR_STEPS.CLIENT_DETAILS,
        success: CALENDAR_STEPS.SUCCESS
      };
      if (isBooking && auth?.me?.uid && !isForVet) {
        history.push(BOOK.replace(':step', stepsUrl[step]));
      }
    },
    [isBooking, auth, history]
  );

  const handleSubmit = async (data) => {
    setSubmitting(true);
    if (me && me?.role === Roles.VET && !isForVet) {
      setErrorMessage(t('admin.vet.booking.error'));
    } else {
      try {
        const res = await signUp({
          variables: {
            record: {
              ...data
            }
          },
          refetchQueries: ['getTimeSlots']
        });

        if (res?.data?.signUpFlow) {
          if (isForVet) {
            onFinishNewClient(data?.timeSlotId, data?.date);
          }
        }
      } catch (err) {
        message.error(t('admin.recommender.new_appointment.error_message' + err));
      }
    }
    setSubmitting(false);
  };

  const changeStep = useCallback(
    (step) => {
      if (step < current) setCurrent(step);
    },
    [current]
  );

  const handleSendRequestCode = async (values) => {
    const codeSent = await sendRequestCode({
      phonePrefix: values.phonePrefix,
      phoneNumber: values.phoneNumber,
      role: Roles.PET_OWNER
    });
    if (codeSent?.data?.requestCode) {
      setIsVerifyCodeModalOpen(true);
      setFormValues(values);
    } else {
      setIsLoading(false);
      setErrorMessage(codeSent?.message);
    }
  };

  const filters = useFilters({
    // vetId: vet?.uid,
    selectedVetsDef: (allVets || []).map((v) => v.uid),
    displayFullTSDef: true,
    displayTSNotInAreaDef: true,
    displayVetsDef: true
  });
  const timeslotActions = useSelectedTimeslot({ handleTimeslot: setSelectedTimeslot });

  const steps = [
    {
      title: 'address.and.date.time',
      content: (
        <AddressStepNew
          filters={filters}
          timeslotActions={timeslotActions}
          address={address}
          addressError={addressError}
          isForVet={isForVet}
          onSelect={onSelect}
          setPropertyType={setPropertyType}
          propertyType={propertyType}
          onChange={onChange}
          currentAddress={address}
          vetId={vet?.uid}
        />
      )
    },
    {
      title: 'Patient Details',
      content: (
        <PetSection
          setPetsToCreate={setPetsToCreate}
          petList={petList}
          setPetList={setPetList}
          isVet
          vet={selectedTimeslot?.vet || vet} // get the concerns for this vet
        />
      )
    },
    {
      title: 'personal.details',
      content: (
        <PersonalDetails form={form} phoneNumberError={phoneNumberError} isForVet={isForVet} />
      )
    }
  ];

  const initialValues = {
    pets: me ? [] : [emptyPetObj],
    phonePrefix: options.find((o) => o.code === (countryCode || 'US'))?.dial_code || '',
    phoneNumber: '',
    isInformClient: true
  };
  return (
    <>
      <div className={`vcs ${isBooking ? 'booking-page' : ''}`}>
        <Stepper
          current={current}
          changeStep={changeStep}
          me={me}
          isForVet={isForVet}
          onRequestClose={onRequestClose}
        />

        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onFinish={async () => {
            if (current !== steps.length - (auth?.me ? 2 : 1)) {
              setCurrent(current + 1);
              changeUrl(current + 1);
            } else {
              setIsLoading(true);
              const values = form.getFieldsValue(true);

              if (auth?.me) {
                setFormValues(values);
                setIsVerificationCodeSent(true);
                setIsLoading(false);
              } else {
                const existingUser = await checkIfUserExists({
                  variables: {
                    record: {
                      phonePrefix: values.phonePrefix,
                      phoneNumber: values.phoneNumber,
                      email: values.email
                    }
                  }
                });
                if (existingUser?.email || existingUser?.phoneNumber) {
                  if (existingUser?.email) {
                    form.setFields([
                      {
                        name: 'email',
                        errors: [t('admin.error.email.already.exists')]
                      }
                    ]);
                  }
                  if (existingUser?.phoneNumber) {
                    form.setFields([
                      {
                        name: 'phoneNumber',
                        errors: [t('admin.error.phone.already.exists')]
                      }
                    ]);
                    setIsPhoneNumberExists(values);
                  }
                  setIsLoading(false);
                } else {
                  if (!isForVet) {
                    await handleSendRequestCode(values);
                  } else {
                    values.date = selectedTimeslot?.date;
                    setFormValues(values);
                    setIsVerificationCodeSent(true);
                  }
                }
              }
              changeUrl('success');
            }
          }}>
          {steps[current].content}
          <StepperFooter
            current={current}
            phoneNumberError={phoneNumberError}
            setPhoneNumberError={setPhoneNumberError}
            addressError={addressError}
            selectedDate={selectedDate}
            selectedTimeslot={selectedTimeslot}
            errorMessage={errorMessage}
            steps={steps}
            changeStep={changeStep}
            form={form}
            isSubmitting={isSubmitting}
            isLoading={isLoading}
            auth={auth}
            isBooking={isBooking}
            vetIsOccupied={vetIsOccupied}
            ignoreFloorAppartment={true}
            isForVet={isForVet}
            goBack={goBack}
          />
          <Form.Item name="isInformClient" noStyle></Form.Item>
        </Form>
      </div>

      {isNoteModalOpen && !isForVet && (
        <Modal isOpen={true} onRequestClose={() => setIsNoteModalOpen(false)}>
          <div className="note-modal">
            <div className="d-flex">
              <img src={Warning} alt="" />
              <h3>{t('admin.note_modal.title')}</h3>
            </div>
            <p>{t('admin.note_modal.text')}</p>
            <div className="text-right">
              <Button
                label={t('admin.note_modal.button')}
                color="darkGreen"
                onClick={() => setIsNoteModalOpen(false)}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default CalendarSteps;
