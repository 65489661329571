import { useQuery, gql } from '@apollo/client';

export const usePetOwners = (variables = {}, options = {}) => {
  const { data, ...rest } = useQuery(query, { variables, ...options });
  return { data: data?.getPetOwners, ...rest };
};

const query = gql`
  query getPetOwners($record: GetPetOwnersRecord!) {
    getPetOwners(record: $record) {
      total
      page
      petOwners {
        uid
        userNextAppointmentDate
        userNextAppointmentEstimation {
          uid
          date
          value
        }
        petOwnerIntegrations {
          status
          errorDetails
          integration {
            uid
            status
          }
        }
        pets {
          uid
          name
          gender
          avatarUrl
          birthDate
          appointmentPets {
            uid
            appointment {
              date
            }
            nextAppointmentPetEstimation {
              uid
              date
            }
          }
          type {
            uid
            name
          }
          petIntegrations {
            status
            errorDetails
            integration {
              uid
              status
            }
          }
        }
        user {
          uid
          firstName
          lastName
          createdAt
          email
          phonePrefix
          phoneNumber
          address {
            ...FAddress
          }
        }
        petOwnerVets {
          vet {
            user {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;
