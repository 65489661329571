import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CalendarImg } from 'assets/images/calendar-simple.svg';

import Selector from 'components/common/selector/Selector';

import TimelineAppointments from 'components/timeline/appointments/TimelineAppointments';
import NoMeetings from 'components/no-meetings/NoMeetings';
import VetModal from 'pages/dashboard/vet-modal/VetModal';
import LoaderWrapper from 'components/loaderWrapper/LoaderWrapper';

import * as S from './components';
import { useLocalizationContext } from 'common_repo';
import useTimeline from '../timeline/content/useTimeline';

const SinglePetTimeline = ({
  YEAR_FILTER_DATA,
  year,
  setYear,
  timeline,
  loading,
  refetchTimeline
}) => {
  const { t } = useTranslation();
  const { rtl: isRtl } = useLocalizationContext();
  const hasTimelineAppointments = !!timeline.length;
  const [vetUid, setVetUid] = useState(null);

  const handleSetVetUid = (value, e) => {
    e.stopPropagation();
    setVetUid(value);
  };

  const { submitHandler } = useTimeline();

  return (
    <>
      <S.PetTimelineWrapper>
        <S.TimelineFilterWrapper>
          <S.TimelineFilterContainer hasTimelineAppointments={!hasTimelineAppointments}>
            <S.Flex>
              <CalendarImg />
              <S.FilterByText>{t('timeline')}</S.FilterByText>
            </S.Flex>
            <S.Flex>
              <S.FilterByText isTransparent>{t('filter.by')}</S.FilterByText>
              <S.YearSelectorWrapper>
                <Selector
                  hideLabel
                  customHeight={'3.6rem'}
                  onChange={setYear}
                  options={YEAR_FILTER_DATA}
                  value={year}
                  isRtl={isRtl}
                  getOptionLabel={(item) => t(item.label)}
                />
              </S.YearSelectorWrapper>
            </S.Flex>
          </S.TimelineFilterContainer>
        </S.TimelineFilterWrapper>
        <LoaderWrapper isLoading={loading} isTimeline>
          {hasTimelineAppointments ? (
            <TimelineAppointments
              timeline={timeline}
              loading={loading}
              year={year}
              setVetUid={handleSetVetUid}
              singlePet={true}
              submitCalendar={submitHandler}
              refetchTimeline={refetchTimeline}
            />
          ) : (
            <NoMeetings />
          )}
        </LoaderWrapper>
      </S.PetTimelineWrapper>
      {vetUid && <VetModal vetId={vetUid} isOpen={true} handleCloseModal={() => setVetUid(null)} />}
    </>
  );
};

export default SinglePetTimeline;
