import { useMutation, gql } from '@apollo/client';
import { message } from 'antd';

export const useUpdateAppointmentOriginallyScheduledTime = () => {
  const [updateAppointmentOriginallyScheduledTime] = useMutation(
    updateAppointmentOriginallyScheduledTimeMutation
  );
  const handleUpdateAppointmentOriginallyScheduledTime = async (
    appointmentRouteId,
    originalStartTime,
    originalEndTime,
    shouldRecalculateRoute
  ) => {
    try {
      await updateAppointmentOriginallyScheduledTime({
        variables: {
          record: {
            appointmentRouteId,
            originalStartTime,
            originalEndTime,
            shouldRecalculateRoute
          }
        },
        refetchQueries: ['getAppointmentsByDate']
      });
    } catch (e) {
      message.error(e.message, 5000);
      console.log(e);
    }
  };

  return { handleUpdateAppointmentOriginallyScheduledTime };
};

const updateAppointmentOriginallyScheduledTimeMutation = gql`
  mutation updateAppointmentOriginallyScheduledTime(
    $record: UpdateAppointmentOriginallyScheduledTimeRecord!
  ) {
    updateAppointmentOriginallyScheduledTime(record: $record) {
      uid
      originalStartTime
      originalEndTime
    }
  }
`;
