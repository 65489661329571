import React, { memo, useCallback } from 'react';
import { Input } from 'antd';
import PlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';

import AddressChecklist from './AddressChecklist';
import { Wrapper, DropDownContainer, Option } from './components';
const AddressAutocomplete = memo(function Component({
  address,
  onChange,
  onSelect,
  disabled = false,
  placeholder,
  formikAddress,
  setIsAddressValid,
  isChecklistNeeded,
  isError
}) {
  const handleSelect = useCallback(
    (address, placeId) => {
      geocodeByPlaceId(placeId).then((res) => {
        getLatLng(res[0]).then(({ lat, lng }) => {
          const addr = {
            countryCode: '',
            city: '',
            street: '',
            houseNumber: '',
            zipCode: '',
            description: address,
            lat: lat,
            lng: lng
          };
          for (const component of res[0].address_components) {
            const componentType = component.types[0];

            switch (componentType) {
              case 'street_number': {
                addr.houseNumber = component.short_name;
                break;
              }

              case 'route': {
                addr.street = component.long_name;
                break;
              }

              case 'postal_code': {
                addr.zipCode = component.long_name;
                break;
              }

              case 'locality':
                addr.city = component.long_name;
                break;

              case 'country':
                addr.countryCode = component.short_name;
                break;

              case 'administrative_area_level_1':
                addr.state = component.long_name;
                break;
              default: {
                break;
              }
            }
          }
          const newAddressValue = `${addr?.description} ${addr?.zipCode}`;
          onChange(newAddressValue);
          onSelect(addr);
        });
      });
    },
    [onChange, onSelect]
  );
  return (
    <PlacesAutocomplete value={address?.description} onChange={onChange} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Wrapper>
          <Input
            {...getInputProps({
              placeholder: placeholder,
              className: isError ? 'error-input' : 'location-search-input'
            })}
            // placeholder={placeholder}
            // suffix={locationIcon}
          />
          <DropDownContainer>
            {suggestions.map((suggestion) => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' };
              return (
                <div
                  key={suggestion.id}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style
                  })}>
                  <Option>{suggestion.description}</Option>
                </div>
              );
            })}
          </DropDownContainer>

          {isChecklistNeeded && (
            <AddressChecklist formikAddress={formikAddress} setIsAddressValid={setIsAddressValid} />
          )}
        </Wrapper>
      )}
    </PlacesAutocomplete>
  );
});

export default AddressAutocomplete;
