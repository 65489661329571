import { useMemo } from 'react';
import moment from 'moment';
import clone from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';

export const useChartData = (data, vets) => {
  return useMemo(() => {
    let empty = true;
    if (!data || !vets) {
      return { flattenedData: [], allNames: [], empty };
    }

    const monthCaregiverCounts = {};
    const vetMap = {};
    const currentMonth = moment();

    // Create a vetMap with all caregivers and counts initialized to zero
    vets?.forEach((vet) => {
      vetMap[vet.user.uid] = {
        caregiver: `${vet.user.firstName} ${vet.user.lastName}`,
        uid: vet.user.uid,
        count: 0
      };
    });

    // Pre-fill the last 12 months in monthCaregiverCounts
    for (let i = 0; i < 12; i++) {
      const date = currentMonth.clone().subtract(i, 'months');
      const key = date.format('MMM. YY');
      if (!monthCaregiverCounts[key]) {
        monthCaregiverCounts[key] = {
          date: date.toDate(),
          ...clone(vetMap)
        };
      }
    }

    // Populate the counts using the data source
    data?.forEach((entry) => {
      const vetUser = entry.vetUser.uid;
      const key = moment(entry.date).format('MMM. YY');
      if (monthCaregiverCounts[key]) {
        monthCaregiverCounts[key][vetUser].count += 1;
        empty = false;
      }
    });

    // Generate chartData
    const chartData = orderBy(
      Object.keys(monthCaregiverCounts).map((month) => ({
        name: month,
        month: new Date(monthCaregiverCounts[month].date).getMonth(),
        date: monthCaregiverCounts[month].date,
        users: Object.values(monthCaregiverCounts[month]).map((set) => ({
          name: set.caregiver,
          amt: set.count
        }))
      })),
      ['date'],
      ['asc']
    );

    // Extract unique caregiver names
    const allNames = Array.from(
      new Set(chartData.flatMap((entry) => entry.users.map((user) => user.name)))
    ).filter((n) => n);

    // Flatten data for each month
    const flattenedData = chartData?.map((monthSet) => {
      const monthData = { name: monthSet.name };
      allNames.forEach((name) => {
        const user = monthSet.users.find((user) => user.name === name);
        monthData[name] = user ? user.amt : 0;
      });
      return monthData;
    });
    console.log('flattenedData, allNames, empty', flattenedData, allNames, empty);
    return { flattenedData, allNames, empty };
  }, [data, vets]);
};
