import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { List, Button, Avatar, Tooltip, Skeleton } from 'antd';
import { FilterOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import useSchedule from './useSchedule';
import EventDetailsModal from './EventDetailsModal';
import NewAppointmentModal from './modals/NewAppointmentModal';
import NewClientModal from './modals/NewClientModal';
import { useTranslation } from 'react-i18next';
import useActAsUser from '../../../vet-caregivers/outdate/useActAsUser';
import { getName, stringToColor } from '_fsd/shared';
import css from './Schedule.module.css';

const Schedule = (props) => {
  const { t } = useTranslation();
  // calnder variables
  const {
    events,
    setEventCache,
    vets,
    loadingVets,
    setEventsStartDate,
    setEventsEndDate,
    eventsStartDate,
    eventsEndDate
  } = useSchedule();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(false);

  const [selectedVets, setSelectedVets] = useState([]);
  const { handleActAsUser } = useActAsUser();
  const localizer = momentLocalizer(moment);
  // new appointment variables
  const [isNewClientModalOpen, setIsNewClientModalOpen] = useState(false);
  const [isNewAppointmentsModalOpen, setIsNewAppointmentsModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [isDayView, setIsDayView] = useState(true);

  // todo: issue with caching and deps updating after refecth events
  const forceClearCalendar = () => {
    setSelectedEvent(false);
    setIsModalOpen(false);
    setEventCache({});
    setEventsStartDate(moment(eventsStartDate));
    setEventsEndDate(moment(eventsEndDate));
  };

  // ---------------- new appointment functions ---------------//
  const changeStep = useCallback(
    (step) => {
      if (step < currentStep) setCurrentStep(step);
    },
    [currentStep]
  );

  const openNewClientModal = useCallback(() => {
    setIsNewClientModalOpen(true);
    setIsNewAppointmentsModalOpen(false);
  }, []);

  const handleNewAppointmentModalClose = useCallback(() => {
    setIsNewAppointmentsModalOpen(false);
    setCurrentStep(0);
  }, []);

  // ---------------- Calendar functions ---------------//
  const actAsHandler = (userId) => {
    handleActAsUser(userId);
  };

  const toggleEventDetailsModalView = () => {
    setIsModalOpen((isModalOpen) => {
      const state = !isModalOpen;
      return state;
    });
  };

  const handleViewChange = (view) => {
    // Check if the view changed to 'day'
    if (view === 'day') {
      setIsDayView(true);
    } else {
      setIsDayView(false);
    }
  };

  // ===calendar filtering logic===//

  const removeFilters = () => {
    setSelectedVets([]);
  };

  const handleVetSelect = (vetId) => {
    if (selectedVets.includes(vetId)) {
      setSelectedVets(selectedVets.filter((i) => i !== vetId));
    } else {
      setSelectedVets([...selectedVets, vetId]);
    }
  };

  const filteredEvents = events?.filter((event) => {
    if (selectedVets.length === 0) {
      return true;
    }
    return selectedVets.includes(event.vetId);
  });

  // ====== calendar events logic ======//

  const clickEventRef = useRef(null);

  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      ...{
        style: {
          backgroundColor: stringToColor(event.vetId)
        }
      }
    }),
    []
  );

  useEffect(() => {
    /* This is to prevent a memory leak, in the off chance that you
     * teardown your interface prior to the timed method being called.
     */
    return () => {
      window.clearTimeout(clickEventRef?.current);
    };
  }, []);

  const onSelectEvent = useCallback((calEvent) => {
    /**
     * Here we are waiting 250 milliseconds  prior to firing
     * our method. to support both 'click' and 'doubleClick'
     */
    window.clearTimeout(clickEventRef?.current);
    clickEventRef.current = window.setTimeout(() => {
      setSelectedEvent(calEvent);
      toggleEventDetailsModalView();
    }, 50);
  }, []);

  const titleAccessor = (event) => {
    let res = '';
    if (event.eventType == 'TimeslotEvent') {
      const name = event?.timeSlot?.name;
      const { isLocked, isFull } = event ?? {};
      const location = '';
      // const location = event?.timeSlot?.workingAreas?.map((w) => w.name).join(', ') || '';
      const full = isFull ? ' (Full)' : '';
      const locked = isLocked ? ' (Locked)' : '';

      res = name + (location ? ' - ' + location : '') + full + locked;
    } else {
      const client =
        (event?.appointments[0]?.appointments[0]?.subscription?.user?.firstName || '') +
        ' ' +
        (event?.appointments[0]?.appointments[0]?.subscription?.user?.lastName || '');
      res = client;
    }
    return res;
  };

  const tooltipAccessor = (event) => {
    let res = '';
    const name = event?.timeSlot?.name;
    const client =
      (event?.appointments[0]?.appointments[0]?.subscription?.user?.firstName || '') +
      ' ' +
      (event?.appointments[0]?.appointments[0]?.subscription?.user?.lastName || '');
    const { isLocked, isFull } = event ?? {};

    const location =
      '\r\nLocation: ' + (event?.timeSlot?.workingAreas?.map((w) => w.name).join(', ') || '');
    const full = '\r\nFull: ' + (isFull ? 'Yes' : 'No');
    const locked = '\r\nLocked: ' + (isLocked ? 'Yes' : 'No');

    if (event.eventType == 'TimeslotEvent') {
      res = location + full + locked;
    } else {
      res = client + '\r\n\r\nTime-slot: ' + name + location;
    }
    return res;
  };

  const handleRangeChange = async (range) => {
    const start = range.start || new Date(Math.min(...range)) || new Date();
    const end = range.end || new Date(Math.max(...range)) || new Date();

    let startEventsDate = moment(start);
    let endEventsDate = moment(end);

    if (start.toISOString() === end.toISOString()) {
      // set 1 month range if start and end dates are the same
      const year = start.getFullYear();
      const month = start.getMonth();

      startEventsDate = moment(new Date(year, month, 1));
      endEventsDate = moment(new Date(year, month + 1, 0));
    }

    setEventsStartDate(startEventsDate);
    setEventsEndDate(endEventsDate);
  };

  const [centeredOnDate, setCenteredDate] = useState(moment().set({ h: 9, m: 0 }).toDate());

  return (
    <div className={css.container}>
      <div style={{ marginBottom: '40px', maxWidth: '370px' }}>
        <Button
          icon={<PlusOutlined />}
          color="primary"
          className="appointment-btn"
          onClick={() => setIsNewAppointmentsModalOpen(true)}
          block
          style={{ background: '#001529e3', color: 'white', minWidth: '200px', maxWidth: '300px' }}>
          {t('admin.schedule.open_recommender_button')}
        </Button>
      </div>
      <EventDetailsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={toggleEventDetailsModalView}
        event={selectedEvent}
        forceCloseModal={forceClearCalendar}
      />
      <div className={css.layout}>
        <div className={css.filters}>
          <div className={css.filterTitle}>
            <div className={css.title}>
              <FilterOutlined style={{ marginRight: '5px' }} />
              {t('admin.schedule.filters_title')}
            </div>
            <div className={css.clearFilters}>
              <Button
                disabled={selectedVets.length === 0}
                type="default"
                shape="round"
                icon={<FilterOutlined />}
                size="small"
                style={{ width: 'fit-content' }}
                onClick={removeFilters}>
                {t('admin.schedule.clear_filters_button')}
              </Button>
            </div>
          </div>
          <Skeleton
            active
            block
            loading={loadingVets}
            className={css.skeleton}
            title={false}
            paragraph={{ rows: 10 }}>
            <List
              dataSource={vets}
              renderItem={(vet) => (
                <List.Item
                  // loading={loading}
                  style={{ padding: '0', borderWidth: '0px' }}>
                  <Button
                    type={selectedVets.includes(vet?.uid) ? 'primary' : ''}
                    onClick={() => handleVetSelect(vet?.uid)}
                    block
                    className={css.rowItem}
                    size="large">
                    <div className={css.rowItemContent}>
                      <Avatar
                        style={{ backgroundColor: stringToColor(vet.uid), verticalAlign: 'middle' }}
                        size={10}
                      />
                      <span>{getName(vet.user)}</span>
                    </div>
                    <Tooltip title="Open user dashboard">
                      <div className={css.rowItemButton}>
                        <Button
                          type="text"
                          icon={
                            <EyeOutlined
                              onClick={(event) => {
                                event.stopPropagation();
                                actAsHandler(vet.user.uid);
                              }}
                            />
                          }
                        />
                      </div>
                    </Tooltip>
                  </Button>
                </List.Item>
              )}
            />
          </Skeleton>
        </div>
        <div style={{ background: 'white' }}>
          <Calendar
            localizer={localizer}
            events={filteredEvents}
            startAccessor={(event) => {
              return new Date(event.startTime);
            }}
            endAccessor={(event) => {
              return new Date(event.endTime);
            }}
            titleAccessor={titleAccessor}
            tooltipAccessor={tooltipAccessor}
            style={{ height: '75vh', background: 'white' }}
            eventPropGetter={eventPropGetter}
            onSelectEvent={onSelectEvent}
            defaultView="day"
            onView={handleViewChange} // Run the function on view change
            scrollToTime={moment().set({ h: 9, m: 0 }).toDate()}
            date={centeredOnDate}
            onNavigate={setCenteredDate}
            onRangeChange={handleRangeChange}
            popup
            selectable
            resources={isDayView ? vets : undefined}
            resourceAccessor={isDayView ? 'vetId' : undefined}
            resourceIdAccessor={isDayView ? 'uid' : undefined}
            resourceTitleAccessor={(vet) => (
              <div>
                <h3>
                  {vet.user.firstName} {vet.user.lastName}
                </h3>
              </div>
            )}
          />
        </div>
      </div>
      {isNewAppointmentsModalOpen && vets?.[0] && (
        <NewAppointmentModal
          setCalendarDate={(m) => setCenteredDate(m.toDate())}
          vet={vets ? vets[0] : null}
          allVets={vets}
          currentStep={currentStep}
          changeStep={changeStep}
          setCurrentStep={setCurrentStep}
          calendarDate={null}
          // selectedTimeslot={null}
          openNewClientModal={openNewClientModal}
          onRequestClose={handleNewAppointmentModalClose}
          getAppointmentsByTimeslotUid={null}
        />
      )}
      {isNewClientModalOpen && vets?.[0] && (
        <NewClientModal
          setCalendarDate={(m) => setCenteredDate(m.toDate())}
          vet={vets ? vets[0] : null}
          allVets={vets}
          sendRequestCode={null}
          selectedTimeslot={null}
          checkExistingUser={null}
          selectedWorkingArea={null}
          onRequestClose={() => setIsNewClientModalOpen(false)}
          goBack={() => {
            setIsNewClientModalOpen(false);
            setIsNewAppointmentsModalOpen(true);
          }}
          getAppointmentsByTimeslotUid={null}
        />
      )}
    </div>
  );
};

export default Schedule;
