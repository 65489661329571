import { gql } from '@apollo/client';

export const USER_FOR_VET_FRAGMENT = gql`
  fragment USER_FOR_VET_FRAGMENT on User {
    uid
    __typename
    bio
    avatarUrl
    firstName
    lastName
    email
    phonePrefix
    phoneNumber
    bio
    userSettingStartPoint
    userSettings {
      uid
      __typename
      itemValue
      setting {
        uid
        __typename
        description
      }
    }
    address {
      uid
      __typename
      placeId
      zipCode
      city
      description
      street
      lat
      lng
      country {
        uid
        __typename
        name
        code
      }
    }
  }
`;
