import * as React from 'react';

export const EmptyStatsIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="98" height="60" fill="none" viewBox="0 0 98 60">
    <path
      fill="url(#paint0_linear_4041_38446)"
      fillRule="evenodd"
      d="M19.914 11.395q1.5-4.716 2.79-5.873c2.38-2.136 5.67-.974 6.37-.807C31.53 5.3 30.731 1.442 32.84.657q2.108-.784 3.468 1.917 1.204-2.52 3.67-2.312c2.467.208 3.33 8.496 6.72 6.682s7.545-2.23 9.32.467c.385.583.532-.322 3.141-3.413S64.371-.455 69.71 1.345q3.641 1.226 5.99 6.648 0 7.738 11.443 9.16c11.442 1.42 2.56 13.66-11.442 17.244-14.003 3.585-46.243 5.52-63.738-3.537q-17.495-9.054 7.95-19.465z"
      clipRule="evenodd"
      opacity="0.8"></path>
    <path
      fill="url(#paint1_linear_4041_38446)"
      fillRule="evenodd"
      d="M84.225 57.01c-15.864 3.959-70.01-.57-75.773-2.637q-4.208-1.51-7.585-5.682a2.11 2.11 0 0 1 .523-3.12c.34-.212.733-.325 1.134-.325h94.619q2.944 7.806-12.918 11.764"
      clipRule="evenodd"
      opacity="0.675"></path>
    <path fill="#64BED4" d="M32.914 13.378h8.831V31.29h-8.83z"></path>
    <path fill="#90D1E1" d="M32.914 31.29h8.831v7.377h-8.83z"></path>
    <path fill="#22A3C3" d="M32.914 38.667h8.831v11.36h-8.83z"></path>
    <path fill="#64BED4" d="M45.278 21.326h8.83v10.208h-8.83z"></path>
    <path fill="#90D1E1" d="M45.278 31.534h8.83V41.74h-8.83z"></path>
    <path fill="#22A3C3" d="M45.278 41.741h8.83v8.285h-8.83z"></path>
    <path fill="#64BED4" d="M57.64 33.69h8.832v5.445H57.64z"></path>
    <path fill="#90D1E1" d="M57.64 39.135h8.832v5.446H57.64z"></path>
    <path fill="#22A3C3" d="M57.64 44.58h8.832v5.446H57.64z"></path>
    <path fill="#D3EDF3" d="M26.582 8.248h2.376v44.154h-2.376z"></path>
    <path fill="#D3EDF3" d="M70.737 50.026v2.376H26.583v-2.376z"></path>
    <path
      fill="#FAFAFA"
      fillOpacity="0.2"
      d="M32.914 13.378h4.416v36.648h-4.416zM45.278 21.326h4.415v28.7h-4.415zM57.64 33.69h4.416v16.336h-4.415z"></path>
    <defs>
      <linearGradient
        id="paint0_linear_4041_38446"
        x1="50.664"
        x2="50.664"
        y1="27.959"
        y2="-6.304"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#DEDEDE" stopOpacity="0"></stop>
        <stop offset="1" stopColor="#A9A9A9" stopOpacity="0.3"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear_4041_38446"
        x1="49"
        x2="49"
        y1="58.58"
        y2="42.623"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" stopOpacity="0"></stop>
        <stop offset="1" stopColor="#919191" stopOpacity="0.15"></stop>
      </linearGradient>
    </defs>
  </svg>
);
