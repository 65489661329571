import { useState, useCallback, useEffect, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { signUpFlowMutation } from 'gql';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import useUpdateUser from './useUpdateUser';

const useNewAppointmentModal = (
  current,
  setCurrent,
  stepsCount,
  onRequestClose,
  setAddress,
  addressError,
  selectedTimeslot,
  selectedClient,
  setSelectedClient,
  ignoreFloorAppartment,
  form,
  clients,
  loading
) => {
  const { t } = useTranslation();
  const [petsToCreate, setPetsToCreate] = useState([]);
  const [clientToEdit, setClientToEdit] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [petList, setPetList] = useState([]);

  const { updateUser } = useUpdateUser(() => {}, onRequestClose);

  const [signUp] = useMutation(signUpFlowMutation);

  // Reset selected client if clients list changes, and It's not available in the new list
  useEffect(() => {
    if (selectedClient?.uid && clients.length) {
      const client = clients.find((client) => client.user?.uid === selectedClient?.user?.uid);
      if (!client) setSelectedClient(null);
    }
  }, [selectedClient, clients]);

  const isNextButtonDisabled = useMemo(() => {
    if (current === 0) {
      const address = selectedClient?.user?.address;
      return !address;
    }
    if (current == 1) {
      const firstStepValues = form.getFieldsValue(['floor', 'apartment']);
      const firstStepIsFilled =
        Object.keys(firstStepValues).filter((value) => firstStepValues[value]).length === 2;
      const errorOnFirstStep =
        form.getFieldsError(['floor', 'apartment']).filter(({ errors }) => errors.length).length >
        0;

      if (ignoreFloorAppartment) {
        return !selectedTimeslot?.uid || addressError;
      } else {
        return !firstStepIsFilled || errorOnFirstStep || !selectedTimeslot?.uid || addressError;
      }
    }

    return !clients?.length || loading || !selectedClient;
  }, [current, clients, loading, selectedClient, selectedTimeslot, addressError]);

  const onClientSelect = (client) => {
    setSelectedClient(client);
    setAddress(client?.user?.address);
    form.resetFields(['pets', 'concern', 'condition']);
    setPetList([]);
    setPetsToCreate([]);
  };

  const handleSignUp = useCallback(async (values) => {
    setSubmitting(true);
    signUp({
      variables: {
        record: values
      },
      refetchQueries: ['getCalendarEvents', 'getTimeSlots', 'getAppointmentsByDate']
    })
      .then((res) => {
        if (res?.data?.signUpFlow) {
          message.success(t('admin.recommender.new_appointment.success_message'));
          onRequestClose();
          setCurrent(0);
        }
      })
      .catch(() => {
        message.success(t('recommender.new_appointment.error_message'));
      })
      .finally(() => {
        setSubmitting(false);
      });
  }, []);

  const onNextClick = useCallback(() => {
    if (!isNextButtonDisabled) {
      if (current !== stepsCount - 1) {
        setCurrent(++current);
      } else {
        form.submit();
      }
    }
  }, [isNextButtonDisabled, current]);

  const onClientEdit = useCallback((e, client) => {
    e.stopPropagation();
    setClientToEdit(client);
  }, []);

  const onAddressSubmit = useCallback((client, values, address, propertyType, onRequestClose) => {
    const data = {
      email: client?.email,
      lastName: client?.lastName,
      firstName: client?.firstName,
      address: {
        ...values,
        ...address,
        propertyType,
        houseNumber: Number(address?.houseNumber)
      },
      floor: Number(values?.floor) || Number(client?.address?.floor),
      comment: values.userComment,
      apartment: values.apartment
    };
    delete data.address.country;
    delete data.address.address;
    delete data.address.__typename;
    delete data.address.userComment;
    updateUser(data, client?.uid, onRequestClose);
  }, []);

  return {
    petsToCreate,
    setPetsToCreate,
    selectedClient,
    setSelectedClient,
    clientToEdit,
    setClientToEdit,
    petList,
    setPetList,
    onClientSelect,
    onClientEdit,
    isNextButtonDisabled,
    onNextClick,
    form,
    handleSignUp,
    isSubmitting,
    onAddressSubmit
  };
};

export default useNewAppointmentModal;
