import React, { useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { CustomTooltip } from './CustomTooltip';
import css from './MonthlyAppointmentSummaryChart.module.css';
import { useTranslation } from 'react-i18next';
import { EmptyStatsIcon, generateColors } from '_fsd/shared';
import { EmptyBox } from '_fsd/features';

const defaultColors = ['#9B9DEF', '#AEE9BD', '#C7BBFB', '#FF9A05', '#FAC079', '#7AC8DB', '#52A1BF'];

export const MonthlyAppointmentSummaryChart = ({ data, width = 500, height = 300 }) => {
  const { t } = useTranslation();
  const { allNames, flattenedData, empty } = data;

  const colors = useMemo(() => {
    if (allNames?.length > 0) {
      return generateColors(allNames.length, defaultColors);
    }
    return [];
  }, [allNames.length]);

  return (
    <div className={css.chartContainer}>
      <p className={css.titleText}>{t('admin_dashboard.monthly_appointments')}</p>
      {!empty ? (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={width}
            height={height}
            data={flattenedData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 0
            }}>
            <CartesianGrid strokeDasharray="2 2" />
            <XAxis dataKey="name" />
            <YAxis allowDecimals={false} />
            <Tooltip content={<CustomTooltip />} filterNull={false} />
            {allNames.map((name, index) => (
              <Bar key={name} dataKey={name} stackId="a" fill={colors[index]} />
            ))}
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <EmptyBox className={css.empty} label={t('admin_dashboard.empty_stats')}>
          <EmptyStatsIcon />
        </EmptyBox>
      )}
    </div>
  );
};
