import React from 'react';

import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LoopOnce } from 'assets/icons/loop-once.svg';

import {
  excludeTimeSlotMutation,
  toggleIsLockedMutation,
  excludeDateFromRecurringTimeSlotMutation
} from 'gql';

import { parseTimeSlotHour } from 'utils/helpers';
import TimeSlotWorkingAreas from 'components/timeslot-working-areas/TimeSlotWorkingAreas';
import { ActionColumn } from '../../../../_fsd/features';
import { processDate } from '_fsd/entities/timeslot';

const useTimeslots = (setUpdateTimeslotData, calendarDate, refetchAuthVet, setSelectedTimeslot) => {
  const { t } = useTranslation();
  const [excludeTimeSlot] = useMutation(excludeTimeSlotMutation);
  const [excludeDateFromRecurringTimeSlot] = useMutation(excludeDateFromRecurringTimeSlotMutation);
  const [toggleIsLocked] = useMutation(toggleIsLockedMutation);

  const toggleIsLockedHandler = async (id, date) => {
    try {
      await toggleIsLocked({
        variables: {
          record: {
            date: processDate(date),
            timeSlotId: id
          }
        }
      });
      message.success(t('route.lock_message'));
      refetchAuthVet();
    } catch (err) {
      console.error(err);
    }
  };
  const excludeTimeSlotHandler = async (timeSlotId, isRecurring) => {
    try {
      const record = {
        timeSlotId,
        date: calendarDate
      };
      isRecurring
        ? await excludeDateFromRecurringTimeSlot({
            variables: {
              record
            }
          })
        : await excludeTimeSlot({
            variables: {
              record
            }
          });

      message.success(
        isRecurring ? t('recurring_time_slot.exclude_success') : t('vet.timeslots.exclude.success')
      );
      refetchAuthVet();
    } catch (err) {
      console.error(err);
      message.error(
        isRecurring ? t('recurring_time_slot.exclude_error') : t('vet.timeslots.exclude.error')
      );
    }
  };

  const timeslotsColumn = [
    {
      title: '',
      dataIndex: 'warnings',
      key: 'warnings',
      align: 'center',
      render: (text, record) => {
        return (
          <div className="warnings-cell">
            {!record.isRecurring ? <LoopOnce className="loop-once-icon" /> : null}
          </div>
        );
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center'
    },
    {
      title: 'Hours',
      dataIndex: 'hours',
      key: 'timeslotHours',
      align: 'center',
      render: (text, record) => (
        <div className="hours-wrapper">{`${parseTimeSlotHour(
          record.startTime
        )} - ${parseTimeSlotHour(record.endTime)}`}</div>
      )
    },
    {
      title: 'Working Area',
      dataIndex: 'workingArea',
      key: 'timeslotWorkingHours',
      align: 'center',
      render: (text, record) => <TimeSlotWorkingAreas timeSlot={record} />
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'timeslotActions',
      align: 'center',
      render: (text, record) => {
        return (
          <ActionColumn
            record={record}
            refetchTimeslots={refetchAuthVet}
            toggleIsLockedHandler={toggleIsLockedHandler}
            setUpdateTimeslotData={setUpdateTimeslotData}
            excludeTimeSlotHandler={excludeTimeSlotHandler}
            setSelectedTimeslot={setSelectedTimeslot}
            calendarDate={calendarDate}
          />
        );
      }
    }
  ];

  return { timeslotsColumn, toggleIsLockedHandler, excludeTimeSlotHandler };
};

export default useTimeslots;
