import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import RadioInput from 'components/vet-components/vet-web-inputs/radio-input/RadioInput';
import Input from 'components/vet-components/vet-web-inputs/input/Input';
import Select from 'components/vet-components/vet-web-inputs/select-input/Select';
import InputNumber from 'components/vet-components/vet-web-inputs/input-number/InputNumber';
import TextArea from 'components/vet-components/vet-web-inputs/text-area/TextArea';
import { getLanguagesQuery } from 'gql';
import { VetCommuteMethodArr } from 'constants/enums';
import dropdownIcon from 'assets/icons/vet-profile-dropdown.svg';
import theme from 'styles/theme';
import * as S from './components';

// props.form
const VetProfessionalInfo = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);

  const { t } = useTranslation();
  const isTablet = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.tabletL}px)`
  });
  const { data: languagesData } = useQuery(getLanguagesQuery);
  // const [address, setAddress] = useState('');
  // const handleSelectAddress = (area) => {
  //   form.setFieldsValue({
  //     address: {
  //       city: area.city,
  //       street: area.street,
  //       countryCode: area.countryCode,
  //       description: area.description,
  //       zipCode: area.zipCode,
  //       lat: area.lat,
  //       lng: area.lng,
  //     },
  //     addressDescription: area.description,
  //   });
  // };

  useEffect(() => {
    setIsDropdownOpen(true);
  }, [isTablet]);

  return (
    <S.Wrapper isDropdownOpen={isDropdownOpen}>
      <S.Header>
        <S.Flex>
          <S.Heading>{t('professional.information')}</S.Heading>
          {/* <S.VisibilityInfo isDropdownOpen={isDropdownOpen}>*/}
          {/*  <S.EyeIcon src={eye} /> - {t('fields.visible.to.clients')}*/}
          {/* </S.VisibilityInfo>*/}
        </S.Flex>

        <S.DropdownIcon
          src={dropdownIcon}
          alt="dropdown"
          onClick={() => {
            setIsDropdownOpen((prev) => !prev);
          }}
          isDropdownOpen={isDropdownOpen}
        />
      </S.Header>

      <S.Content isDropdownOpen={isDropdownOpen}>
        <Row gutter={24} align="stretch">
          <Col xs={24} lg={4}>
            <RadioInput name="status" />
          </Col>

          <Col xs={24} lg={13}>
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Input name="licenseNumber" label={t('license.number')} />
              </Col>
              <Col xs={24} lg={12}>
                <InputNumber
                  hasVisibilitySign
                  name="experience"
                  label={t('experience.years')}
                  min={0}
                  max={999}
                />
              </Col>
            </Row>
            <div className="language-row">
              <Row gutter={24}>
                <Col xs={24} lg={12} style={{ height: 'auto!important' }}>
                  <Select
                    hasVisibilitySign
                    name="languageIds"
                    label={t('languages')}
                    mode="multiple"
                    options={languagesData?.getLanguages.map(({ uid, name }) => {
                      return { displayValue: name, value: uid };
                    })}
                  />
                </Col>
                <Col xs={24} lg={12}>
                  <Select
                    name="mainCommuteMethod"
                    label={t('vet_profile.main.commute.method.required')}
                    options={VetCommuteMethodArr}
                    required
                    hasVisibilitySign
                    infoIcon
                    tooltipText={t('vet_profile.commute.method.tooltip')}
                  />
                </Col>
              </Row>
            </div>
          </Col>

          <Col xs={24} lg={7}>
            <TextArea
              hasVisibilitySign
              name="bio"
              label={t('vet_profile.short_biography')}
              placeholder={t('biography.placeholder')}
              limit={1000}
            />
          </Col>
        </Row>
      </S.Content>
    </S.Wrapper>
  );
};

export default VetProfessionalInfo;
