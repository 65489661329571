import React, { useState } from 'react';

import useTimeline from './useTimeline';
import TimelineFilter from '../filter/TimelineFilter';
import TimelinePets from '../pets/TimelinePets';
import TimelineAppointments from '../appointments/TimelineAppointments';
import { YEAR_FILTER_DATA } from 'constants/year';
import * as S from './components';

const Timeline = ({ activeTab, activeTabEnum, setVetUid }) => {
  const [year, setYear] = useState(YEAR_FILTER_DATA[0]);
  const [selectedPets, setSelectedPets] = useState([]);
  const [petsData, setPetsData] = useState([]);

  const { timeline, loading, pets, submitHandler, time, refetchTimeline } = useTimeline(
    year.value,
    selectedPets
  );

  return (
    <S.Wrapper>
      <S.TimelineContent isActiveTab={activeTab === activeTabEnum.timeline}>
        <TimelineFilter
          petsData={petsData.length ? petsData : pets}
          year={year}
          setYear={setYear}
          setPetsData={setPetsData}
          selectedPets={selectedPets}
          setSelectedPets={setSelectedPets}
          YEAR_FILTER_DATA={YEAR_FILTER_DATA}
        />

        <TimelineAppointments
          timeline={timeline}
          time={time}
          loading={loading}
          year={year}
          setVetUid={setVetUid}
          submitCalendar={submitHandler}
          refetchTimeline={refetchTimeline}
        />
      </S.TimelineContent>
      <TimelinePets isActiveTab={activeTab === activeTabEnum.pets} pets={pets} />
    </S.Wrapper>
  );
};

export default Timeline;
