import React from 'react';
import cls from 'classnames';
import css from './VerticalLine.module.css';

export const VerticalLine = React.forwardRef(function Component(
  { children, className, ...props },
  ref
) {
  return (
    <div ref={ref} className={cls(css.verticalLine, className)} {...props}>
      {children}
    </div>
  );
});
