import { useQuery, gql } from '@apollo/client';

export const useAddress = (variables = {}, options = {}) => {
  const { data, ...rest } = useQuery(query, { variables, ...options, fetchPolicy: 'cache-only' });
  return { data: data?.address, ...rest };
};

export const ADDRESS_FRAGMENT = gql`
  fragment FAddress on Address {
    uid
    __typename
    country {
      uid
      name
      code
    }
    city
    street
    description
    houseNumber
    zipCode
    floor
    apartment
    propertyType
    comment
    placeId
    lat
    lng
  }
`;

const query = gql`
  query address($id: String!) {
    address(id: $id) {
      ...FAddress
    }
  }
`;
