import React from 'react';
import { message, Select, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { Label, Modal } from '_fsd/shared';
import { useGetArchiveReasons, useArchivePet } from '_fsd/features/archive-pet-modal/api';
import Button from 'components/common/button/Button';
import { useArchivePetForm } from '../hooks';
import { Button as FSDButton } from '_fsd/shared';
import css from './ArchivePetModal.module.css';
import { useHistory } from 'react-router-dom';

export const ArchivePetModal = ({ isModalOpen, closeModal, petIdToArchive, isPetsWeb }) => {
  const { t } = useTranslation();
  const { data: reasons } = useGetArchiveReasons();
  const { archivePet } = useArchivePet();
  const history = useHistory();

  const formik = useArchivePetForm({
    onSubmit: async (values, formikHelpers) => {
      formikHelpers.setSubmitting(true);
      archivePet({
        variables: {
          data: { ...values, uid: petIdToArchive }
        },
        refetchQueries: [
          'me',
          'getPetsByUser',
          'getPetsByOwner',
          'getPetOwners',
          'getPetOwnersTotals'
        ]
      })
        .then(() => {
          message.success(t('archive_pet.message_success'));
          if (isPetsWeb) {
            history.replace('/');
          }
          closeModal();
        })
        .catch((err) => {
          message.error(err?.message || t('archive_pet.message_error'));
        });
      formikHelpers.setSubmitting(false);
    }
  });

  return (
    <Modal
      title={t('archive_pet.title')}
      isOpen={isModalOpen}
      customFooter={() => null}
      onClose={closeModal}>
      <div className={css.form}>
        <Label>{t('archive_pet.reason')}</Label>
        <Select
          className={css.select}
          onSelect={(v) => formik.setFieldValue('archiveReason', v)}
          name="archiveReason"
          placeholder={t('archive_pet.reason_placeholder')}
          options={reasons}
        />

        <Label className={css.label}>{t('archive_pet.comments')}</Label>
        <Input.TextArea
          className={css.textarea}
          name="comment"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        {isPetsWeb ? (
          <div className={css.petswebButtons}>
            <Button
              type="button"
              label={t('cancel')}
              color="secondary"
              submitHandler={closeModal}
            />
            <Button
              disabled={Object.keys(formik.errors).length > 0 || formik.isSubmitting}
              color="primary"
              type="submit"
              onClick={formik.submitForm}
              label={t('save')}
            />
          </div>
        ) : (
          <div className={css.vetsButtons}>
            <FSDButton type="default" size="large" className="cancel-button" onClick={closeModal}>
              {t('cancel')}
            </FSDButton>
            <FSDButton
              type="primary"
              size="large"
              disabled={Object.keys(formik.errors).length > 0 || formik.isSubmitting}
              onClick={formik.submitForm}>
              {t('save')}
            </FSDButton>
          </div>
        )}
      </div>
    </Modal>
  );
};
