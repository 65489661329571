import React, { useContext, useEffect } from 'react';
import AuthContext from '../../../contexts/AuthContext';
import { DASHBOARD } from '../../../constants/client';
import SignUpFormVetAdmin from '../../../components/sign-in-form-vet-admin/SignUpFormVetAdmin';
import style from './SignInVetAdmin.module.css';
import { useTranslation } from 'react-i18next';
import { Link as LinkDom } from 'react-router-dom';
import cls from 'classnames';
import { cssText, cssColor } from '../../../_fsd/shared';
import { ReactComponent as SvgArrowLef } from '../../../assets/icons/arrow-left.svg';
import { Container, Content, Wrapper } from '../sign-up/components';
import { PageTitle } from '../../../components/common/texts/components';

const SignInVetAdmin = ({ history }) => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      history.push(DASHBOARD);
    }
  }, [user]);

  return (
    <Wrapper>
      <LinkDom to={'..'} className={cls(cssText.s14h24w5, cssColor.title85, style.back)}>
        <SvgArrowLef /> Back
      </LinkDom>
      <Container>
        <PageTitle>{t('Clinic Admin Sign In')}</PageTitle>
        <Content>
          <SignUpFormVetAdmin />
          <div className={style.bottom}>
            <span className={style.question}>{t('To sign up, please ')}</span>
            <a href={'https://kumba.pet/#Form'} className={style.link}>
              {t('contact us.')}
            </a>
          </div>
        </Content>
      </Container>
      <div />
    </Wrapper>
  );
};

export default SignInVetAdmin;
