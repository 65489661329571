import { message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { updateUserMutation } from 'gql';
import { useTranslation } from 'react-i18next';

const useUpdateUser = (refetchClients) => {
  const { t } = useTranslation();
  const [updateUserData] = useMutation(updateUserMutation, {
    refetchQueries: ['getPetOwnerById', 'getPetOwners']
  });

  const updateUser = async (values, uid, onRequestClose) => {
    const { address, floor, apartment, comment, ...rest } = values;

    try {
      await updateUserData({
        variables: {
          record: {
            uid: uid,
            address: { ...address, floor, apartment, comment },
            userArgs: { ...rest }
          }
        }
      });
      message.success(t('admin.updated.successfully'));
      if (onRequestClose) onRequestClose();
    } catch (e) {
      const error = e?.graphQLErrors?.[0]?.message;
      message.error(error || t('admin.failed.to.update'), 10);
    }
  };
  return { updateUser };
};

export default useUpdateUser;
