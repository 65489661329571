import { ApolloClient, InMemoryCache, ApolloLink, split } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import storage from 'utils/storage';
import { createFragmentRegistry } from '@apollo/client/cache';
import { ADDRESS_FRAGMENT } from '_fsd/entities/address';
import { USER_FOR_VET_FRAGMENT } from '_fsd/entities/vet';

const uri = process.env.REACT_APP_GRAPHQL_URI;
const wsUrl = process.env.REACT_APP_API_SUBSCRIPTION_URI;

const uploadLink = createUploadLink({
  uri,
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
});

const wsLink = new WebSocketLink({
  uri: wsUrl,
  options: {
    reconnect: true,
    connectionParams: async () => {
      const token = storage.getToken();
      return {
        authorization: token ? `Bearer ${token}` : ''
      };
    },
    connectionCallback: (err) => {
      if (err) {
        console.log('connection', err);
      }
    }
  }
});

const authLink = setContext((_, { headers, ...rest }) => {
  const token = storage.getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    },
    ...rest
  };
});

const uploadLinkWithAuth = authLink.concat(uploadLink);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((props) => {
      const { extensions } = props;
      console.log('[GraphQL Error]', JSON.stringify(props));
      if (extensions.exception.response?.statusCode === 401) {
        storage.clearToken();
        window.location = '/';
      }
    });
  }

  if (networkError) {
    console.log('[Network error]:', networkError);
  }
});

const cache = new InMemoryCache({
  dataIdFromObject: (o) => o.uid,
  addTypename: false,
  fragments: createFragmentRegistry(ADDRESS_FRAGMENT, USER_FOR_VET_FRAGMENT)
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  uploadLinkWithAuth
);

export default new ApolloClient({
  cache,
  link: ApolloLink.from([errorLink, link]),
  connectToDevTools: true
});
