import React from 'react';
import { ClientCardSearchable } from '_fsd/features/client-card-searchable';
import ClientsEmptyState from './ClientsEmptyState';
import InfiniteScroll from 'react-infinite-scroll-component';
import { List, Skeleton } from 'antd';

const ModalContent = ({
  total,
  clients,
  vet,
  onClientSelect,
  selectedClient,
  loading,
  onClientEdit,
  fetchPetOwners
}) => {
  if (!clients.length && !loading) return <ClientsEmptyState vet={vet} />;

  const loadMoreData = () => {
    if (loading) {
      return;
    }

    fetchPetOwners();
  };

  return (
    <div className={`appointments-content-wrapper ${loading ? 'd-flex' : ''}`}>
      <div
        id="scrollableDiv"
        style={{
          overflow: 'auto'
        }}>
        <InfiniteScroll
          dataLength={clients.length}
          next={loadMoreData}
          hasMore={clients.length < total}
          loading={loading}
          loader={
            <Skeleton
              avatar
              paragraph={{
                rows: 1
              }}
              active
            />
          }
          scrollableTarget="scrollableDiv">
          <List
            dataSource={clients}
            renderItem={(item) => (
              <ClientCardSearchable
                key={item?.uid}
                item={item}
                onClientSelect={onClientSelect}
                selectedClient={selectedClient}
                onClientEdit={onClientEdit}
              />
            )}
          />
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default ModalContent;
