import React from 'react';
import css from './RecurringTimeslotListCreatable.module.css';
import cls from 'classnames';
import { Button, cssColor as colors, cssText as text } from '../../../shared';
import { useTranslation } from 'react-i18next';
import { RecurringTimeSlotList } from '_fsd/features/recurring-timeslot-list';

export const RecurringTimeslotListCreatable = ({
  onClickNewRecurringTimeSlot,
  timeSlots,
  loading,
  setSelectedTimeSlot,
  setIsRecurringTimeSlotPageOpen,
  setIsCopying,
  onRemove,
  refetchTimeSlots
}) => {
  const { t } = useTranslation();

  return (
    <div className={css.container}>
      <div className={css.headerSticky}>
        <div className={css.header}>
          <span className={text.s16h24w5}>{t('recurring_time_slot.timeslot_list_title')}</span>
          <Button
            className={cls(colors.cBlueBackground, css.createButton, colors.cWhiteImportant)}
            onClick={onClickNewRecurringTimeSlot}>
            {t('recurring_time_slot.create')}
          </Button>
        </div>
      </div>

      <RecurringTimeSlotList
        onRemove={onRemove}
        timeSlots={timeSlots}
        loading={loading}
        onClickNewRecurringTimeSlot={() => setIsRecurringTimeSlotPageOpen(true)}
        setSelectedTimeSlot={setSelectedTimeSlot}
        setIsRecurringTimeSlotPageOpen={setIsRecurringTimeSlotPageOpen}
        refetchTimeSlots={refetchTimeSlots}
        setIsCopying={setIsCopying}
      />
    </div>
  );
};
