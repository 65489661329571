import React, { useCallback, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import theme from 'styles/theme';
import dayjs from 'dayjs';

import { ReactComponent as Arrow } from 'assets/icons/arrow-down-gray.svg';
import { ReactComponent as Dots } from 'assets/icons/dots-gray.svg';
import { ReactComponent as Pencil } from 'assets/icons/pen-icon.svg';
import { ReactComponent as Copy } from 'assets/icons/copy.svg';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import { ReactComponent as Trash } from 'assets/icons/trash-red.svg';
import { ReactComponent as Folder } from 'assets/icons/folder-open.svg';
import { ReactComponent as Done } from 'assets/icons/done-mark.svg';

import useOutsideClickDetector from 'hooks/useOutsideClickDetector';
import { isSterilized } from 'constants/pet.js';
import { BOOK } from '../../constants/client';
import { petImages } from 'constants/pet.js';
import useSinglePetDetails from './useSinglePetDetails';

import VetModal from 'pages/dashboard/vet-modal/VetModal';
import RemovePetModal from 'components/remove-pet-modal/RemovePetModal';
import SinglePetDetailsLoader from './SinglePetDetailsLoader';
import PetModal from 'components/pet-modal/PetModal';
import { ArchivePetModal } from '_fsd/features/archive-pet-modal';

import * as S from './components';
import { Tooltip } from 'antd';

dayjs.extend(relativeTime);

const SinglePetDetails = ({ timeline, pet }) => {
  const {
    petData,
    loading,
    vet,
    openSettings,
    removeModal,
    setRemoveModal,
    setOpenSettings,
    settingsRef,
    closeSettings,
    handleRemovePet,
    profileCompleted,
    isArchiveModalOpen,
    setIsArchiveModalOpen
  } = useSinglePetDetails();
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.mobile}px)`
  });

  const [vetModalOpen, setVetModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [openDetails, setOpenDetails] = useState(true);

  const copyText = () => {
    navigator.clipboard.writeText(petData?.chipNumber || '--');
    setCopied(true);
    setTimeout(() => setCopied(false), 2200);
  };

  useOutsideClickDetector(settingsRef, closeSettings);

  const handleBookVisit = useCallback(() => {
    history.push(BOOK.replace(':step?', ''));
  }, []);

  const petCanBeDeleted = useMemo(() => {
    return petData?.nextAppointmentDate < dayjs().format('YYYY-MM-DD');
  }, [petData]);

  return (
    <S.Wrapper openDetails={openDetails}>
      <S.ProfileDetailsHeader openDetails={openDetails}>
        <S.ProfileHeaderTitle>{t('profile.details')}</S.ProfileHeaderTitle>
        <Arrow onClick={() => setOpenDetails((prev) => !prev)} />
      </S.ProfileDetailsHeader>
      <S.PetInfo openDetails={openDetails}>
        {loading ? (
          <SinglePetDetailsLoader isMobile={isMobile} />
        ) : (
          <S.PetAvatar
            src={petData?.avatarUrl || petImages[petData?.type?.name]}
            alt="pet avatar"
          />
        )}
        {petData?.activeSubscription ? (
          <>
            <S.PetDetailsHeader isPetAvatarsHeader>
              {isMobile && (
                <S.PetCommonInfo isSubscribed>
                  <S.PetName>{petData?.name}</S.PetName>
                  <S.DotsWrapper ref={settingsRef}>
                    <Dots onClick={() => setOpenSettings((prev) => !prev)} />
                    {openSettings && (
                      <S.Settings>
                        <S.Setting>
                          <Download fill="#687A9E" />
                          {t('download.medical.record')}
                        </S.Setting>
                        <S.Setting onClick={() => setEditOpen((prev) => !prev)}>
                          <Pencil fill="#687a9e" />
                          {t('edit.pet')}
                        </S.Setting>
                        {petCanBeDeleted ? (
                          <S.Setting onClick={() => setRemoveModal((prev) => !prev)}>
                            <Trash />
                            {t('remove.pet')}
                          </S.Setting>
                        ) : (
                          <Tooltip title={t('this.pet.has.appointments')} trigger="hover">
                            <S.Setting>
                              <Trash />
                              {t('remove.pet')}
                            </S.Setting>
                          </Tooltip>
                        )}
                      </S.Settings>
                    )}
                  </S.DotsWrapper>
                </S.PetCommonInfo>
              )}
              {/* <S.SubscribeInfo> */}
              {/* <S.WellnessPlan>
                {t('wellness.plan')}: {petPlan}
              </S.WellnessPlan> */}
              {/* <S.VetInfo onClick={() => setVetModalOpen((prev) => !prev)}>
                {vet?.user?.avatarUrl ? (
                  <S.VetImage src={vet?.user?.avatarUrl} />
                ) : (
                  <S.VetAvatarByName>
                    {getInitialLetters(
                      null,
                      vet?.user?.firstName,
                      vet?.user?.lastName,
                    )}
                  </S.VetAvatarByName>
                )}
                <h2>
                  {t('dr')} {vet?.user?.firstName[0]}. {vet?.user?.lastName}
                </h2>
              </S.VetInfo> */}
              {/* </S.SubscribeInfo> */}
              {isMobile ? (
                <S.ExtraVisitButton onClick={handleBookVisit}>
                  {t('book.a.visit')}
                </S.ExtraVisitButton>
              ) : (
                <S.ExtraVisitButton onClick={handleBookVisit}>
                  {t('book.a.visit')}
                </S.ExtraVisitButton>
              )}
            </S.PetDetailsHeader>
          </>
        ) : isMobile ? (
          <S.PetDetailsHeader>
            <S.PetCommonInfo>
              <S.PetName>{petData?.name}</S.PetName>
              <S.DotsWrapper ref={settingsRef}>
                <Dots onClick={() => setOpenSettings((prev) => !prev)} />
                {openSettings && (
                  <S.Settings>
                    <S.Setting>
                      <Download fill="#687A9E" />
                      {t('download.medical.record')}
                    </S.Setting>
                    <S.Setting onClick={() => setEditOpen((prev) => !prev)}>
                      <Pencil fill="#687a9e" />
                      {t('edit.pet')}
                    </S.Setting>
                    {petCanBeDeleted ? (
                      <S.Setting onClick={() => setRemoveModal((prev) => !prev)}>
                        <Trash />
                        {t('remove.pet')}
                      </S.Setting>
                    ) : (
                      <Tooltip title={t('this.pet.has.appointments')} trigger="hover">
                        <S.Setting>
                          <Trash />
                          {t('remove.pet')}
                        </S.Setting>
                      </Tooltip>
                    )}
                  </S.Settings>
                )}
              </S.DotsWrapper>
            </S.PetCommonInfo>
            {/* <S.SubscribeButton>{t('subscribe')}</S.SubscribeButton> */}
            <S.ExtraVisitButton onClick={handleBookVisit}>{t('book.a.visit')}</S.ExtraVisitButton>
          </S.PetDetailsHeader>
        ) : (
          <S.ExtraVisitButton onClick={handleBookVisit}>{t('book.a.visit')}</S.ExtraVisitButton>
        )}
      </S.PetInfo>
      {openDetails && (
        <S.Details>
          <S.PetDetailsWrapper>
            {!isMobile && (
              <S.PetDetailsHeader>
                <S.PetName>{petData?.name}</S.PetName>
                {!petData?.activeSubscription && (
                  <S.DotsWrapper ref={settingsRef}>
                    <Dots onClick={() => setOpenSettings((prev) => !prev)} />
                    {openSettings && (
                      <S.Settings>
                        {/* {pet && timeline && me && (
                          <PDFDownloadLink
                            document={
                              <PDFRenderer
                                timeline={timeline}
                                pet={pet}
                                me={me}
                              />
                            }
                            fileName="pet-info.pdf"
                          >
                            <S.Setting>
                              <Download fill="#687A9E" />
                              {t('download.medical.record')}
                            </S.Setting>
                          </PDFDownloadLink>
                        )} */}
                        <S.Setting onClick={() => setIsArchiveModalOpen(true)}>
                          <Folder />
                          {t('client-modal.pet-actions.archive')}
                        </S.Setting>
                        {petCanBeDeleted ? (
                          <S.Setting onClick={() => setRemoveModal((prev) => !prev)}>
                            <Trash />
                            {t('remove.pet')}
                          </S.Setting>
                        ) : (
                          <Tooltip title={t('this.pet.has.appointments')} trigger="hover">
                            <S.Setting>
                              <Trash />
                              {t('remove.pet')}
                            </S.Setting>
                          </Tooltip>
                        )}
                      </S.Settings>
                    )}
                  </S.DotsWrapper>
                )}
                <S.PencilWrapper onClick={() => setEditOpen((prev) => !prev)}>
                  <Pencil />
                </S.PencilWrapper>
                {profileCompleted !== 100 && (
                  <>
                    <S.ProfileCompleteBar>
                      <S.Bar percentage={profileCompleted} />
                    </S.ProfileCompleteBar>
                    <S.ProfileComplete>
                      {t('profile.completed.on')} {profileCompleted}%
                    </S.ProfileComplete>
                  </>
                )}
              </S.PetDetailsHeader>
            )}
            {profileCompleted !== 100 && isMobile && (
              <S.BarWrapper>
                <S.ProfileCompleteBar>
                  <S.Bar percentage={profileCompleted} />
                </S.ProfileCompleteBar>
                <S.ProfileComplete>
                  {t('profile.completed.on')} {profileCompleted}%
                </S.ProfileComplete>
              </S.BarWrapper>
            )}
            <S.PetDetails>
              <S.Detail>
                <S.DetailTitle>{t('gender')}</S.DetailTitle>
                <S.DetailText>{petData?.gender}</S.DetailText>
              </S.Detail>
              <S.Detail>
                <S.DetailTitle>{t('age')}</S.DetailTitle>
                <S.DetailText>
                  {petData?.birthDate ? dayjs(petData?.birthDate).from(new Date(), true) : ''}
                </S.DetailText>
              </S.Detail>
              <S.Detail>
                <S.DetailTitle>{t('weight')}</S.DetailTitle>
                <S.DetailText>{petData?.weight ? petData?.weight : '--'}</S.DetailText>
              </S.Detail>
              <S.Detail>
                <S.DetailTitle>{t('breed')}</S.DetailTitle>
                <S.DetailText>{petData?.breed || '--'}</S.DetailText>
              </S.Detail>
              <S.Detail copy>
                <S.ChipHeader>
                  <S.DetailTitle>{t('chip.number')}</S.DetailTitle>
                  <Copy stroke={copied ? theme.colors.darkGreen : '#8A94A6'} onClick={copyText} />
                </S.ChipHeader>
                <S.DetailText>{petData?.chipNumber ? petData?.chipNumber : '--'}</S.DetailText>
                {copied && (
                  <S.CopiedAlert>
                    <Done />
                    {t('copied.to.clipboard')}
                  </S.CopiedAlert>
                )}
              </S.Detail>
              <S.Detail>
                <S.DetailTitle>{t('spray.neuter')}</S.DetailTitle>
                <S.DetailText>
                  {isSterilized.find((element) => element.value === petData?.isSterilized)?.label ||
                    '--'}
                </S.DetailText>
              </S.Detail>
              <S.Detail>
                <S.DetailTitle>{t('food.type')}</S.DetailTitle>
                <S.DetailText>{petData?.foodType || '--'}</S.DetailText>
              </S.Detail>
            </S.PetDetails>
            <S.MedicalInfoWrapper>
              <S.MedicalInfo>
                <S.MedicalInfoTitle>
                  <S.YellowDot />
                  {t('medical.alerts')}
                </S.MedicalInfoTitle>
                <S.MedicalAlertsText>{petData?.medicalAlerts || '--'}</S.MedicalAlertsText>
              </S.MedicalInfo>
              <S.MedicalInfo>
                <S.MedicalInfoTitle>
                  <S.YellowDot />
                  {t('allergies')}:
                </S.MedicalInfoTitle>
                <S.MedicalAlertsText isForAllergy>
                  <S.Allergies>
                    {petData?.allergies && petData?.allergies.length > 0
                      ? petData?.allergies?.map((allergy, index) => (
                          <S.Allergy key={`allergy${index}`}>{allergy}</S.Allergy>
                        ))
                      : '--'}
                  </S.Allergies>
                </S.MedicalAlertsText>
              </S.MedicalInfo>
            </S.MedicalInfoWrapper>
          </S.PetDetailsWrapper>
          <S.VisitWrapper>
            {/* {!isMobile && petData?.activeSubscription && (
              <S.ExtraVisitButton onClick={() => handleSubscribe('bookAVisit')}>
                {t('book.a.visit')}
              </S.ExtraVisitButton>
            )} */}
            <S.Note>
              <S.NoteHeader>{t('note')}:</S.NoteHeader>
              <S.NoteText>{petData?.behavioralNotes}</S.NoteText>
            </S.Note>
          </S.VisitWrapper>
        </S.Details>
      )}
      <RemovePetModal
        isOpen={removeModal}
        onRequestClose={() => setRemoveModal(false)}
        petName={petData?.name}
        uid={petData?.uid}
        handleRemovePet={handleRemovePet}
      />
      <PetModal
        uid={petData?.uid}
        isOpen={editOpen}
        isEdit
        handleCloseModal={() => setEditOpen(false)}
      />
      {isArchiveModalOpen && (
        <ArchivePetModal
          isModalOpen={isArchiveModalOpen}
          closeModal={() => setIsArchiveModalOpen(false)}
          petIdToArchive={petData?.uid}
          isPetsWeb
        />
      )}
      {vetModalOpen && (
        <VetModal isOpen={true} handleCloseModal={() => setVetModalOpen(false)} vetId={vet?.uid} />
      )}
    </S.Wrapper>
  );
};

export default SinglePetDetails;
