import React, { useEffect, useMemo, useState } from 'react';
import { GoogleMap, Marker, Polygon, useLoadScript } from '@react-google-maps/api';
import mapStyle from '_fsd/app/map_styles.json';
import { getIconMarker } from '../lib';
import uniq from 'lodash/uniq';
import flatMap from 'lodash/flatMap';
import flatten from 'lodash/flatten';
import { AREA_TYPE } from '../../../entities';
import { getZipArea } from '../../../entities/zip';
import { fitBounds } from '../../working-areas-map-view/ui/WorkingAreasMapView';
import { generateColors } from '../../../shared';
import { getStartEndPoints } from '_fsd/entities/vet';

const defCenter = {
  lat: 37.7749,
  lng: -122.4194
};

export const AdminMapVets = ({ vets }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GM_KEY
  });

  const [map, setMap] = React.useState(null);

  const onMapLoad = React.useCallback((map) => {
    setMap(map);
  }, []);

  const [zipPolygons, setZipPolygons] = useState([]);

  const workingAreas = useMemo(
    () => flatten(vets?.map((v) => flatten(v.timeslots?.map((ts) => ts.workingAreas)))),
    [vets]
  );
  const colors = useMemo(() => {
    if (workingAreas?.length > 0) {
      return generateColors(workingAreas.length);
    }
    return [];
  }, [workingAreas]);

  useEffect(() => {
    if (map && workingAreas && zipPolygons) {
      fitBounds(map, workingAreas, zipPolygons);
    }
  }, [workingAreas, zipPolygons, map]);

  useEffect(() => {
    if (workingAreas?.length) {
      const zips = uniq(
        flatMap(
          workingAreas
            .map((wa, i) => {
              if (wa.type === AREA_TYPE.ZIP) {
                return {
                  ...wa,
                  zipCodes: wa.zipCodes.map((z) => ({
                    index: i,
                    ...z
                  }))
                };
              }
              return wa;
            })
            .filter((z) => z.type === AREA_TYPE.ZIP),
          (a) => a.zipCodes.map((z) => ({ zip: z.zip, index: z.index }))
        )
      );
      if (zips.length) {
        const zipsCodes = zips.map((z) => z.zip);
        getZipArea(zipsCodes).then((res) => {
          const zipPolygonsObjects = res.map((r) => ({
            ...r,
            index: zips.find((z) => z.zip === r.zip)?.index
          }));
          setZipPolygons(zipPolygonsObjects);
        });
      }
    } else {
      setZipPolygons([]);
    }
  }, [workingAreas]);

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      workingAreas?.forEach((w) => {
        w?.polygons?.forEach((p) => {
          p.area?.forEach((a) => {
            bounds.extend(new window.google.maps.LatLng(a.lat, a.lng));
          });
        });
      });
      if (vets.length) {
        vets.forEach((vet) => {
          vet.timeslots?.forEach((timeslot) => {
            const { startPoint, endPoint } = getStartEndPoints({ vet, timeslot });
            if (startPoint?.lat)
              bounds.extend(new window.google.maps.LatLng(startPoint.lat, startPoint.lng));
            if (endPoint?.lat)
              bounds.extend(new window.google.maps.LatLng(endPoint.lat, endPoint.lng));
          });
        });
        map.panTo(bounds.getCenter());
        map.fitBounds(bounds, 50);
      }
    }
  }, [map, vets]);

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading maps...';

  return (
    <GoogleMap
      mapContainerStyle={{
        height: '100%',
        width: '100%'
      }}
      center={defCenter}
      zoom={13}
      onLoad={onMapLoad}
      version="weekly"
      options={{
        streetViewControl: false,
        mapTypeControl: false,
        gestureHandling: 'cooperative',
        styles: mapStyle
      }}>
      {workingAreas?.map((area, index) => {
        if (area.type === AREA_TYPE.POLYGON) {
          return area.polygons?.map((p, i) => (
            <Polygon
              options={{
                fillColor: colors[index],
                fillOpacity: 0.12,
                strokeColor: colors[index],
                strokeOpacity: 1,
                strokeWeight: 2
              }}
              key={area.uid + p.uid}
              path={p.area}
              // onClick={() => handleClickArea(area)}
            />
          ));
        }
        return null;
      })}
      {zipPolygons?.map((zip) => {
        return zip.polygons.map((p, index) => (
          <Polygon
            options={{
              fillColor: colors[zip.index],
              fillOpacity: 0.12,
              strokeColor: colors[zip.index],
              strokeOpacity: 1,
              strokeWeight: 2
            }}
            key={index}
            path={p}
          />
        ));
      })}

      {vets.map((v) =>
        v.apps.map((a) => {
          const { lat, lng } = a.appointmentRoute.address;
          const { order } = a.appointmentRoute;
          return (
            <Marker
              key={a.uid}
              position={{ lat, lng }}
              icon={{
                url:
                  'data:image/svg+xml;charset=UTF-8,' +
                  encodeURIComponent(getIconMarker(order >= 0 ? order : 30, v.index)),
                scaledSize: new window.google.maps.Size(40, 40)
              }}
            />
          );
        })
      )}
    </GoogleMap>
  );
};
