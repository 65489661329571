import React, { useCallback, useState } from 'react';
import NewAppointmentModal from '../../vet-schedule/ui/outdate/modals/NewAppointmentModal';
import NewClientModal from '../../vet-schedule/ui/outdate/modals/NewClientModal';

const SmartScheduleWrapperComponent = (props) => {
  const { vets, isSmartScheduleOpen, setIsSmartScheduleOpen, refetchApps, setCalendarDate } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const changeStep = useCallback(
    (step) => step < currentStep && setCurrentStep(step),
    [currentStep]
  );

  const [isNewClientOpen, setIsNewClientOpen] = useState(false);
  const openNewClientModal = useCallback(() => {
    setIsNewClientOpen(true);
    setIsSmartScheduleOpen(false);
    setCurrentStep(0);
  }, []);

  return (
    <>
      {isSmartScheduleOpen && (
        <NewAppointmentModal
          setCalendarDate={setCalendarDate}
          vet={vets ? vets[0] : null}
          allVets={vets}
          currentStep={currentStep}
          changeStep={changeStep}
          setCurrentStep={setCurrentStep}
          openNewClientModal={openNewClientModal}
          onRequestClose={() => {
            setIsSmartScheduleOpen(false);
            setCurrentStep(0);
          }}
          getAppointmentsByTimeslotUid={() => {
            refetchApps();
          }}
        />
      )}
      {isNewClientOpen && (
        <NewClientModal
          setCalendarDate={setCalendarDate}
          vet={vets?.[0] || null}
          allVets={vets}
          sendRequestCode={null}
          selectedTimeslot={null}
          checkExistingUser={null}
          selectedWorkingArea={null}
          onRequestClose={() => setIsNewClientOpen(false)}
          goBack={() => {
            setIsNewClientOpen(false);
            setIsSmartScheduleOpen(true);
          }}
          getAppointmentsByTimeslotUid={() => {
            refetchApps();
          }}
        />
      )}
    </>
  );
};

export const SmartScheduleWrapper = React.memo(SmartScheduleWrapperComponent);
