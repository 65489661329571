import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { serializeAddressDescription } from 'serializers/signUpFlow.serializer';
import { PropertyType } from 'utils/enums';
import { Form } from 'antd';

import AddressAutocomplete from 'components/address-autocomplete/AddressAutocomplete';
import AdditionalInfo from 'components/calendar/steps/address/AdditionalInfo';
import mediaQueriesList from 'common_repo/utils/mediaQueriesList';
import Button from 'components/common/button/Button';
import Modal from 'components/common/modal/Modal';

import './style.scss';

const EditClientAddressModal = ({ client, onRequestClose, onAddressSubmit }) => {
  const { isMobile } = mediaQueriesList();
  const [address, setAddress] = useState({});
  const [propertyType, setPropertyType] = useState(PropertyType.PrivateHome);
  const [isSubmitting, setSubmitting] = useState(false);
  const { t } = useTranslation();

  const onSelect = useCallback((value) => {
    const description = serializeAddressDescription(value);
    setAddress({
      ...value,
      description
    });
  }, []);

  const [form] = Form.useForm();

  useEffect(() => {
    if (client) {
      setAddress(client?.address);
      setPropertyType(PropertyType[client.address?.propertyType] ?? PropertyType.PrivateHome);
    }
  }, []);

  const initialValues = {
    address: client?.address?.description,
    floor: client?.address?.floor ? String(client?.address?.floor) : '0',
    apartment: client?.address?.apartment,
    userComment: client?.address?.comment
  };

  return (
    <Modal isOpen={true} autoHeight={isMobile} isLaptop={!isMobile} isMobile={isMobile}>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        disabled={isSubmitting}
        onFinish={(values) => {
          setSubmitting(true);
          onAddressSubmit(client, values, address, propertyType, onRequestClose);
          setSubmitting(false);
        }}>
        <div className="address-step edit-clients-modal-wrapper">
          <h3>{t('edit_clients_modal.title')}</h3>
          <Form.Item
            name="address"
            className="address-field"
            rules={[{ required: true }, { max: 150 }]}>
            <div className="address-field">
              <h2>{t('user.address')}</h2>
              <AddressAutocomplete
                onSelect={onSelect}
                address={address}
                isForZipCode
                isForCalendar
                isForCompleteProfile={true}
                // error={address && !!Object.keys(address).length && addressError}
              />
            </div>
          </Form.Item>

          <AdditionalInfo propertyType={propertyType} setPropertyType={setPropertyType} />

          <div className="edit-clients-footer">
            <Button
              disabled={isSubmitting}
              label={t('back')}
              className="cancel-btn"
              onClick={onRequestClose}
            />
            <Button
              disabled={isSubmitting}
              label={t('save')}
              type="submit"
              color="primary"
              className="next-btn"
            />
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default EditClientAddressModal;
