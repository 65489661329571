import React from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from '_fsd/shared';
import cls from 'classnames';
import { getRouteSummaryData } from '_fsd/entities/route/lib/getRouteSummaryData';
import { CloseOutlined, InfoCircleFilled } from '@ant-design/icons';
import css from './AppointmentRouteSummaryModal.module.css';
import { ReactComponent as CarIcon } from 'assets/icons/car.svg';
import { ReactComponent as FirstAidKitIcon } from 'assets/icons/firstaidkit.svg';
import { Tooltip } from 'antd';
import { routeStatus } from 'components/timeline/appointments/useTimelineAppointments';

import {
  HorizontalLine,
  VerticalLine,
  Modal,
  cssText as text,
  cssColor as colors,
  cssSpace as space
} from '_fsd/shared';

export const AppointmentRouteSummaryModal = ({
  onRequestClose,
  isRouteSummaryModalOpen,
  appointments,
  timeSlot,
  petsCount,
  clientCount
}) => {
  const { t } = useTranslation();

  const [totalDistance, totalTime, routingTime, appointmentsTime, freeTime, percentage] =
    getRouteSummaryData(
      appointments.map((appointment) => appointment.appointmentRoute),
      timeSlot,
      timeSlot.routes[0]
    );
  const isOverTime = freeTime < 0;

  const percentageStyle =
    isOverTime || percentage <= 5
      ? colors.cRed
      : percentage > 5 && percentage <= 20
        ? colors.cYellow
        : colors.cBlue;

  return (
    <Modal
      isOpen={isRouteSummaryModalOpen}
      onClose={onRequestClose}
      className={css.modalBody}
      customFooter={() => null}
      customHeader={() => null}>
      <div className={css.routeSummaryContainer}>
        <div className={cls(css.row, space.mb32)}>
          <Label className={text.s16w5l24}>{t('route-summary.title')}</Label>
          <CloseOutlined className={css.close} onClick={onRequestClose} />
        </div>
        <div className={css.row}>
          <Label>{t('route-summary.totalDistance')}</Label>
          {timeSlot.routes[0].status.toLowerCase() === routeStatus.REGISTER ? (
            <div className={css.row}>
              <Label className={cls(text.s14w5l22, space.mr4)}>
                {t('route-summary.toBeDetermined')}
              </Label>
              <Tooltip className={text.s12w4l22} title={t('route-summary.totalDistanceInfoText')}>
                <InfoCircleFilled className={colors.cC9d4E3} />
              </Tooltip>
            </div>
          ) : (
            <Label className={text.s14w5l22}>
              {totalDistance} {t('route-summary.miles')}
            </Label>
          )}
        </div>
        <HorizontalLine className={cls(css.hr, colors.cC9d4E3)} />
        <div className={cls(css.row, space.mb8)}>
          <Label>{t('route-summary.totalTime')}</Label>
          <Label className={text.s14w5l22}>
            {totalTime} {t('route-summary.min')}
          </Label>
        </div>
        <div className={cls(css.row, space.mb4)}>
          <div className={css.iconWithText}>
            <CarIcon className={cls(space.mr8, css.icon)} />
            <Label className={cls(text.s12w4l22, space.mr4)}>{t('route-summary.routing')}</Label>
            {timeSlot.routes[0].status.toLowerCase() === routeStatus.REGISTER ? (
              <>
                <Label className={text.s12w4l22}>
                  {' ('}
                  {t('route-summary.estimatedTime')}
                </Label>
                <Tooltip className={text.s12w4l22} title={t('route-summary.routingInfoText')}>
                  <InfoCircleFilled className={colors.cC9d4E3} />
                </Tooltip>
                <Label className={text.s12w4l22}>{')'}</Label>
              </>
            ) : null}
            <Label className={text.s12w4l22}>{':'}</Label>
          </div>
          <Label className={text.s12w4l22}>
            {routingTime} {t('route-summary.min')}
          </Label>
        </div>
        <div className={cls(css.row, space.mb4)}>
          <div className={css.iconWithText}>
            <FirstAidKitIcon className={cls(space.mr8, css.icon)} />
            <Label className={text.s12w4l22}>{t('route-summary.appointments')}</Label>
          </div>
          <Label className={text.s12w4l22}>
            {appointmentsTime} {t('route-summary.min')}
          </Label>
        </div>
        <HorizontalLine className={cls(css.hr, colors.cC9d4E3)} />
        <div className={css.row}>
          {isOverTime ? (
            <Label className={cls(css.overTime, colors.cRed)}>{t('route-summary.overTime')}</Label>
          ) : (
            <Label>{t('route-summary.free')}</Label>
          )}
          <div className={css.iconWithText}>
            <Label
              className={cls(
                text.s12w6l20,
                space.mr8,
                percentageStyle,
                isOverTime ? css.overTime : null
              )}>
              {percentage.toFixed()}%
            </Label>
            <VerticalLine className={cls(css.verticalLine, space.mr8, colors.cC9d4E3)} />
            <Label
              className={
                isOverTime ? cls(text.s14w5l22, css.overTime, colors.cRed) : text.s14w5l22
              }>
              {Math.abs(freeTime)} {t('route-summary.min')}
            </Label>
          </div>
        </div>
        <HorizontalLine className={cls(css.hr, colors.cC9d4E3)} />
        <div className={cls(css.row, space.mb12)}>
          <Label>{t('route-summary.numberOfHouseholds')}</Label>
          <Label className={text.s14w5l22}>{clientCount ?? 0}</Label>
        </div>
        <div className={css.row}>
          <Label>{t('route-summary.numberOfPatients')}</Label>
          <Label className={text.s14w5l22}>{petsCount ?? 0}</Label>
        </div>
      </div>
    </Modal>
  );
};
