import { useCallback, useEffect, useState } from 'react';
import { usePetOwners } from '_fsd/entities/pet-owner';

const LIMIT = 10;
export const usePetOwnerSearch = (args) => {
  const [search, _setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);

  const queryResult = usePetOwners({
    record: {
      skipAppointmentPets: true,
      name: search,
      take: LIMIT,
      page,
      vet: args?.vet
    }
  });

  const fetchNext = useCallback(() => {
    if (data.length < queryResult.data?.total) {
      setPage((p) => p + 1);
    }
  }, [queryResult.data?.total, data.length]);

  const setSearch = useCallback((s) => {
    _setSearch(s);
    setPage(1);
    setData([]);
  });

  useEffect(() => {
    if (!queryResult.loading && queryResult.data?.petOwners) {
      // merge re/fetched data (easy with react-query)
      setData((p) => [
        ...p.map((petOwner) => {
          const po = queryResult.data.petOwners.find((p) => p.uid === petOwner.uid);
          return po || petOwner;
        }),
        ...queryResult.data.petOwners.filter((petOwner) => !p.some((po) => po.uid === petOwner.uid))
      ]);
    }
  }, [queryResult.loading, queryResult.data]);

  return {
    search,
    setSearch,
    loading: queryResult.loading,
    data,
    fetchNext,
    total: queryResult?.data?.total
  };
};
