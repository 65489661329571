import React from 'react';
import css from './EmptyBox.module.css';
import { cssText } from '_fsd/shared';
import cls from 'classnames';

export const EmptyBox = ({ children, label, className }) => (
  <div className={cls(css.container, className)}>
    {children}
    <span className={cls(css.text, cssText.s14w4l22)}>{label}</span>
  </div>
);
