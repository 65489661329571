import { useMemo, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import {
  bookAVisitForPetMutation,
  createSubscriptionForPetMutation,
  getPetQuery,
  removePetMutation,
  getPetSubscriptionQuery,
  getPetsByOwnerQuery
} from 'gql';
import { replaceRouteParams } from 'utils/helpers';
import { CHOOSE_PET } from 'constants/client';

const smallPercent = 8;
const bigPercent = 17;
const percentageDistribution = {
  gender: smallPercent,
  birthDate: smallPercent,
  chipNumber: smallPercent,
  isSterilized: smallPercent,
  weight: bigPercent,
  breed: bigPercent,
  foodType: bigPercent,
  avatarUrl: bigPercent
};

const useSinglePetDetails = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const [openSettings, setOpenSettings] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const { id } = match.params;
  const settingsRef = useRef();

  const closeSettings = () => {
    setOpenSettings(false);
  };

  const { data, loading } = useQuery(getPetQuery, {
    variables: {
      record: {
        uid: id
      }
    }
  });

  const { data: subscriptionPet } = useQuery(getPetSubscriptionQuery, {
    variables: {
      record: {
        uid: id
      }
    }
  });

  const pet = data?.pet;
  const profileCompleted = useMemo(() => {
    if (pet) {
      return Object.keys(percentageDistribution).reduce((completedCount, key) => {
        return completedCount + (pet?.[key] && pet?.[key] !== '' ? percentageDistribution[key] : 0);
      }, 0);
    } else {
      return 0;
    }
  }, [pet]);

  const vet = subscriptionPet?.getPetSubscription?.subscription?.vet;

  const [removePet] = useMutation(removePetMutation, {
    refetchQueries: [{ query: getPetsByOwnerQuery }]
  });

  const [subscribe] = useMutation(createSubscriptionForPetMutation);
  const [bookAVisit] = useMutation(bookAVisitForPetMutation);

  const handleRemovePet = async (uid) => {
    try {
      await removePet({
        variables: { record: { uid } }
      });
      history.push('/');
    } catch (err) {
      console.log('err', err);
    }
  };

  const actions = {
    subscribe: subscribe,
    bookAVisit: bookAVisit
  };

  const handleSubscribe = async (actionName) => {
    const res = await actionName({
      variables: { record: { petId: match.params.id } }
    });
    history.push(replaceRouteParams(CHOOSE_PET, 'id', res?.data?.subscriptionId));
  };

  return {
    petPlan: subscriptionPet?.getPetSubscription?.plan?.title,
    petData: data?.pet,
    vet: vet,
    handleSubscribe,
    openSettings,
    setOpenSettings,
    removeModal,
    setRemoveModal,
    settingsRef,
    closeSettings,
    handleRemovePet,
    profileCompleted,
    loading,
    actions,
    isArchiveModalOpen,
    setIsArchiveModalOpen
  };
};

export default useSinglePetDetails;
