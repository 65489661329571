import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@apollo/react-hooks';
import { updateUserMutation } from 'gql';

const useUpdateUser = (refetchClients) => {
  const { t } = useTranslation();
  const [updateUserData] = useMutation(updateUserMutation, {
    refetchQueries: ['getPetOwnerById', 'getPetOwners']
  });

  const updateUser = (values, uid, onRequestClose) => {
    const { address, floor, apartment, comment, ...rest } = values;
    return updateUserData({
      variables: {
        record: {
          uid: uid,
          address: { ...address, floor, apartment, comment },
          userArgs: { ...rest }
        }
      }
    })
      .then(async () => {
        if (refetchClients) refetchClients(true);
        if (onRequestClose) onRequestClose();
        message.success(t('updated.successfully'));
        return true;
      })
      .catch(async (err) => {
        message.error(err?.message || t('failed.to.update'), 10);
        return false;
      });
  };
  return { updateUser };
};

export default useUpdateUser;
