import * as Yup from 'yup';
import { useFormik } from 'formik';

const schema = Yup.object({
  archiveReason: Yup.string().required(),
  comment: Yup.string()
});

const getInitialValues = () => {
  return {};
};
export const useArchivePetForm = (formikProps = {}) => {
  return useFormik({
    validationSchema: schema,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: getInitialValues(),
    ...formikProps
  });
};
