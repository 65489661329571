import React, { useEffect, useMemo, useState, useCallback } from 'react';
import mediaQueriesList from 'common_repo/utils/mediaQueriesList';
import { VetStatus } from 'constants/enums';
import { Row, Col } from 'antd';
import VetCalendar from 'components/vet-components/calendar/VetCalendar';
import NewAppointmentModal from '../new-appointment-modal/NewAppointmentModal';
import LimitReachedWarning from '../limit-reached/LimitReachedWarning';
import NewClientModal from '../new-appointment-modal/NewClientModal';
import CalendarLink from './calendar-link/CalendarLink';
import Appointments from './appointments/Appointments';
import useCalendar from '../../calendar/useCalendar';
import Timeslots from './timeslots/Timeslots';
import useRoutes from './useRoutes';
import { viewType as VIEW_TYPE } from './types';
import css from './Routes.module.css';

import { routeStatus as Route } from 'components/timeline/appointments/useTimelineAppointments';
import ModalOrComponent from 'components/modal-or-component/ModalOrComponent';
import EditAppointmentAddressModal from '../edit-appointment-address-modal/EditAppointmentAddressModal';

import { ReactComponent as CloseIcon } from 'assets/icons/close-gray.svg';
import { Appointments as AppointmentsNew } from '_fsd/widgets/vet-appointments';
import { Skeleton } from 'antd';
import { useTimeslotsByVets } from '../../../_fsd/entities/timeslot';
import moment from 'moment';
import GenerateRouteModal from '_fsd/pages/vet-schedule/ui/outdate/modals/GenerateRouteModal';
import { useAppointmentsByVetIds } from '_fsd/entities/appointment';
import { normalizeTimeSlotId } from 'utils/helpers';
import { LoaderOnContent } from '_fsd/shared';

const emptySelectedAppointment = {
  addPatient: null,
  duration: null,
  cancel: null,
  reschedule: null,
  eta: null
};

const Routes = () => {
  const { isMobile } = mediaQueriesList();
  const { sendRequestCode, checkExistingUser } = useCalendar();

  const [current, setCurrent] = useState(0);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [routeId, setRouteId] = useState(null);
  const [petsCount, setPetsCount] = useState(0);
  const [routeStatus, setRouteStatus] = useState('');
  const [isNewClientModalOpen, setIsNewClientModalOpen] = useState(false);
  const [isAppointmentsModalOpen, setIsAppointmentsModalOpen] = useState(false);
  const [isNewAppointmentsModalOpen, setIsNewAppointmentsModalOpen] = useState(false);
  const [isGenerateRouteModalOpen, setIsGenerateRouteModalOpen] = useState({
    isOpen: false,
    shouldSendEta: false
  });

  const {
    vet,
    loading,
    calendarDate,
    selectedTimeslot,
    spotCalendarDate,
    setSelectedTimeslot,
    selectedDateTimeSlots,
    changeVisitOrderHandler,
    setSelectedDateTimeSlots,
    appointmentAddressToEdit,
    setAppointmentAddressToEdit,
    vetTimezoneSlug
  } = useRoutes(routeId);

  const timeslotId = normalizeTimeSlotId(selectedTimeslot);
  const args = useMemo(
    () => ({
      vets: [vet?.uid],
      date: calendarDate,
      timeSlotId: timeslotId,
      skip: !vet?.uid || !timeslotId,
      fetchPolicy: 'network-only'
    }),
    [vet?.uid, calendarDate, timeslotId]
  );
  const {
    data: appointmentsData,
    refetch: refetchApps,
    loading: isAppsLoading
  } = useAppointmentsByVetIds(args);

  const [monthDate, setMonthDate] = useState(new Date());
  const {
    data: timeslots,
    refetch: refetchTimeslots
    // loading
  } = useTimeslotsByVets({
    vetIds: vet?.uid ? [vet?.uid] : [],
    dateStart: moment(monthDate).startOf('month').subtract(1, 'month').format('YYYY-MM-DD 00:00'),
    dateEnd: moment(monthDate).endOf('month').add(1, 'month').format('YYYY-MM-DD 23:59')
  });

  const refetch = useCallback(() => {
    refetchTimeslots();
    refetchApps();
  }, []);

  const startPoint = useMemo(() => {
    if (vet?.uid) {
      const address = vet?.user?.userSettings?.find(
        (s) => s?.setting?.description === 'DEFAULT_START_POINT'
      )?.itemValue;

      if (!address) return {};

      return JSON.parse(address);
    }
  }, [vet]);

  const endPoint = useMemo(() => {
    if (vet?.uid) {
      const address = vet?.user?.userSettings?.find(
        (s) => s?.setting?.description === 'DEFAULT_END_POINT'
      )?.itemValue;

      if (!address) return {};

      return JSON.parse(address);
    }
  }, [vet]);

  const clientLimit = useMemo(() => {
    if (vet?.uid) {
      const address = vet?.user?.userSettings?.find(
        (s) => s?.setting?.description === 'CLIENTS_PER_HOUR'
      )?.itemValue;

      if (!address) return {};

      return JSON.parse(address);
    }
  }, [vet]);

  const selectedWorkingArea = useMemo(() => {
    if (selectedDateTimeSlots.length && selectedTimeslot) {
      return selectedDateTimeSlots.find((s) => s.uid === selectedTimeslot);
    }
  }, [selectedDateTimeSlots, selectedTimeslot]);

  const openNewClientModal = useCallback(() => {
    setIsNewClientModalOpen(true);
    setIsNewAppointmentsModalOpen(false);
  }, []);

  const handleNewAppointmentModalClose = useCallback(() => {
    setIsNewAppointmentsModalOpen(false);
    setCurrent(0);
  }, []);

  useEffect(() => {
    if (!loading && appointmentsData && appointmentsData?.length) {
      const route = appointmentsData?.[0]?.appointmentRoute?.route;
      setSelectedRoute(route);
      setRouteId(route?.uid);
      setRouteStatus(route?.status);
    } else if (!loading) {
      setSelectedRoute(null);
      setRouteId(null);
      setRouteStatus(null);
    }
  }, [appointmentsData, loading]);

  const registerOrAdjustment = useMemo(() => {
    return (
      routeStatus?.toLowerCase() === Route.REGISTER ||
      routeStatus?.toLowerCase() === Route.ADJUSTMENT
    );
  }, [routeStatus]);

  useEffect(() => {
    if (appointmentsData && !!appointmentsData.length) {
      let pets = 0;
      appointmentsData.forEach((app) => {
        pets += app.pets.length;
      });
      setPetsCount(pets);
    }
  }, [appointmentsData]);

  const changeStep = useCallback(
    (step) => {
      if (step < current) setCurrent(step);
    },
    [current]
  );

  const [selectedAppointment, setSelectedAppointment] = useState(emptySelectedAppointment);

  return (
    <Skeleton loading={loading} active>
      {vet && (
        <Row gutter={12} className={css.container}>
          <Col span={24} className="routes-left-column">
            <CalendarLink vet={vet} />
          </Col>
          <Col xs={24} lg={8} className="routes-left-column">
            <VetCalendar
              monthDate={monthDate}
              setMonthDate={setMonthDate}
              vetId={vet?.uid}
              vet={vet}
              isForAdminCalendar={true}
              timeSlots={timeslots}
              calendarDate={calendarDate}
              setCalendarDate={spotCalendarDate}
              setSelectedTimeslot={setSelectedTimeslot}
              setSelectedDateTimeSlots={setSelectedDateTimeSlots}
            />
          </Col>

          {vet?.status?.toLowerCase() === VetStatus.LIMIT_REACHED ? (
            <Col xs={24} lg={16}>
              <LimitReachedWarning />
            </Col>
          ) : (
            <>
              <Col xs={24} lg={16}>
                <Timeslots
                  vet={vet}
                  setIsAppointmentsModalOpen={setIsAppointmentsModalOpen}
                  setSelectedDateTimeSlots={setSelectedDateTimeSlots}
                  registerOrAdjustment={registerOrAdjustment}
                  selectedTimeslot={selectedTimeslot}
                  setSelectedTimeslot={setSelectedTimeslot}
                  timeslotsData={selectedDateTimeSlots}
                  appointmentsData={appointmentsData}
                  spotCalendarDate={spotCalendarDate}
                  refetchAuthVet={refetch}
                  calendarDate={calendarDate}
                  clientLimit={clientLimit}
                  startPoint={startPoint}
                  endPoint={endPoint}
                />
              </Col>

              <ModalOrComponent
                isOpen={isAppointmentsModalOpen}
                onClose={() => setIsAppointmentsModalOpen(false)}>
                <Col span={24} className="routes-left-column">
                  {isMobile && (
                    <Row>
                      <Col span={24}>
                        <CloseIcon
                          className="close-modal-icon"
                          onClick={() => setIsAppointmentsModalOpen(false)}
                        />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col span={24}>
                      {!isAppsLoading && !loading ? (
                        <>
                          <AppointmentsNew
                            apps={appointmentsData}
                            onClickAddress={(app) => setAppointmentAddressToEdit(app)}
                            selectedTimeslot={selectedDateTimeSlots.find(
                              (t) => t.uid === selectedTimeslot
                            )}
                            vet={vet}
                            timeSlotId={selectedTimeslot}
                            selectedRoute={selectedRoute}
                            date={calendarDate}
                            setSelectedAppointment={setSelectedAppointment}
                            routeStatus={routeStatus}
                            setIsNewAppointmentsModalOpen={setIsNewAppointmentsModalOpen}
                            changeVisitOrderHandler={changeVisitOrderHandler}
                          />
                          <Appointments
                            selectedAppointment={selectedAppointment}
                            setSelectedAppointment={setSelectedAppointment}
                            viewType={VIEW_TYPE.LIST_VIEW}
                            setIsNewAppointmentsModalOpen={setIsNewAppointmentsModalOpen}
                            getAppointmentsByTimeslotUid={refetchApps}
                            setAppointmentAddressToEdit={setAppointmentAddressToEdit}
                            setIsAppointmentsModalOpen={setIsAppointmentsModalOpen}
                            changeVisitOrderHandler={changeVisitOrderHandler}
                            houseHolds={appointmentsData?.length || 0}
                            selectedTimeslot={selectedDateTimeSlots.find(
                              (t) => t.uid === selectedTimeslot
                            )}
                            selectedTimeslotId={selectedTimeslot}
                            selectedRoute={selectedRoute}
                            appointmentsData={appointmentsData}
                            fullTimeSlots={vet?.fullTimeSlots}
                            appointmentPets={petsCount}
                            calendarDate={calendarDate}
                            timeSlots={vet?.timeSlots}
                            routeStatus={routeStatus}
                            vetId={vet?.uid}
                            vet={vet}
                            apps={appointmentsData}
                          />
                        </>
                      ) : (
                        <LoaderOnContent md />
                      )}
                    </Col>
                  </Row>
                </Col>
              </ModalOrComponent>
            </>
          )}
        </Row>
      )}

      {isNewAppointmentsModalOpen && (
        <NewAppointmentModal
          vet={vet}
          current={current}
          changeStep={changeStep}
          setCurrent={setCurrent}
          calendarDate={calendarDate}
          selectedTimeslot={selectedTimeslot}
          openNewClientModal={openNewClientModal}
          onRequestClose={handleNewAppointmentModalClose}
          getAppointmentsByTimeslotUid={refetchApps}
          openGenerateRouteModal={setIsGenerateRouteModalOpen}
          vetTimezoneSlug={vetTimezoneSlug}
        />
      )}
      {isNewClientModalOpen && (
        <NewClientModal
          vet={vet}
          calendarDate={calendarDate}
          sendRequestCode={sendRequestCode}
          selectedTimeslot={selectedTimeslot}
          checkExistingUser={checkExistingUser}
          selectedWorkingArea={selectedWorkingArea}
          onRequestClose={() => setIsNewClientModalOpen(false)}
          goBack={() => {
            setIsNewClientModalOpen(false);
            setIsNewAppointmentsModalOpen(true);
          }}
          getAppointmentsByTimeslotUid={() => {
            refetchApps();
          }}
        />
      )}
      {isGenerateRouteModalOpen.isOpen && (
        <GenerateRouteModal
          routeId={routeId}
          routeStatus={routeStatus}
          isGenerateRouteModalOpen={isGenerateRouteModalOpen}
          onRequestClose={() =>
            setIsGenerateRouteModalOpen({ isOpen: false, shouldSendEta: false })
          }
        />
      )}
      {appointmentAddressToEdit && (
        <EditAppointmentAddressModal
          getAppointmentsByTimeslotUid={() => {
            refetchApps();
          }}
          onRequestClose={() => setAppointmentAddressToEdit(null)}
          appointment={appointmentAddressToEdit}
        />
      )}
    </Skeleton>
  );
};

export default Routes;
