import { useQuery, gql } from '@apollo/client';
import capitalize from 'lodash/capitalize';
export const useGetArchiveReasons = (variables = {}, options = {}) => {
  const { data, ...rest } = useQuery(query, { variables, ...options });
  return {
    data: data?.getPetArchiveReasons?.map((i) => ({
      value: i.name,
      label: capitalize(i.description)
    })),
    ...rest
  };
};

const query = gql`
  query getPetArchiveReasons {
    getPetArchiveReasons {
      name
      description
    }
  }
`;
