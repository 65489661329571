import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { getAuthVetQuery, changeVisitOrderMutation } from 'gql';
import dayjs from 'dayjs';

const UseRoutes = (routeId, skip) => {
  const [calendarDate, setCalendarDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [selectedDateTimeSlots, setSelectedDateTimeSlots] = useState([]);
  const [selectedTimeslot, setSelectedTimeslot] = useState(null);
  const [mobileAppointmentsModalIsOpen, setMobileAppointmentsModalIsOpen] = useState(false);
  const [appointmentAddressToEdit, setAppointmentAddressToEdit] = useState(false);
  const [vetTimezoneSlug, setVetTimezoneSlug] = useState('');

  const { data, loading, refetch } = useQuery(getAuthVetQuery, {
    fetchPolicy: 'no-cache',
    skip: skip
  });
  useEffect(() => {
    if (data?.getAuthVet?.timezone?.slug) {
      setVetTimezoneSlug(data.getAuthVet.timezone.slug);
    }
  }, [data?.getAuthVet?.timezone?.slug]);

  const [changeVisitOrder] = useMutation(changeVisitOrderMutation);

  const changeVisitOrderHandler = async (appointmentRouteOrder) => {
    await changeVisitOrder({
      variables: {
        record: {
          routeId,
          appointmentRouteOrder
        }
      },
      refetchQueries: ['getAppointmentsByDate']
    });
  };

  const spotCalendarDate = useCallback((date) => {
    setCalendarDate(dayjs(date).format('YYYY-MM-DD'));
  }, []);

  return {
    vet: data?.getAuthVet && !loading ? data?.getAuthVet : null,
    loading,
    setCalendarDate,
    calendarDate,
    selectedDateTimeSlots,
    setSelectedDateTimeSlots,
    setSelectedTimeslot,
    refetchAuthVet: refetch,
    spotCalendarDate,
    changeVisitOrderHandler,
    selectedTimeslot,
    mobileAppointmentsModalIsOpen,
    setMobileAppointmentsModalIsOpen,
    appointmentAddressToEdit,
    setAppointmentAddressToEdit,
    vetTimezoneSlug
  };
};

export default UseRoutes;
