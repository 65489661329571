import React from 'react';
import { message } from 'antd';
import './style.css';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from '_fsd/shared';
import { ReactComponent as IconRoute } from 'assets/icons/direction-route.svg';
import css from './RouteModal.module.css';
import { lockRouteByIdMutation, updateRouteStatusMutation } from 'gql';
import { useMutation } from '@apollo/client';
import { RouteStatus } from '_fsd/entities/route';

const GenerateRouteModal = ({ onRequestClose, routeId, isGenerateRouteModalOpen, routeStatus }) => {
  const { t } = useTranslation();
  const [lockRoute] = useMutation(lockRouteByIdMutation);
  const [updateStatus] = useMutation(updateRouteStatusMutation, {
    refetchQueries: ['getTimeslots']
  });

  const generateRoute = async () => {
    try {
      await updateStatus({
        variables: { record: { status: 'ADJUSTMENT', routeId } }
      });
      isGenerateRouteModalOpen.shouldSendEta &&
        (await lockRoute({
          variables: {
            data: { routeId, sendNotifications: isGenerateRouteModalOpen.shouldSendEta }
          }
        }));
      message.success(t('vet_timeslots.message.generate_success'));
    } catch (e) {
      console.log(e);
    } finally {
      onRequestClose();
    }
  };

  if (
    !isGenerateRouteModalOpen.shouldSendEta &&
    routeStatus !== '' &&
    routeStatus !== undefined &&
    routeStatus !== RouteStatus.REGISTER
  ) {
    onRequestClose();
    return null;
  }
  if (
    isGenerateRouteModalOpen.shouldSendEta &&
    routeStatus !== '' &&
    routeStatus !== undefined &&
    routeStatus !== RouteStatus.REGISTER &&
    routeStatus !== RouteStatus.ADJUSTMENT
  ) {
    onRequestClose();
    return null;
  }

  return (
    <>
      <Modal
        title={t('admin.recommender.new_appointment.title')}
        isOpen={true}
        onClose={onRequestClose}
        customHeader={() => null}
        customFooter={() => null}
        className={css.modal}>
        <div className={css.wrapper}>
          <IconRoute className={css.icon} />
          <div className={css.content}>
            <div className={css.header}>
              <p className={css.title}>
                {isGenerateRouteModalOpen.shouldSendEta
                  ? t('generate_route_modal.title_with_etas')
                  : t('generate_route_modal.title')}
              </p>
              <p className={css.subTitle}>{t('generate_route_modal.description')}</p>
            </div>
            <div className={css.footer}>
              <Button onClick={onRequestClose}>{t('generate_route_modal.button_cancel')}</Button>
              <Button type="primary" onClick={generateRoute}>
                {isGenerateRouteModalOpen.shouldSendEta
                  ? t('generate_route_modal.button_with_etas')
                  : t('generate_route_modal.button')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GenerateRouteModal;
