import { useState } from 'react';
import dayjs from 'dayjs';
import { useQuery } from '@apollo/client';

import {
  getPetOwnersQuery,
  getUniqueCountriesQuery
  // getPetOwnersTotalsQuery,
} from 'gql';
import moment from 'moment';
import { getPhone } from '../../../_fsd/entities';
import { getName } from '../../../_fsd/shared';

const useCustomersData = (userId) => {
  const [sortBy, setSortBy] = useState({
    registrationDate: null,
    fullName: null,
    country: null,
    cardExpiration: null,
    subscriptionDate: null,
    subscriptionDuration: null,
    spendingAmount: null,
    petAmount: null,
    status: null
  });
  const [name, setName] = useState('');
  const [search, setSearch] = useState('');
  const [petName, setPetName] = useState('');
  const [vet, setVet] = useState([]);
  const [countries, setCountries] = useState([]);
  const [take, setTake] = useState(10);
  const [page, setPage] = useState(1);
  const [subscriptionType, setSubscriptionType] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [registrationDate, setRegistrationDate] = useState(null);

  const { data, loading } = useQuery(getPetOwnersQuery, {
    variables: {
      record: {
        sortBy,
        search,
        take,
        name,
        vet,
        countries,
        page,
        subscriptionType,
        subscriptionStatus,
        registrationDate,
        userId,
        petName
      }
    },
    fetchPolicy: 'no-cache'
  });

  const uniqueCountries = useQuery(getUniqueCountriesQuery);

  const totalData = data?.getPetOwners?.total;

  const customersData = data?.getPetOwners?.petOwners?.map(
    ({
      uid,
      user,
      pets,
      spendingAmount,
      subscriptionDuration,
      subscriptionDate,
      status,
      userNextAppointmentDate,
      userNextAppointmentEstimation
    }) => {
      return {
        userId: user?.uid,
        registrationDate: dayjs(user?.createdAt).format('DD/MM/YYYY'),
        fullName: getName(user),
        country: user?.address?.country?.name,
        contacts: '@',
        cardExpiration: 'dunno',
        subscriptionDate,
        spendingAmount: spendingAmount?.toFixed(2) || 0,
        subscriptionDuration,
        petAmount: pets?.length,
        status,
        email: user?.email,
        phone: getPhone(user),
        city: user?.address?.city,
        street: user?.address?.street,
        pets,
        uid,
        nextAppointmentDate: moment(userNextAppointmentDate ?? ''),
        nextAppointmentEstimation: moment(userNextAppointmentEstimation?.date ?? '')
      };
    }
  );
  return {
    customersData,
    // customerTotal: data?.getPetOwners?.total,
    loading,
    totalData,
    search,
    setSearch,
    setName,
    name,
    setVet,
    setSortBy,
    countries,
    setCountries,
    setTake,
    setPage,
    subscriptionStatus,
    setSubscriptionStatus,
    registrationDate,
    setRegistrationDate,
    subscriptionType,
    // customerTotals: customerTotals?.getPetOwnersTotals,
    countriesList: uniqueCountries?.data?.getUniqueCountries,
    setSubscriptionType,
    setPetName
  };
};

export default useCustomersData;
