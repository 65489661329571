import React, { useState, useMemo } from 'react';
import css from './RecurringTimeSlotForm.module.css';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UserIcon } from 'assets/icons/user-blue.svg';
import { PlusOutlined, LeftOutlined } from '@ant-design/icons';
import {
  Col,
  Row,
  Input,
  InputNumber,
  Button,
  Checkbox,
  TimePicker,
  Typography,
  message
} from 'antd';
import cls from 'classnames';
const { Title } = Typography;

import AddressAutocomplete from 'components/vet-components/address-autocomplete/AddressAutocomplete';

import {
  cssText as text,
  cssColor as colors,
  cssSpace as space,
  Label,
  HorizontalLine,
  Button as ButtonFSD,
  useGetVetByUid,
  parseStringToRrule
} from '_fsd/shared';

import * as moment from 'moment';
import { RecurringTimeSlotRuleEditForm } from './RecurringTimeSlotRuleEditForm';
import { RecurringTimeSlotRuleViewForm } from './RecurringTimeSlotRuleViewForm';
import { useUpdateRecurringTimeSlot } from '_fsd/entities/timeslot/api/useUpdateRecurringTimeSlot';
import { useCreateTimeSlots } from '_fsd/entities/timeslot/api/useCreateTimeSlots';

import { WorkingAreasMapView, WorkingAreasSelectMultiple } from '_fsd/features';
import { CreateWorkingArea } from '_fsd/widgets';
import { addressSerializer, useCreateForm } from '_fsd/features/timeslot-editor';
import { serializeTimeslotUpdateInput } from '_fsd/entities/timeslot';
import LoaderWrapper from 'components/loaderWrapper/LoaderWrapper';

const getOnChangeAddressHandler = (name, formik) => (address) =>
  formik.setFieldValue(name, addressSerializer({ address }));

const RecurringTimeSlot = ({
  selectedTimeSlot,
  goBack,
  vetId,
  vet,
  refetchTimeSlots,
  isCopying,
  clientLimit
}) => {
  const { t } = useTranslation();
  const { updateRecurringTimeSlot } = useUpdateRecurringTimeSlot();
  const { createTimeSlots } = useCreateTimeSlots();

  const formik = useCreateForm(
    selectedTimeSlot,
    {
      onSubmit: (values, helpers) => {
        helpers.setSubmitting(true);
        const variables = serializeTimeslotUpdateInput(values, { isCopying });
        const timeSlotActionHandler =
          selectedTimeSlot && !isCopying ? updateRecurringTimeSlot : createTimeSlots;

        timeSlotActionHandler({
          variables: {
            record: {
              timeSlots: [
                {
                  ...variables,
                  vetId: undefined,
                  workingAreas: variables.workingAreas.map((wa) => wa.uid),
                  type: 'work_availability'
                }
              ],
              vetId
            }
          }
        })
          .then(() => {
            refetchTimeSlots();
            message.success(t('updated.successfully'));
            goBack();
          })
          .catch((err) => {
            message.error(err.message);
            console.log('error', err);
          })
          .finally(() => {
            helpers.setSubmitting(false);
          });
      }
    },
    false,
    null,
    vet
  );

  const [createAreaModal, setCreateAreaModal] = useState(false);

  const [rule] = useMemo(() => parseStringToRrule(formik.values.rule), [formik.values.rule]);

  return (
    <div className={css.page}>
      <div className={css.container}>
        <div className={cls(css.header, space.mb24)}>
          <LeftOutlined onClick={() => goBack()} className={css.buttonBack} />
          <span className={text.s16h24w5}>
            {selectedTimeSlot && !isCopying
              ? t('recurring_time_slot.edit')
              : t('recurring_time_slot.create')}
          </span>
        </div>
        <div className={css.formItem}>
          <span className={cls(text.s12h20w4)}>{t('recurring_time_slot.name')}</span>
          <Input
            disabled={formik.isSubmitting}
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            status={formik.errors.name && 'error'}
            placeholder={t('recurring_time_slot.name_the_time_slot')}
          />
          {formik.errors.name && <Label type="error">{t(`recurring_time_slot.errors.name`)}</Label>}
        </div>
        {selectedTimeSlot && !isCopying ? (
          <RecurringTimeSlotRuleViewForm rrule={rule} disabled={formik.isSubmitting} />
        ) : (
          <RecurringTimeSlotRuleEditForm
            rrule={rule}
            disabled={formik.isSubmitting}
            setDate={(startDate) => formik.setFieldValue('date', startDate)}
            setRrule={(ruleObj) => formik.setFieldValue('rule', ruleObj.toString())}
          />
        )}
        <div className={css.formRow}>
          <div className={css.formItem}>
            <span className={cls(text.s12h20w4)}>{t('start.time')}</span>
            <TimePicker
              disabled={formik.isSubmitting}
              status={formik.errors.startTime && 'error'}
              getPopupContainer={() => document.getElementById('ID_LAYOUT_SCROLLABLE')}
              className={css.inputFullWidth}
              inputReadOnly
              format="h:mm a"
              use12Hours
              minuteStep={15}
              name="startTime"
              placeholder={'00:00'}
              value={
                formik.values.startTime ? moment(formik.values.startTime, 'hh:mm a') : undefined
              }
              onBlur={formik.handleBlur}
              onSelect={(v) => {
                formik.setFieldValue('startTime', moment(v).format('hh:mm a'));
              }}
            />
            {formik.errors.startTime && (
              <Label type="error">{t(`recurring_time_slot.errors.startTime`)}</Label>
            )}
          </div>
          <div className={css.formItem}>
            <span className={cls(text.s12h20w4)}>{t('end.time')}</span>
            <TimePicker
              disabled={formik.isSubmitting}
              status={formik.errors.endTime && 'error'}
              getPopupContainer={() => document.getElementById('ID_LAYOUT_SCROLLABLE')}
              className={css.inputFullWidth}
              inputReadOnly
              format="h:mm a"
              use12Hours
              minuteStep={15}
              name="endTime"
              placeholder={'00:00'}
              value={formik.values.endTime ? moment(formik.values.endTime, 'hh:mm a') : undefined}
              onBlur={formik.handleBlur}
              onSelect={(v) => {
                formik.setFieldValue('endTime', moment(v).format('hh:mm a'));
              }}
            />
            {formik.errors.endTime && (
              <Label type="error">{t(`recurring_time_slot.errors.endTime`)}</Label>
            )}
          </div>
        </div>
        <Title level={5} className="working-area-title">
          {t('timeslot_modal.working_area_lbl')}
        </Title>
        <Row>
          <Col span={24}>
            <WorkingAreasSelectMultiple
              disabled={formik.isSubmitting}
              placeholder={t('timeslot_modal.select_wa_placeholder')}
              status={formik.errors.workingAreas && 'error'}
              error={formik.errors.workingAreas && t('recurring_time_slot.errors.workingAreas')}
              onSelect={(areas) => formik.setFieldValue('workingAreas', areas)}
              values={formik.values.workingAreas}
            />
            {!createAreaModal && (
              <ButtonFSD
                disabled={formik.isSubmitting}
                className={css.buttonCreate}
                onClick={() => setCreateAreaModal(true)}
                icon={<PlusOutlined />}>
                {t('timeslot_modal.add_working_area_btn')}
              </ButtonFSD>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <WorkingAreasMapView
              data={formik.values.workingAreas}
              loading={false}
              handleClickArea={() => {}}
              disableControls
              mapClass={css.mapClass}
              containerClass={css.containerClass}
            />
          </Col>
        </Row>
        <HorizontalLine className={cls(css.hr, colors.cC9d4E3)} />
        <>
          <Col>
            <Row gutter={8} style={{ alignItems: 'center', marginBottom: 8 }}>
              <Checkbox
                disabled={formik.isSubmitting}
                name="isDefaultLimit"
                checked={formik.values.isDefaultLimit}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}>
                <span className="checkbox-text">{t('timeslot_modal.clients_limit')}</span>
              </Checkbox>
              {!formik.values.isDefaultLimit && (
                <InputNumber
                  disabled={formik.isSubmitting}
                  name="maxAppointments"
                  controls={false}
                  type="number"
                  addonAfter={<UserIcon />}
                  value={formik.values.maxAppointments}
                  onChange={(number) => formik.setFieldValue('maxAppointments', number)}
                  onBlur={formik.handleBlur}
                  status={formik.errors.maxAppointments && 'error'}
                  className="client-limit-input"
                />
              )}
            </Row>
            {formik.errors.maxAppointments && (
              <Label type="error">{t(`recurring_time_slot.errors.maxAppointments`)}</Label>
            )}
          </Col>
          <>
            <Row gutter={8} className={css.defaultPointsCheckbox}>
              <Checkbox
                disabled={formik.isSubmitting}
                name="useDefaultPoints"
                checked={formik.values.useDefaultPoints}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}>
                <span className="checkbox-text">{t('timeslot_modal.default_points')}</span>
              </Checkbox>
            </Row>
            <Row gutter={8} className={css.pointsInput}>
              <Col span={12}>
                <span className="checkbox-text">{t('start.point')}</span>
                <AddressAutocomplete
                  disabled={formik.values.useDefaultPoints || formik.isSubmitting}
                  className="address-autocomplete-input"
                  address={formik.values.startPoint}
                  onSelect={getOnChangeAddressHandler('startPoint', formik)}
                  onChange={getOnChangeAddressHandler('startPoint', formik)}
                  customErrorMessage={
                    formik.errors.startPoint && t('recurring_time_slot.errors.addressPoint')
                  }
                />
              </Col>
              <Col span={12}>
                <span className="checkbox-text">{t('end.point')}</span>
                <AddressAutocomplete
                  disabled={formik.values.useDefaultPoints || formik.isSubmitting}
                  className="address-autocomplete-input"
                  address={formik.values.endPoint}
                  onSelect={getOnChangeAddressHandler('endPoint', formik)}
                  onChange={getOnChangeAddressHandler('endPoint', formik)}
                  customErrorMessage={
                    formik.errors.endPoint && t('recurring_time_slot.errors.addressPoint')
                  }
                />
              </Col>
            </Row>
          </>
        </>
        <Row className={css.includeCheckbox}>
          <Checkbox
            name="isSingle"
            onBlur={formik.handleBlur}
            onClick={() => formik.setFieldValue('isSingle', !formik.values.isSingle)}
            checked={!formik.values.isSingle}
            className="checkbox-text">
            {t('timeslot_modal.is_single_timeslot')}
          </Checkbox>
        </Row>
        <Button
          disabled={formik.isSubmitting}
          loading={formik.isSubmitting}
          className={cls(
            css.inputFullWidth,
            colors.cBlueBackground,
            css.saveButton,
            colors.cWhiteImportant
          )}
          onClick={formik.submitForm}>
          {selectedTimeSlot && !isCopying
            ? t('recurring_time_slot.update')
            : t('recurring_time_slot.save')}
        </Button>
      </div>
      {createAreaModal && (
        <CreateWorkingArea
          pushIsSavedSelectModal
          createAreaModal={createAreaModal}
          onClose={(area) => {
            setCreateAreaModal(false);
            const areas = [...formik.values.workingAreas.filter((a) => a.uid !== area?.uid)];
            if (area?.uid) {
              areas.push(area);
            }
            formik.setFieldValue('workingAreas', areas);
          }}
        />
      )}
    </div>
  );
};

export const RecurringTimeSlotForm = (props) => {
  const { vet, loading } = useGetVetByUid(props.vetId);

  return (
    <LoaderWrapper isLoading={loading}>
      <RecurringTimeSlot vet={vet} {...props} />
    </LoaderWrapper>
  );
};
