import { useMutation, gql } from '@apollo/client';

export const useArchivePet = () => {
  const [archivePet] = useMutation(query);

  return { archivePet };
};

const query = gql`
  mutation archivePet($data: ArchivePetRecord!) {
    archivePet(record: $data) {
      uid
    }
  }
`;
