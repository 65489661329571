import React, { useEffect, useState } from 'react';
import cls from 'classnames';
import css from './AppointmentDetails.module.css';
import PetEdit, { petYup } from '../PetEdit/PetEdit';
import { Button, ButtonColors, Checkbox, Label, FormItem } from '_fsd/shared';
import { PetItem } from '_fsd/entities/pet';
import { CloseOutlined, FilePdfOutlined, FileTextOutlined } from '@ant-design/icons';
import { Input, message, Select, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { birthDayDateToNumbers, getOptions, prepareAppPetFromForm } from '../../../utils/helpers';
import * as Yup from 'yup';
import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';
import useUpload from '../../../services/useUpload';
import last from 'lodash/last';

const appointmentSchema = (isVet) =>
  Yup.object({
    pet: petYup(isVet),
    concerns: Yup.array()
      .min(1, 'forms.appointment.concern')
      .of(Yup.object({ value: Yup.string().required(), label: Yup.string() }))
      .required('forms.appointment.concern'),
    comment: isVet ? Yup.string() : Yup.string().required('forms.appointment.comment'),
    privateComment: Yup.string(),
    firstTime: Yup.boolean(),
    files: Yup.array()
  });

const getSterilizedStatus = (appPet) => {
  if (appPet?.pet?.isSterilized !== undefined) {
    if (typeof appPet?.pet?.isSterilized === 'string') {
      return appPet?.pet?.isSterilized;
    }
    return appPet?.pet?.isSterilized === null
      ? 'unknown'
      : appPet?.pet?.isSterilized
        ? 'neutered'
        : 'no_neutered';
  }
  return undefined;
};

const getInitialValues = (appPet) => {
  const obj = {
    _id: appPet._id || uniqueId(),
    pet: {
      _id: appPet?.pet?._id || uniqueId(),
      uid: appPet?.pet?.uid || undefined,
      name: appPet?.pet?.name || '',
      type: appPet?.pet?.type
        ? getOptions([appPet.pet.type], { label: 'name', value: 'uid' })[0]
        : undefined,
      gender: appPet?.pet?.gender
        ? { value: appPet?.pet?.gender, label: appPet?.pet?.gender }
        : undefined,
      isSterilized: getSterilizedStatus(appPet),
      birthDate: appPet?.pet?.birthDate || undefined,
      ...birthDayDateToNumbers(appPet?.pet?.birthDate)
    },
    concerns: appPet?.concerns ? getOptions(appPet.concerns) : undefined,
    comment: appPet?.comment || undefined,
    privateComment: appPet?.privateComment || undefined,
    firstTime: appPet?.pet?.uid ? !!appPet?.firstTime : true,
    files: appPet?.files || []
  };
  if (appPet.uid) {
    obj.uid = appPet.uid;
  }
  return obj;
};

const AppointmentDetails = ({ appPet, onCancel, onComplete, isNew, concerns, isVet }) => {
  const { uploadFile } = useUpload();
  const [uploading, setUpload] = useState(false);
  const { t } = useTranslation('appt_details');
  const [edit, setEdit] = useState(false);
  const initialValues = React.useMemo(() => getInitialValues(appPet), [appPet]);
  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: appointmentSchema(isVet),
    onSubmit: async (values, helpers) => {
      helpers.setSubmitting(true);
      const preparedValues = prepareAppPetFromForm(values);
      onComplete(preparedValues);
      helpers.setSubmitting(false);
      onCancel();
    }
  });
  useEffect(() => {
    if (Object.keys(formik.touched).length === 0 && formik.errors.pet) {
      setEdit(true);
    }
  }, [initialValues, formik.errors]);
  const errors = {
    concerns:
      get(formik.touched, 'concerns') && t(get(formik.errors, 'concerns'), { ns: 'common' }),
    comment: get(formik.touched, 'comment') && t(get(formik.errors, 'comment'), { ns: 'common' })
  };
  const uploadProps = {
    onRemove: (file) => {
      const index = formik.values.files.indexOf(file);
      const newFileList = formik.values.files.slice();
      newFileList.splice(index, 1);
      formik.setFieldValue('files', newFileList);
    },
    beforeUpload: async (file) => {
      setUpload(true);
      const isLt15M = file.size / 1024 / 1024 < 15;
      if (!isLt15M) {
        message.error('File must be smaller than 15MB!', 4);
        return false;
      }
      const url = await uploadFile(file);
      const newFileList = formik.values.files.slice();
      newFileList.push(url);
      formik.setFieldValue('files', newFileList);
      setUpload(false);
      return false;
    },
    itemRender: (on, f, fl, { remove }) => {
      const fileName = last(f.split('/'));
      const v4 = new RegExp(
        /(_[0-9(a-f|A-F)]{8}-[0-9(a-f|A-F)]{4}-4[0-9(a-f|A-F)]{3}-[89ab][0-9(a-f|A-F)]{3}-[0-9(a-f|A-F)]{12})/
      );
      const name = fileName.replace(v4, '');
      return (
        <a href={f} className={css.file} target="_blank" rel="noreferrer">
          <FilePdfOutlined />
          <Label className={css.fileName}>{name}</Label>
          <Button
            type="text"
            icon={<CloseOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              remove();
            }}
          />
        </a>
      );
    },
    fileList: formik.values.files
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={css.container}>
        {isNew || edit ? (
          <PetEdit formik={formik} />
        ) : (
          <PetItem pet={appPet.pet} className={css.pet} onClick={() => setEdit(true)} />
        )}
        <FormItem label={t('concern_label')} error={errors.concerns}>
          <Select
            mode="multiple"
            allowClear
            showSearch={false}
            style={{ width: '100%' }}
            placeholder={t('concern_placeholder')}
            value={formik.values.concerns}
            onChange={(v, options) => {
              formik.setFieldValue('concerns', options);
            }}
            status={errors.concerns ? 'error' : undefined}
            onBlur={formik.handleBlurr}
            options={concerns}
          />
        </FormItem>
        {isVet && (
          <FormItem label={t('private_comment')} error={errors.privateComment}>
            <Input.TextArea
              className={css.textArea}
              onBlur={formik.handleBlur}
              type="text"
              name="privateComment"
              placeholder={t('private_placeholder')}
              onChange={formik.handleChange}
              value={formik.values.privateComment}
              status={formik.touched.privateComment && formik.errors.privateComment ? 'error' : ''}
            />
          </FormItem>
        )}
        <FormItem
          label={isVet ? t('user_comment_clinic') : t('user_comment')}
          error={errors.comment}>
          <Input.TextArea
            className={css.textArea}
            onBlur={formik.handleBlur}
            type="text"
            name="comment"
            placeholder={t('user_placeholder')}
            onChange={formik.handleChange}
            value={formik.values.comment}
            status={formik.touched.comment && formik.errors.comment ? 'error' : ''}
          />
        </FormItem>
        <div
          className={cls(css.checkbox, css.mb12)}
          onClick={() => formik.setFieldValue('firstTime', !formik.values.firstTime)}>
          <Checkbox checked={formik.values.firstTime} name="firstTime" />
          <Label>{t('first_time_label')}</Label>
        </div>
        <Upload.Dragger {...uploadProps}>
          <Button loading={uploading} icon={<FileTextOutlined />} type="text" disabled={uploading}>
            {t('upload_label')}
          </Button>
        </Upload.Dragger>
        <div className={css.buttons}>
          <Button type="secondary" onClick={onCancel}>
            {t('cancel')}
          </Button>
          <Button
            colorScheme={ButtonColors.ORANGE}
            type="primary"
            onClick={formik.submitForm}
            htmlType="submit">
            {t('save')}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default AppointmentDetails;
