import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonColors, Label, cssColor, cssText, SingleOption } from '_fsd/shared';
import css from './SigninOptions.module.css';
import cls from 'classnames';
import { SIGN_IN_USER, SIGN_IN_VET_ADMIN, SIGN_IN_VET } from '../../../constants/client';
import { useHistory } from 'react-router-dom';
import { ReactComponent as SvgProfile } from '../../../assets/icons/profile.svg';
import { ReactComponent as SvgHome } from '../../../assets/icons/home.svg';
import { ReactComponent as SvgHealth } from '../../../assets/icons/health.svg';

const MAP = {
  vet: SIGN_IN_VET,
  admin: SIGN_IN_VET_ADMIN,
  user: SIGN_IN_USER
};

export const SigninOptions = (props) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState();
  const history = useHistory();
  const onClick = () => history.push(selected);
  return (
    <div className={css.container}>
      <Label className={cls(cssText.s22h29w7i, cssColor.c323B4B)}>{t('sign_in.title')}</Label>
      <Label className={cls(cssText.s16h24w5i, cssColor.c8A94A6, css.label)}>
        {t('sign_in.sub_title')}
      </Label>

      <SingleOption
        icon={<SvgHealth />}
        className={css.option}
        onClick={() => setSelected(MAP.vet)}
        active={selected === MAP.vet}>
        {t('sign_in.caregiver')}
      </SingleOption>
      <SingleOption
        icon={<SvgProfile />}
        className={css.option}
        onClick={() => setSelected(MAP.admin)}
        active={selected === MAP.admin}>
        {t('sign_in.clinic_admin')}
      </SingleOption>
      <SingleOption
        icon={<SvgHome />}
        className={css.option}
        onClick={() => setSelected(MAP.user)}
        active={selected === MAP.user}>
        {t('sign_in.pet_owner')}
      </SingleOption>
      <Button
        type="primary"
        onClick={onClick}
        className={cls(css.confirm)}
        colorScheme={ButtonColors.ORANGE}
        disabled={!selected}>
        {t('sign_in.CTA')}
      </Button>
    </div>
  );
};
