import { gql, useMutation } from '@apollo/react-hooks';
import { useCallback } from 'react';

export const useSendRequestCode = () => {
  const [requestCode] = useMutation(query);
  return useCallback(
    async ({ phoneNumber, phonePrefix, role }) => {
      try {
        return await requestCode({
          variables: {
            record: {
              phonePrefix,
              phoneNumber,
              role
            }
          }
        });
      } catch (e) {
        return e;
      }
    },
    [requestCode]
  );
};

const query = gql`
  mutation requestAuthCode($record: RequestCodeRecord!) {
    requestCode(record: $record) {
      uid
      phonePrefix
      phoneNumber
      codeSentAt
      createdAt
    }
  }
`;
