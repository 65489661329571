import React, { useEffect, useState } from 'react';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  AREA_TYPE,
  serializeWorkingArea,
  useCreateWorkingArea,
  useUpdateWorkingArea,
  useWorkingAreaForm
} from '_fsd/entities/working-area';
import {
  WorkingAreaEditorPolygon,
  WorkingAreasMapView,
  WorkingAreasSelectType,
  ZipCodeSelectMultiple
} from '_fsd/features';
import { ButtonColors, cssColor, cssText, Label, Modal } from '_fsd/shared';
import css from './CreateWorkingArea.module.css';
import { useZipCodesOnWorkingAreasMap } from '../hooks';
import { SaveModal } from './SaveModal';

const Content = ({ form, defaultCenter }) => {
  const { t } = useTranslation();
  const zipCodeAreas = useZipCodesOnWorkingAreasMap(form.values);
  return (
    <div className={css.container}>
      <div className={css.leftSide}>
        <div className={css.control}>
          <span className={cssText.s16w4h24}>
            {t('settings_page.service_area.create_area_modal.label_areaName')}
          </span>
          <div>
            <Input
              name="name"
              placeholder={t('settings_page.service_area.create_area_modal.placeholder_areaName')}
              disabled={form.isSubmiting}
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              value={form.values.name}
              status={form.errors.name ? 'error' : ''}
            />
            {form.errors.name && (
              <Label type="error">
                {form.errors.name.startsWith('FRONT_')
                  ? t('settings_page.service_area.create_area_modal.validations.name_required')
                  : form.errors.name}
              </Label>
            )}
          </div>
        </div>
        <WorkingAreasSelectType
          value={form.values.type}
          onSelect={(value) => form.setFieldValue('type', value)}
        />
      </div>
      <div className={css.places}>
        {form.values.type === AREA_TYPE.POLYGON ? (
          <WorkingAreaEditorPolygon form={form} defaultCenter={defaultCenter} />
        ) : null}
        {form.values.type === AREA_TYPE.ZIP ? (
          <>
            <span className={cls(css.zipTitle, cssText.s16w5l24)}>
              {t('settings_page.service_area.editor_zip.title')}
              <span className={cls(cssText.s16w4l24, cssColor.cBlackO45)}>
                {t('settings_page.service_area.editor_zip.note')}
              </span>
            </span>
            <ZipCodeSelectMultiple
              values={form.values.zipCodes}
              onSelect={(zips) => form.setFieldValue('zipCodes', zips)}
            />
            <WorkingAreasMapView
              data={zipCodeAreas}
              defaultCenter={defaultCenter}
              disableControls
              mapClass={css.zipMap}
              containerClass={css.zipMapContainer}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

export const CreateWorkingArea = ({
  createAreaModal,
  onClose,
  defaultCenter,
  pushIsSavedSelectModal = false
}) => {
  const { t } = useTranslation();
  const form = useWorkingAreaForm(createAreaModal);

  useEffect(() => {
    return () => {
      form.resetForm();
    };
  }, []);

  const [exitModal, setExitModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [backendError, setBackendError] = useState('');
  const [errorModal, setErrorModal] = useState([]);
  const [update] = useUpdateWorkingArea();
  const [create] = useCreateWorkingArea();

  const isErrors = !!Object.keys(form.errors).length;

  const onConfirm = ({ isSaved, newName }) => {
    setBackendError('');
    if (isErrors) {
      return false;
    }
    form.setSubmitting(true);
    const formValues = { ...form.values, isSaved };
    if (newName) {
      formValues.name = newName;
    }
    const record = serializeWorkingArea(formValues);
    const promise = createAreaModal?.uid
      ? update({
          variables: {
            record
          }
        })
      : create({
          variables: {
            record
          }
        });
    promise
      .then(({ data: { workingArea } }) => {
        onClose(workingArea);
      })
      .catch((err) => {
        if (pushIsSavedSelectModal) {
          form.setFieldError('name', err.message);
          setSaveModal(false);
        } else {
          if (!newName) {
            setSaveModal(false);
            form.setFieldError('name', err.message);
          }
          setBackendError(err.message);
        }
      })
      .finally(() => {
        form.setSubmitting(false);
      });
  };

  return (
    <>
      <Modal
        force
        className={css.modalContainer}
        title={
          createAreaModal?.uid
            ? t('settings_page.service_area.create_area_modal.title_edit')
            : t('settings_page.service_area.create_area_modal.title_create')
        }
        isOpen={createAreaModal}
        onConfirm={() => {
          if (!form.dirty) {
            onClose();
            return;
          }
          form.validateForm().then((errors) => {
            if (Object.keys(errors).length) {
              if (errors.zipCodes || errors.polygons) {
                const errorsKeys = Object.keys(errors);
                setErrorModal(
                  errorsKeys.map((error) =>
                    t('settings_page.service_area.create_area_modal.validations.' + error)
                  )
                );
              }
            } else {
              if (createAreaModal?.uid || pushIsSavedSelectModal) {
                setSaveModal(true);
                setBackendError('');
              } else {
                onConfirm({ isSaved: true });
              }
            }
          });
        }}
        onClose={() => {
          if (form.dirty) {
            setExitModal(true);
          } else {
            onClose();
          }
        }}
        footerProps={{
          displayCancel: false,
          titleConfirm: t('settings_page.service_area.create_area_modal.button_save')
        }}>
        <Content form={form} defaultCenter={defaultCenter} />
      </Modal>
      <Modal
        hideClose
        icon={<ExclamationCircleOutlined />}
        title={t('settings_page.service_area.create_area_modal.exit_modal.title')}
        isOpen={exitModal}
        onConfirm={() => {
          setExitModal(false);
          onClose();
        }}
        onClose={() => {
          setExitModal(false);
        }}
        footerProps={{
          buttonConfirmProps: {
            colorScheme: ButtonColors.ORANGE
          },
          titleConfirm: t('settings_page.service_area.create_area_modal.exit_modal.button_confirm')
        }}>
        {t('settings_page.service_area.create_area_modal.exit_modal.content')}
      </Modal>
      {saveModal && (
        <SaveModal
          pushIsSavedSelectModal={pushIsSavedSelectModal}
          onConfirm={onConfirm}
          onClose={() => setSaveModal(false)}
          error={backendError}
        />
      )}
      <Modal
        hideClose
        icon={<ExclamationCircleOutlined />}
        title={t('settings_page.service_area.validation_modal.title')}
        isOpen={errorModal.length}
        footerProps={{
          displayCancel: false
        }}
        onConfirm={() => {
          setErrorModal([]);
        }}
        onClose={() => {
          setErrorModal([]);
        }}>
        <div className={css.validationModal}>
          {errorModal.map((err) => (
            <span key={err}>{err}</span>
          ))}
        </div>
      </Modal>
    </>
  );
};
