import React, { useState, useRef, useCallback } from 'react';
import useOutsideClickDetector from 'hooks/useOutsideClickDetector.jsx';
import RemovePetModal from 'components/remove-pet-modal/RemovePetModal';
import { parseTimeSlotHour, getInitialLetters } from 'utils/helpers';
import useDateFormat from 'common_repo/components/useDateFormat.js';
import mediaQueriesList from 'common_repo/utils/mediaQueriesList';
import CustomModal from 'components/common/modal/Modal';
import { useTranslation } from 'react-i18next';
import { petAvatars } from 'constants/pet';
import { Tooltip } from 'antd';
import { ReactComponent as InstuctionsIcon } from 'assets/icons/instuctions-icon.svg';
import { ReactComponent as MedicationIcon } from 'assets/icons/medication-icon.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as Comment } from 'assets/icons/comment-icon.svg';
import { ReactComponent as FilesIcon } from 'assets/icons/files-icon.svg';
import { ReactComponent as Location } from 'assets/icons/location.svg';
import { ReactComponent as Arrow } from 'assets/icons/arrow-down.svg';
import { ReactComponent as Exit } from 'assets/icons/close-gray.svg';
import { ReactComponent as Niddle } from 'assets/icons/niddle.svg';
import { ReactComponent as Pen } from 'assets/icons/pen-icon.svg';
import useVisitModal from './useVisitModal.js';
import * as S from './components.jsx';

const VisitModal = ({
  uid,
  onRequestClose,
  isOpen,
  visitIcon,
  visitType,
  route,
  missed,
  rescheduleAvailable,
  setIsCalendarOpen,
  address,
  pets,
  isFutureTimeline,
  isFutureTimeSlot,
  appointments,
  vetIsOccupied,
  refetchTimeline
}) => {
  const [activePets, setActivePets] = useState({});
  const [height, setHeight] = useState(59);
  const [comment, setCommentValue] = useState(appointments?.[0]?.userComment || '');
  const [, setToolTipOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  const { updateUserComment, loading, removeVisit } = useVisitModal(
    appointments?.[0]?.uid,
    onRequestClose,
    setIsCalendarOpen,
    refetchTimeline
  );
  const rescheduleRef = useRef(null);
  const { isMobile } = mediaQueriesList();
  const commentRef = useRef(null);
  const { dateFormat } = useDateFormat();
  const { t } = useTranslation();
  const { vet } = route;

  function debounce(func, timeout = 0) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  const closeToolTip = () => {
    setToolTipOpen(false);
  };

  useOutsideClickDetector(rescheduleRef, closeToolTip);

  const changeHandler = (event) => {
    if (height !== event?.target?.scrollHeight) {
      setHeight(event?.target?.scrollHeight);
    }

    updateUserComment(event.target.value?.trim());
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), []);

  return (
    <CustomModal onRequestClose={!loading && onRequestClose} isOpen={isOpen} missed={missed}>
      <S.Wrapper missed={missed}>
        <>
          <S.Header missed={missed}>
            <S.HeaderTitle>{t('visit.information')}</S.HeaderTitle>
            <S.ExitIcon onClick={!loading && onRequestClose}>
              <Exit fill="grey" />
            </S.ExitIcon>
          </S.Header>
          <S.Main>
            <S.Visit>
              <S.VisitDetails>
                <S.VisitType>
                  <S.VisitTypeIcon src={visitIcon} alt="visitIcon" />
                  <S.VisitTypeDetails>
                    <S.VisitTypeDetailsTitle>
                      {visitType}
                      {missed && <S.Missed>{t('missed')}</S.Missed>}
                    </S.VisitTypeDetailsTitle>
                    <S.VisitTimesWrapper>
                      <S.VisitTime>{dateFormat(route?.date)} </S.VisitTime>
                      <S.VisitTime>
                        {parseTimeSlotHour(route?.timeSlot?.startTime) +
                          '-' +
                          parseTimeSlotHour(route?.timeSlot?.endTime)}
                      </S.VisitTime>
                    </S.VisitTimesWrapper>
                  </S.VisitTypeDetails>
                </S.VisitType>
                {!isMobile && (
                  <S.VisitContact>
                    <S.UserAddress>
                      <S.InfoIcon>
                        <Location />
                      </S.InfoIcon>
                      <S.VisitContactDetails>
                        <S.DetailsTitle>{t('user.address')}</S.DetailsTitle>
                        <S.AdressDetails>
                          {address?.houseNumber + ' ' + address?.street + ', ' + address?.city}
                        </S.AdressDetails>
                      </S.VisitContactDetails>
                    </S.UserAddress>
                    <S.UserComment style={{ display: 'none' }}>
                      <S.InfoIcon>
                        <Comment />
                      </S.InfoIcon>
                      <S.VisitContactDetails onClick={(e) => e.stopPropagation()}>
                        {loading && <S.Loader />}
                        <S.DetailsTitle>
                          {t('user.comment')}
                          <Pen fill="#ACBCD4" />
                        </S.DetailsTitle>
                        <S.Comment
                          maxLength="300"
                          value={comment}
                          onChange={(event) => {
                            setCommentValue(event.target.value);
                            debouncedChangeHandler(event);
                          }}
                          ref={commentRef}
                          style={{ height: `${height}px` }}
                          placeholder={t('you.can.leave.comment')}
                          isPlaceHolder={comment === ''}
                        />
                      </S.VisitContactDetails>
                    </S.UserComment>
                  </S.VisitContact>
                )}
              </S.VisitDetails>
              <S.VetInfo>
                <S.Vet>
                  {vet?.user?.avatarUrl ? (
                    <S.VetImage src={vet?.user?.avatarUrl} alt="vetavatar" />
                  ) : (
                    <S.VetDefaultAvatar>
                      {getInitialLetters(null, vet?.user?.firstName, vet?.user?.lastName)}
                    </S.VetDefaultAvatar>
                  )}
                  <S.VetInfoWrapper>
                    <S.VetName>
                      {vet?.user?.firstName} {vet?.user?.lastName}
                    </S.VetName>
                    {t('vet')}
                  </S.VetInfoWrapper>
                </S.Vet>
                {isMobile && (
                  <S.VisitContact>
                    <S.UserAddress>
                      <S.InfoIcon>
                        <Location />
                      </S.InfoIcon>
                      <S.VisitContactDetails>
                        <S.DetailsTitle>{t('user.address')}</S.DetailsTitle>
                        <S.AdressDetails>
                          {`${address?.houseNumber} ${address?.street}, ${address?.city}`}
                        </S.AdressDetails>
                      </S.VisitContactDetails>
                    </S.UserAddress>
                    <S.UserComment style={{ display: 'none' }}>
                      <S.InfoIcon>
                        <Comment />
                      </S.InfoIcon>
                      <S.VisitContactDetails onClick={(e) => e.stopPropagation()}>
                        {loading && <S.Loader />}
                        <S.DetailsTitle>
                          {t('user.comment')}
                          <Pen fill={'#ACBCD4'} />
                        </S.DetailsTitle>
                        <S.Comment
                          maxLength="300"
                          value={comment}
                          onChange={(event) => {
                            setCommentValue(event.target.value);
                            debouncedChangeHandler(event);
                          }}
                          ref={commentRef}
                          style={{ height: `${height}px` }}
                          isPlaceHolder={comment === ''}
                          placeholder={t('you.can.leave.comment')}
                        />
                      </S.VisitContactDetails>
                    </S.UserComment>
                  </S.VisitContact>
                )}
              </S.VetInfo>
            </S.Visit>
            <S.PetsWrapper>
              {pets?.map((pet, index) => {
                const isActive = activePets?.[pet?.pet?.uid];
                return (
                  <S.Pet isActive={isActive} key={`pet${index}`}>
                    <S.PetHeader isActive={isActive}>
                      <div>
                        <S.PetImage src={pet?.pet?.avatarUrl || petAvatars[pet?.pet?.type?.name]} />
                        <S.PetName>{pet?.pet?.name}</S.PetName>
                      </div>
                      <div>
                        {(!!pet?.vaccines.length ||
                          pet?.summary ||
                          !!pet?.soldInventories?.length) && (
                          <S.ArrowWrapper
                            isActive={isActive}
                            onClick={() => {
                              setActivePets((curr) => ({
                                ...curr,
                                [pet?.pet?.uid]: !curr[pet.pet?.uid]
                              }));
                            }}>
                            <Arrow />
                          </S.ArrowWrapper>
                        )}
                      </div>
                    </S.PetHeader>
                    {isActive && (
                      <S.PetDetails>
                        {pet?.summary && (
                          <S.PetDetailsWrapper>
                            <S.PetDetailsHeader>
                              <S.IconWrapper>
                                <InstuctionsIcon />
                              </S.IconWrapper>
                              {t('instuctions')}
                            </S.PetDetailsHeader>
                            <S.InstuctuinsText>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: pet?.summary
                                    .replaceAll('\\n', '<br/>')
                                    .replaceAll('\n', '<br/>')
                                }}
                              />
                            </S.InstuctuinsText>
                          </S.PetDetailsWrapper>
                        )}
                        {pet?.vaccines.length > 0 && (
                          <S.PetDetailsWrapper>
                            <S.PetDetailsHeader>
                              <S.IconWrapper>
                                <Niddle />
                              </S.IconWrapper>
                              {t('vaccines')}
                            </S.PetDetailsHeader>
                            <S.Vaccines>
                              {pet?.vaccines?.map(({ uid, vaccine, isUsed }) => {
                                if (isUsed) {
                                  return <S.Vaccine key={uid}>{vaccine?.name}</S.Vaccine>;
                                }
                              })}
                            </S.Vaccines>
                          </S.PetDetailsWrapper>
                        )}
                        {pet?.soldInventories?.length > 0 && (
                          <S.PetDetailsWrapper>
                            <S.PetDetailsHeader>
                              <S.IconWrapper>
                                <MedicationIcon />
                              </S.IconWrapper>
                              {t('medications.products.given')}
                            </S.PetDetailsHeader>
                            <S.Medications>
                              {pet?.soldInventories?.map((medication) => (
                                <S.Medication key={medication.uid}>
                                  {medication?.quantity > 1 ? medication?.quantity + 'x' : ''}{' '}
                                  {medication?.inventory?.name}
                                </S.Medication>
                              ))}
                            </S.Medications>
                          </S.PetDetailsWrapper>
                        )}
                        {pet?.fileUrls?.length > 0 && (
                          <S.PetDetailsWrapper>
                            <S.FilesHeader>
                              <div>
                                <S.IconWrapper>
                                  <FilesIcon />
                                </S.IconWrapper>
                                {t('files')}
                              </div>
                              <S.Download>
                                <S.DownloadTitle>{t('download.all')}</S.DownloadTitle>
                                <DownloadIcon />
                              </S.Download>
                            </S.FilesHeader>
                          </S.PetDetailsWrapper>
                        )}
                      </S.PetDetails>
                    )}
                  </S.Pet>
                );
              })}
            </S.PetsWrapper>
          </S.Main>
        </>
        {!missed && (isFutureTimeSlot || isFutureTimeline) && (
          <S.Footer>
            <S.Line />
            {rescheduleAvailable ? (
              <>
                <S.Reschedule ref={rescheduleRef} onClick={setIsCalendarOpen} rescheduleAvailable>
                  {t('reschedule.event')}
                </S.Reschedule>
                <S.CancelAppointment onClick={() => setIsRemoveModalOpen(true)}>
                  {t('cancel.appointment')}
                </S.CancelAppointment>
              </>
            ) : (
              <>
                <Tooltip
                  trigger="hover"
                  title={
                    vetIsOccupied
                      ? t('no.slots.error', {
                          user: `${vet?.user?.firstName} ${vet?.user?.lastName}`
                        })
                      : t('tooltip.rescheduling.error')
                  }>
                  <S.Reschedule>{t('reschedule.event')}</S.Reschedule>
                </Tooltip>
                <Tooltip title={t('tooltip.canceling.error')} trigger="hover">
                  <S.CancelAppointment isDisabled>{t('cancel.appointment')}</S.CancelAppointment>
                </Tooltip>
              </>
            )}
          </S.Footer>
        )}
      </S.Wrapper>
      {isRemoveModalOpen && (
        <RemovePetModal
          isOpen={isRemoveModalOpen}
          onRequestClose={() => setIsRemoveModalOpen(false)}
          removeVisit={removeVisit}
          appointmentRouteId={uid}
          isForAppointment
        />
      )}
    </CustomModal>
  );
};

export default VisitModal;
